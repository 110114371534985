import { Box, Grid, useMediaQuery } from '@mui/material';
import React from 'react'
import { PractitionerCard } from '../healthcare_services/PractitionerCard';
import MobileDoctorCard from '../branchhome/mobileDoctorsCard';
import DoctorCard from '../branchhome/DoctorCard';

function PractitionerGroups({ practitioner }) {
    const isXsScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <div>
            <div className='font-semibold  text-base md:text-lg'>Meet Our Experts</div>
            <div className='pt-1 px-0 '>
                <Box sx={{ bgcolor: '#FDF3F7', p: { xs: "10px 8px", md: "20px 16px" }, mt: 2 }}>

                    <Grid container columnSpacing={5}>
                        {practitioner?.map((val, index) => (
                            <Grid item xs={12} sm={6} key={index} md={6} lg={6} sx={{ p: { xs: "10px", sm: '10px', md: '10px', lg: '10px ' } }}>
                                {isXsScreen ?
                                    <MobileDoctorCard val={val} /> :
                                    <DoctorCard val={val} />
                                    // <PractitionerCard val={val} />
                                }

                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </div>
        </div>
    )
}

export default PractitionerGroups;