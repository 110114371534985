const baseUrl = `${process.env.REACT_APP_API_URL_v4}`
const api = `${process.env.REACT_APP_API_URL}`
const otpapi = `${process.env.REACT_APP_API_URL_OTP}`
const feed = `${process.env.REACT_APP_API_URL_FEED}`
const address = `${process.env.REACT_APP_API_URL_ADDRESS}`
const elib = `${process.env.REACT_APP_API_URL_ELIB}`
const slot = `${process.env.REACT_APP_API_URL_SLOT}`
const docurl = `${process.env.REACT_APP_API_Docsteth}`
const language = `${process.env.REACT_APP_API_URL_LANGUAGE}`
const relationship = `${process.env.REACT_APP_API_URL_RELATIONSHIP}`
const cm = `${process.env.REACT_APP_C_M_API_URL}`
const URL = {
    practitioner: `${baseUrl}Practitioner`,
    paramitha: `${api}`,
    paramitha_v4: `${baseUrl}`,
    paramitha_v1_practitioner: `${slot}Practitioner/getPractitionerRole`,
    paramitha_v1: `${slot}`,
    language: `${language}`,
    // services api
    getServiceDepts: `${baseUrl}Organization?type=dept`,
    getHeatlthcareservice: `${baseUrl}HealthcareService`,
    getServicePractitioner: `${baseUrl}PractitionerRole`,
    // login and register apis
    check: `${api}`,
    checkMobileNumber: `${api}/findPatientsByPhoneNumber`,
    sendOtp: `${otpapi}signupotpforparamithawithcaptcha`,
    verifyOtp: `${otpapi}verifyOtpparamitha`,
    otp: `${otpapi}`,
    sendOtpAppointment: `${otpapi}send_otp_for_appointmentBooking`,
    verifyOtpAppointment: `${otpapi}verify_otp_for_appointmentBooking`,
    address: `${address}`,
    post: `${feed}`,
    feed: `${feed}`,
    elib: `${elib}`,
    appointment: `${slot}`,
    patientDetails: `${slot}`,

    // doctor 
    docstethDocterData: `${docurl}findUserDetails/ByID`,
    docsteth: `${docurl}`,

    //relationship
    relationship: `${relationship}`,
    // search
    search: `${cm}manager/search/paramitha`,
    publish: `${cm}manager/`,
    // testmonail get api
    getReviews: `${cm}manager/getreviews`,

    // Conditions
    getDiseasesBySpecialityname: `${cm}manager/getDiseasesBySpecialityname/paramitha`,
    getSpeciality: `${cm}manager/getspeciality/paramitha`,
    getallDiseases: `${cm}manager/getallDiseases/paramitha`,
    getActivePatientEducation: `${cm}manager/patient-education/getActivePatientEducation/paramitha`,

}
export default URL