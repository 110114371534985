export const timeSince = (date) => {
    const ms = new Date().getTime() - new Date(date).getTime();
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 1) {
        return "Just now";
    }

    let timeString = '';
    if (seconds < 60) {
        timeString = `${seconds}s`;
    } else if (minutes < 60) {
        timeString = `${minutes}m`;
    } else if (hours < 24) {
        timeString = `${hours}h`;
    } else if (days < 30) {
        timeString = `${days}d`;
    } else if (months < 12) {
        timeString = `${months}mon`;
    } else {
        timeString = `${years}y`;
    }

    return timeString + (seconds < 60 ? '' : ' ago');
};


