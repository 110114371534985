import React from "react";
import { Button, Typography } from "@mui/material";
import styles from "../../header/styles.module.css";
import AutoPlayVideo from "./AutoPlayVideo";
import videosrc from "../../../assets/images/AdobeStock_477611324.mp4"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const Secondslide = () => {
  const branchName = useSelector((state) => state.branch.branch);
  const navigate = useNavigate();

  // console.log(branchName,"branchName123321")

  const handleClickConsultNow = () => {


    navigate("/doctors")


  }
  return (
    <div style={{ width: "100%", height: "80vh", }} className={styles.carouselHead}>
      <AutoPlayVideo src={videosrc} />
      <div
        style={{
          position: "absolute",
          top: "58%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          padding: 15,
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          gap: 20,
          // border: "1px solid red",
        }}
        className={styles.carouselContainer2}
      >
        <h1
          className={[styles.slidesecondheading].join(" ")}
          style={{ color: "white" }}
        >
          BRING YOUR NEW BORN TO SAFE HANDS
        </h1>
        <p
          style={{
            fontSize: "23px",
            color: "white",
          }}
          className={styles.slidesecondheading2}
        >
          Consult with Pioneers in Post Natal & Neonatal Care
        </p>
        <div className={styles.consultbtn}>
          <Button
            variant="contained"
            sx={{
              background: "#ffffff !important",
              color: "#000",
              borderRadius: "7px",
              alignSelf: "left",
              padding: "10px 20px",
              textTransform: "capitalize",
              height: 40,
              width: 170
            }}
          >
            <Typography sx={{ fontSize: 17, fontWeight: 600 }} onClick={handleClickConsultNow}> Consult Now</Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Secondslide;
