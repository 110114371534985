import React, { useEffect, useState } from 'react'
import CommonDialog from '../../atoms/commonModal'
import { Autocomplete, Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Menu, MenuItem, TextField, Typography } from '@mui/material'
import { Close, Delete } from '@mui/icons-material'
import { Field, Formik } from 'formik'
import * as yup from 'yup';
import CustomAutoComplete from '../../atoms/CustomAutoComplete'
import { CustomTextField } from '../../atoms/TextField'
import { useDispatch, useSelector } from 'react-redux'
import ContainedButton, { OutlinedButton } from '../../atoms/commonbutton'
import zIndex from '@mui/material/styles/zIndex'
import SelectOptions from '../../atoms/selectoptions'
import dayjs from 'dayjs'
import FormikCommoncalender, { Commoncalender } from '../../atoms/commoncalender'
import axios from 'axios'
import { localstore } from '../../localstore/localstore'
import URL from '../../../services/api_url'
import { getDistricts, getPatientdatafromibm, getRelationship, getStates, getSubDistricts } from '../../../redux/actions/actions'
import ErrorStatusMsg from '../../atoms/ErrorStatusMsg'
import { relation } from '../../atoms/RelationData'
import BranchUrl from '../../../services/api_branch_url'

function RelatedPerson({ open, setEditRelPerson, selectedbranch, patientDetails }) {
    // console.log(patientDetails, "patientdetails")

    const ibmId = localstore.getIbmId()
    const usertoken = localstore.getToken()
    const dispatch = useDispatch()
    const urls = BranchUrl()
    const states = useSelector(state => state.paramitha.states);
    const districts = useSelector(state => state.paramitha.districts);
    const subdistricts = useSelector(state => state.paramitha.subdistricts);
    const relationship = useSelector(state => state.paramitha.relationship);

    const [loading, setLoading] = useState(false)
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [language, setLanguage] = useState([])
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [gender, setGender] = useState('');
    const genderOptions = ["male", "female", "other"];
    const [relPerson, setRelPerson] = useState('')

    // console.log(relPerson, "patientdetails")

    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');

    const handleChangeState = (e, newValue) => {
        if (newValue) {
            setState(newValue);
            dispatch(getDistricts(usertoken, newValue));
        }
    };

    const handleChangeDistrict = (e, newValue) => {
        if (newValue) {
            setDistrict(newValue);
            dispatch(getSubDistricts(usertoken, newValue.toUpperCase()));
        }
    };

    const handleChangeCity = (e, newValue) => {
        setCity(newValue);
    };
    const handlePhoneChange = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setPhone(inputValue.slice(0, 10));
    }

    const handleChangePincode = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setPincode(inputValue.slice(0, 6));
    };
    const handleClose = () => {
        setEditRelPerson(false)
    }
    const handleGender = (e) => {
        setGender(e.target.value);
    }
    // console.log(relPerson, "relperosn")
    const handleRelatedPerson = (e, newValue) => {
        setRelPerson(newValue)
    }
    useEffect(() => {
        dispatch(getStates(usertoken));
        dispatch(getRelationship())
    }, []);

    useEffect(() => {
        if (patientDetails) {
            setAddress1(patientDetails?.contact?.[0]?.address?.line[0] || '');
            setAddress2(patientDetails?.contact?.[0]?.address?.line[1] || '');
            setPincode(patientDetails?.contact?.[0]?.address?.postalCode || '');
            const stateName = patientDetails?.contact?.[0]?.address?.state;
            const cityName = patientDetails?.contact?.[0]?.address?.city;
            const districtName = patientDetails?.contact?.[0]?.address?.district;
            const relatedPerson = relationship.find(
                r => r.display === patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[1]?.display
            );
            const communication = patientDetails?.communication?.map(options => options?.language?.coding?.[0])
            setLanguage(communication)
            setFirstname(patientDetails?.contact?.[0]?.name?.given[0] || '')
            setLastname(patientDetails?.contact?.[0]?.name?.family || '')
            setGender(patientDetails?.contact?.[0]?.gender || '')
            setPhone(patientDetails?.contact?.[0]?.telecom?.[0].value || '')
            setEmail(patientDetails?.contact?.[0]?.telecom?.[1].value || '')
            setRelPerson(relatedPerson)
            setState(stateName || '');
            setDistrict(districtName || '');
            setCity(cityName || '');

            if (stateName) {
                dispatch(getDistricts(usertoken, stateName));
            }
        }
    }, [patientDetails]);

    const handleSave = async () => {
        const data =
        {
            "resourceType": "Patient",
            "id": ibmId,

            "address": patientDetails?.address ? [
                {
                    "use": "home",
                    "type": "both",
                    // "text": "534 Erewhon St PeasantVille, Rainbow, Vic  3999",
                    "line": [
                        patientDetails?.address?.[0]?.line[0], patientDetails?.address?.[0]?.line[1]
                    ],
                    "city": patientDetails?.address?.[0]?.city,
                    "district": patientDetails?.address?.[0]?.city,
                    "state": patientDetails?.address?.[0]?.state,
                    "postalCode": patientDetails?.address?.[0]?.postalCode,

                }
            ] : [],
            "identifier": patientDetails?.identifier ? [
                {
                    "system": patientDetails?.identifier?.[0]?.system,
                    "value": patientDetails?.identifier?.[0]?.value
                }
            ] : [],
            "active": true,
            "name": patientDetails?.name ? [
                {
                    "use": "official",
                    "family": patientDetails?.name?.[0]?.family,
                    "text": patientDetails?.name?.[0]?.given[0],
                    "given": [patientDetails?.name?.[0]?.given[0]]
                }
            ] : [],
            "contact": [
                {
                    "relationship": [
                        {
                            "coding": [
                                {
                                    "system": "http://terminology.hl7.org/CodeSystem/v2-0131",
                                    "code": "C"
                                },
                                {
                                    "system": relPerson.system,
                                    "code": relPerson.code,
                                    "display": relPerson.display
                                },

                            ]
                        }
                    ],
                    "name": {
                        "family": lastname,
                        "_family": {
                            "extension": [
                                {
                                    "url": "http://hl7.org/fhir/StructureDefinition/humanname-own-prefix",
                                    "valueString": "VV"
                                }
                            ]
                        },
                        "given": [
                            firstname
                        ]
                    },
                    "telecom": [
                        {
                            "system": "phone",
                            "value": phone
                        },
                        {
                            "system": "email",
                            "value": email
                        }
                    ],
                    "address": {
                        "use": "home",
                        "type": "both",
                        "line": [
                            address1, address2
                        ],
                        "city": city,
                        "district": district,
                        "state": state,
                        "postalCode": pincode,
                    },
                    "gender": gender,

                }
            ],
            "telecom": patientDetails?.telecom ? [
                {
                    "system": "phone",
                    "value": patientDetails?.telecom?.[0]?.value,
                    "use": "work"
                },
                {
                    "system": "email",
                    "value": patientDetails?.telecom?.[1]?.value,
                    "use": "work"
                },

            ] : [],
            "gender": patientDetails?.gender,
            "birthDate": patientDetails?.birthDate,
            "managingOrganization": {
                "reference": `Organization/${selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`}`
            },
            "communication": language ? language?.map(options => {
                return {
                    'language': {
                        "coding": [
                            {
                                "system": "urn:ietf:bcp:47",
                                "code": options.code,
                                "display": options.display
                            }
                        ],
                    }
                }
            }) : []
        }
        try {
            setLoading(true)
            const res = await axios.put(`${urls.person_tenant_orgId}Patient/${ibmId}`, data, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                    'realm': 'parimitha',
                    'Authorization': `Bearer ${usertoken}`
                }
            })
            setAlertmessage('updated successfully')
            setAlertstatus('success')
            dispatch(getPatientdatafromibm(urls, usertoken, ibmId))
            handleClose()
            setAlertmessage('')
        } catch (error) {
            // setAlertmessage(error.message)
            // setAlertstatus('error')
            alert(error.message)
        }
        finally {
            setLoading(false)
        }
    }


    return (

        <div>
            <CommonDialog open={open} maxWidth="md" onClose={handleClose} sx={{ '.MuiDialog-paper': { top: { xs: 0, sm: 0, md: 20 } } }}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>Emergency contact person</Typography>
                    <IconButton onClick={handleClose}><Close sx={{ color: 'red' }} /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{
                    overflowY: 'scroll', height: { xs: '62vh', sm: '61vh', md: '61vh' }, '&::-webkit-scrollbar': {
                        display: 'none',
                    }
                }}>
                    <div className='px-2'>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>First Name</label>
                                </Box>
                                <CustomTextField
                                    placeholder="Enter First Name"
                                    type='text'
                                    borderColor="#59D8FA"
                                    borderRadius="7px"
                                    value={firstname}
                                    onChange={(e) => setFirstname(e.target.value)}
                                />
                            </Grid >
                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>Last Name</label>
                                </Box>
                                <CustomTextField
                                    placeholder="Enter Last Name"
                                    type='text'
                                    borderColor="#59D8FA"
                                    borderRadius="7px"
                                    value={lastname}
                                    onChange={(e) => setLastname(e.target.value)}
                                />
                            </Grid >
                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>Gender</label>
                                </Box>
                                <SelectOptions borderRadius="7px" value={gender} defaultName="Select your Gender" onChange={handleGender}>
                                    {genderOptions.map(option => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </SelectOptions>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>

                                <Box >
                                    <Box sx={{ mb: '4px' }}>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Related Person</label>
                                    </Box>
                                    <Autocomplete
                                        id="country-select-demo"
                                        // sx={{ width: 300 }}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                // padding: "2px 10px !important",
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#59D8FA',
                                                },
                                            },
                                            ".MuiInputBase-root": {
                                                borderRadius: '7px',
                                                // backgroundColor: 'whitesmoke',
                                            },


                                        }}
                                        options={relationship}
                                        size='small'
                                        autoHighlight
                                        value={relPerson}
                                        onChange={handleRelatedPerson} // Handle changes
                                        fullWidth
                                        getOptionLabel={(option) => option.display}
                                        // onInputChange={(e) => e?.target?.value && searchPolicyOwner(e.target.value)}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {option.display}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                // label="Related Person"
                                                placeholder='Related Person'
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid >

                        </Grid>

                        <div className='mt-2 font-semibold'>Contact Details</div>
                        <Divider />
                        <div className='mt-1'>
                            <Grid container spacing={3} >
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Phone</label>
                                    </Box>
                                    <CustomTextField
                                        placeholder="Enter Phone Number"
                                        type='text'
                                        borderColor="#59D8FA"
                                        borderRadius="7px"
                                        value={phone}
                                        onChange={handlePhoneChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Email</label>
                                    </Box>
                                    <CustomTextField
                                        placeholder="Enter Email"
                                        type='email'
                                        borderColor="#59D8FA"
                                        borderRadius="7px"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className='mt-2 font-semibold'>Address</div>
                        <Divider />
                        <div className='mt-1'>
                            <Grid container spacing={3} >
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box>
                                        <Box>
                                            <label style={{ fontWeight: 500, fontSize: '14px' }}>Address Line1</label>
                                        </Box>
                                        <CustomTextField
                                            placeholder="Enter your Address Line1"
                                            type='text'
                                            borderColor="#59D8FA"
                                            borderRadius="7px"
                                            value={address1}
                                            onChange={(e) => setAddress1(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box>
                                        <Box>
                                            <label style={{ fontWeight: 500, fontSize: '14px' }}>Address Line2</label>
                                        </Box>
                                        <CustomTextField
                                            placeholder="Enter your Address Line2"
                                            type='text'
                                            borderColor="#59D8FA"
                                            borderRadius="7px"
                                            value={address2}
                                            onChange={(e) => setAddress2(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Select State</label>
                                    </Box>
                                    <Autocomplete
                                        id="state-select"
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#59D8FA',
                                                },
                                            },
                                            ".MuiInputBase-root": {
                                                borderRadius: '7px',
                                            },
                                        }}
                                        options={states}
                                        size='small'
                                        autoHighlight
                                        value={state}
                                        onChange={handleChangeState}
                                        fullWidth
                                        getOptionLabel={(option) => option}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ textTransform: 'capitalize' }} {...props}>
                                                {option}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder='Select State'
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Select District</label>
                                    </Box>
                                    <Autocomplete
                                        id="district-select"
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#59D8FA',
                                                },
                                            },
                                            ".MuiInputBase-root": {
                                                borderRadius: '7px',
                                            },
                                        }}
                                        options={districts}
                                        size='small'
                                        autoHighlight
                                        value={district}
                                        onChange={handleChangeDistrict}
                                        fullWidth
                                        getOptionLabel={(option) => option}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                {option}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder='Select District'
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Select City/Sub-District</label>
                                    </Box>
                                    <Autocomplete
                                        id="city-select"
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#59D8FA',
                                                },
                                            },
                                            ".MuiInputBase-root": {
                                                borderRadius: '7px',
                                            },
                                        }}
                                        options={subdistricts}
                                        size='small'
                                        autoHighlight
                                        value={city}
                                        onChange={handleChangeCity}
                                        fullWidth
                                        getOptionLabel={(option) => option}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props}>
                                                {option}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder='Select City/Sub-District'
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>PinCode</label>
                                    </Box>
                                    <CustomTextField
                                        placeholder="Enter Pincode"
                                        type='text'
                                        borderColor="#59D8FA"
                                        borderRadius="7px"
                                        value={pincode}
                                        onChange={handleChangePincode}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                </DialogContent >
                <Divider />
                {/* <div className='text-center'> <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} /></div> */}

                <DialogActions sx={{ m: "5px 20px", }}>
                    <div>
                        <OutlinedButton text="Save Changes" borderColor="#1B5775" color="#1B5775" borderRadius="7px" endIcon={loading ? <CircularProgress size={20} color='inherit' /> : null} onClick={handleSave} />
                    </div>
                </DialogActions>

            </CommonDialog >

        </div >
    )
}

export default RelatedPerson;