// import React, { useState, useEffect, useRef } from "react";
// import { Box, Button, Card, Drawer, Grid, SwipeableDrawer, Modal, Dialog, Paper } from "@mui/material";
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// // import UploadMediaGallery from "./uploadMediaGalery";
// import axios from "axios";
// import { useSelector } from "react-redux";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';

// import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import { getMediaGallery, getMediaGalleryServices } from "../../redux/actions/actions";
// import ParticularMediaGallery from "../healthcare_departs/particularMediaGallery";
// // import { Opacity } from "@mui/icons-material";
// // import "./mediaGallery.css";

// const DialogOfGalleryImages = ({ open, close, particularItem, listOfImages, setItem, updatePostedList, getListApi }) => {
//     // console.log(props)

//     return (

//         <Dialog
//             open={open}
//             onClose={close}
//             fullWidth
//             maxWidth={'lg'}
//             minWidth={'sm'}
//         >
//             <div style={{ height: "100vh", overflow: "hidden" }}>
//                 <ParticularMediaGallery particularItem={particularItem} images={listOfImages} close={close} setItem={setItem} updateImageList={updatePostedList} getGallery={getListApi} />
//             </div>
//         </Dialog>

//     )
// }
// function Mediagalleryservices({ servicePageId }) {
//     // console.log(servicePageId, "healthcareservices")
//     // console.log(healthCareSerivces, "healthcareservices")
//     const [open, setOpen] = useState(false);
//     const [postedGalleryList, setPostedGalleryList] = useState([]);
//     const [openDrawer, setOpenDrawer] = useState(false);
//     const [selectedItem, setSelectedItem] = useState(null);
//     const [gallery, setGallery] = useState([])
//     const videoRef = useRef("");

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const mediaGallery = await getMediaGalleryServices(servicePageId);
//                 setPostedGalleryList(mediaGallery || [])
//             } catch (error) {
//                 console.error("Error fetching media gallery", error);
//             }
//         };

//         fetchData();
//     }, [servicePageId]);




//     const chunkArray = (arr, size) => {
//         return arr?.reduce((acc, _, i) => {
//             if (i % size === 0) {
//                 acc.push(arr?.slice(i, i + size));
//             }
//             return acc;
//         }, []);
//     };


//     const chunkedItems = chunkArray(postedGalleryList, 6);



//     const handleOpenDrawer = () => {
//         setOpenDrawer(true)

//     }

//     const handleCloseDrawer = () => {
//         setOpenDrawer(false)
//     }




//     return (
//         <div>
//             <div className='font-semibold text-lg '>Media Gallery</div>
//             <div className="mv1">
//                 {
//                     chunkedItems?.length > 0 ?
//                         <Paper elevation={3} sx={{ padding: "10px", minHeight: "150px", mt: 1 }}>




//                             <Carousel

//                                 style={{ height: "200px", width: "100%", border: "1px solid red", }}
//                                 showThumbs={false}
//                                 showStatus={false}
//                                 showIndicators={false}
//                                 renderArrowPrev={(onClickHandler, hasPrev, label) =>
//                                     hasPrev && (
//                                         <button type="button" onClick={onClickHandler} title={label}
//                                             className="carousalprevsBtn" >
//                                             <KeyboardArrowLeftIcon />
//                                         </button>
//                                     )
//                                 }
//                                 renderArrowNext={(onClickHandler, hasNext, label) =>
//                                     hasNext && (
//                                         <button type="button" onClick={onClickHandler} title={label}
//                                             className="carousalhasNextBtn "
//                                         >
//                                             <KeyboardArrowRightIcon />
//                                         </button>
//                                     )
//                                 }

//                             >
//                                 {
//                                     chunkedItems?.map((chunk, index) => (
//                                         <div key={index} style={{ display: 'flex' }}>
//                                             {chunk.map((item, i) => (
//                                                 <Card
//                                                     key={i}
//                                                     style={{ width: "200px", height: "200px", margin: "5px" }}
//                                                     onClick={() => {
//                                                         handleOpenDrawer();
//                                                         setSelectedItem(item);
//                                                     }}
//                                                 >
//                                                     {
//                                                         item?.mediaType == "IMAGE" && <img
//                                                             src={item?.sourceLink}
//                                                             alt=""
//                                                             style={{ width: '100%', height: '100%', objectFit: 'cover' }}
//                                                         />
//                                                     }
//                                                     {
//                                                         item?.mediaType == "VIDEO" &&
//                                                         item?.sourceLink !== null && <video style={{ backgroundColor: 'black' }} width="100%" height="100%" src={item?.sourceLink} controlsList="nodownload" controls ref={videoRef} />
//                                                     }

//                                                 </Card>
//                                             ))}
//                                         </div>
//                                     ))
//                                 }

//                             </Carousel >


//                         </Paper > : <div className='pt-3 text[16px] pl-6'>No Data Available</div>

//                 }

//                 <DialogOfGalleryImages open={openDrawer} close={handleCloseDrawer} particularItem={selectedItem} listOfImages={postedGalleryList} setItem={setSelectedItem} updatePostedList={setPostedGalleryList} />

//             </div >

//         </div>
//     )
// }

// export default Mediagalleryservices


import React, { useState, useEffect, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getMediaGallery, getMediaGalleryServices } from "../../redux/actions/actions";
import { ReactPhotoCollage } from "react-photo-collage";

function Mediagalleryservices({ servicePageId }) {
    const [open, setOpen] = useState(false);
    const [postedGalleryList, setPostedGalleryList] = useState([]);
    const videoRef = useRef("");

    // Fetch media gallery data using the servicePageId
    useEffect(() => {
        const fetchData = async () => {
            try {
                const mediaGallery = await getMediaGalleryServices(servicePageId);
                if (mediaGallery) {
                    setPostedGalleryList(mediaGallery);
                } else {
                    setPostedGalleryList([]);
                }
            } catch (error) {
                console.error("Error fetching media gallery", error);
            }
        };

        if (servicePageId) {
            fetchData();
        }
    }, [servicePageId]);

    // Settings for the photo collage (used when there are multiple images)
    const collageSettings = {
        width: "100%",
        height: ["200px", "200px"],
        layout: [1, 3],  // Adjust layout as per number of images
        photos: postedGalleryList.map((img) => ({
            source: img.sourceLink
        })),  // Map over your images array
        showNumOfRemainingPhotos: true,
    };

    return (
        <div>
            <div className="font-semibold text-base md:text-lg">Media Gallery</div>
            <div className="mt-1">
                {postedGalleryList.length > 0 ? (
                    <div>
                        {/* Render photo collage if there are images */}
                        <ReactPhotoCollage {...collageSettings} />
                    </div>
                ) : (
                    <p className="text-sm">No media available</p>  // Message when no media is available
                )}
            </div>
        </div>
    );
}

export default Mediagalleryservices;

