import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NoPage = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     navigate('/');
  //   }, 3000); // Redirect after 3 seconds

  //   return () => clearTimeout(timer); // Cleanup the timer on component unmount
  // }, [navigate]);

  return (
    <div className='text-2xl md:text-4xl text-center flex items-center justify-center h-screen'>
      404 Not Found
    </div>
  );
};

export default NoPage;
