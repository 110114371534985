
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHotkeys } from "react-hotkeys-hook";
import EmojiPicker from 'emoji-picker-react';
import { BsEmojiSmile } from "react-icons/bs";
import './chat.css';
import { Button } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
// import { Message } from "./components/Message";
import { Message } from "./message";
// import ChatBg from '../assets/chatbg.png';

export function ActiveChat({ sendJsonMessage, setSnackbarOpen, messageHistory, typing, adminSeen }) {

    // const [messageHistory, setMessageHistory] = useState([]);
    const [message, setMessage] = useState("");
    const [hasMoreMessages, setHasMoreMessages] = useState(false);
    // const [typing, setTyping] = useState(false);
    const [emojiOpen, setEmojiOpen] = useState(false);
    const [storeDate, setStoreDate] = useState([]);


    const timeout = useRef();
    const timeoutFunction = () => {
        sendJsonMessage({
            type: "message.typing",
            user: 'patient',
            typing: false
        });
    };
    function onType() {
        // if (!meTyping) {
        //   setMeTyping(true);
        //   sendJsonMessage({ type: "typing", typing: true });
        //   timeout.current = setTimeout(timeoutFunction, 5000);
        // } else {
        //   clearTimeout(timeout.current);
        //   timeout.current = setTimeout(timeoutFunction, 5000);
        // }
    }

    function handleChangeMessage(e) {
        setMessage(e.target.value);
        sendJsonMessage({
            type: "message.typing",
            user: 'patient',
            typing: true
        });

        // Reset the timeout to stop typing status after 2 seconds of inactivity
        clearTimeout(timeout.current);
        timeout.current = setTimeout(timeoutFunction, 2000);
    }

    useEffect(() => () => clearTimeout(timeout.current), []);

    const handleSubmit = () => {
        if (message.length === 0 || message.length > 512) return;
        // sendJsonMessage({
        //   type: "chat_message",
        //   message,
        //   "receiver_id":"1425645344",
        // });
        sendJsonMessage({
            "roomid": localStorage.getItem('roomId'),
            "content": message,
            "attachment": "",
            "urls": "",
            "subject": "help",

        })

        setMessage("");
        clearTimeout(timeout.current);
        setSnackbarOpen(false)
        timeoutFunction();
    };

    const inputReference = useHotkeys(
        "enter",
        () => {
            handleSubmit();
        },
        {
            enableOnTags: ["TEXTAREA"],
        }
    );

    useEffect(() => {
        if (inputReference.current) {
            (inputReference.current).focus();
        }
    }, [inputReference]);

    const chatEmoji = () => {
        setEmojiOpen(!emojiOpen);
    }

    const emojiClose = () => {
        setEmojiOpen(false);
    }

    const emojiSetFunc = (e) => {
        setMessage(message + e.emoji);
    }

    useEffect(() => {
        if (messageHistory?.length > 0) {
            const uniqueDates = [...new Set(messageHistory?.map(msg => formatMessageDate(msg?.timestamp)))];
            setStoreDate(uniqueDates);
        }
    }, [messageHistory]);
    const groupMessagesByDate = (messages) => {
        return messages.reduce((groups, message) => {
            const date = formatMessageDate(message.timestamp);
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(message);
            return groups;
        }, {});
    };

    function formatMessageDate(timestamp) {
        return new Date(timestamp).toLocaleString("en-US", { month: "short", day: "2-digit", year: "numeric" });
    }
    // console.log(messageHistory, "messagehistory")
    const sortedMessages = [...messageHistory].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    // console.log(sortedMessages, "sortedmessges")
    return (
        <div className=" flex-col" style={{ background: "#fdf3f7" }}>
            {/* <Box sx={{
                p: '5px 30px', overflowY: 'scroll',
                height: { xs: '100vh', sm: '100vh', md: '70vh' },
                '&::-webkit-scrollbar': {
                    display: 'none',
                }
            }}> */}
            <div
                id="scrollableDiv"
                style={{ height: `${window.innerHeight - 225}px`, backgroundSize: "cover" }}
                className="flex flex-col-reverse relative w-full px-2 overflow-y-auto"
            >
                <InfiniteScroll
                    dataLength={messageHistory.length}
                    // next={fetchMessages}
                    className="flex"
                    style={{ flexDirection: 'column' }}
                    inverse={true}
                    hasMore={hasMoreMessages}
                    loader={<CircularProgress className="flex self-center" />}
                    scrollableTarget="scrollableDiv"
                >
                    {/* {storeDate?.map((date) => (
                        <div key={date} className="text-center text-gray-500 my-2">{date}</div>
                    ))}
                    {sortedMessages?.map((msg) => (
                        <Message key={msg?.id} message={msg} />
                    ))} */}
                    {Object.entries(groupMessagesByDate(sortedMessages)).map(([date, messages]) => (
                        <React.Fragment key={date}>
                            <div className="text-center text-gray-500 my-2">{date}</div>
                            {messages?.map((msg) => (
                                <Message key={msg.id} message={msg} adminSeen={adminSeen} />
                            ))}
                        </React.Fragment>
                    ))}
                </InfiniteScroll>
            </div>
            {/* </Box> */}

            {/* {typing && <p className="text-gray-500 text-sm text-center py-1">Typing...</p>} */}

            {
                emojiOpen && (
                    <div className="absolute bottom-16 left-0 right-0 bg-white p-2 shadow-lg">
                        <EmojiPicker onEmojiClick={emojiSetFunc} />
                    </div>
                )
            }

            <div className="flex items-center p-2 border-t border-gray-200 bg-white">
                <span onClick={chatEmoji} className="p-2">
                    <BsEmojiSmile />
                </span>
                <textarea
                    // style={{ overflow: 'hidden' }}
                    value={message}
                    onChange={handleChangeMessage}
                    ref={inputReference}
                    className="w-full border-none p-2 rounded-md resize-none"
                    rows={1}
                    placeholder="Type a message"
                // onKeyDown={(e) => {
                //     if (e.key === "Enter") {
                //         handleSubmit()
                //     }
                // }} 
                />
                <Button onClick={handleSubmit} className="text-blue-500 capitalize" style={{ textTransform: 'capitalize' }}>
                    Send
                </Button>
            </div>
        </div >
    );
}
