import { Box, Button, Card, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import BranchImage from './branchimage'
import FindDoctorPage from './doctorPage'
import HealthcarePrograms from './HealthcarePrograms'
import FacilityInformation from './FacilityInfo'
import { useSelector } from 'react-redux'
import Ambulanceicon from '../../assets/ambulance-icon.png'
import EmergencyDeptIcon from '../../assets/24x7.jpg'
import axios from 'axios'
import EmergencyPopup from './EmergencyPopup'
import URL from '../../services/api_url'
import BranchUrl from '../../services/api_branch_url'
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton'
import { ArrowForward } from '@mui/icons-material'
import UrgentCarePopup from './UrgentcarePopup'
import { useNavigate } from 'react-router-dom'


const BranchHome = () => {
    const [orgInfo, setOrgInfo] = useState({})
    const [orgPageData, setOrgPageData] = useState({})
    const [qr, setQr] = useState(null)
    const branchName = useSelector((state) => state.branch.branch);
    const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
    const [emergencyOpen, setEmergencyOpen] = useState(false)
    const [urgentCareOpen, setUrgentCareOpen] = useState(false)
    const navigate = useNavigate()
    const baseurl = BranchUrl()
    const handleClose = () => {
        setEmergencyOpen(false)
    }
    const handleUrgentCloseDialog = () => {
        setUrgentCareOpen(false)
    }

    const handleClickAppoinment = () => {
        navigate(branchName ? `doctors` : '/doctors')
    }
    useEffect(() => {
        axios.get(`${baseurl.fhir_tenant_orgId}Organization`, {
            headers: {
                'X-FHIR-TENANT-ID': 'parimitha',
                'Realm': 'parimitha',
                'X-FHIR-DSID': `fhirparimitha`,
                'Cache-Control': 'no-cache'
            }
        }).then((res) => {
            setOrgInfo(res?.data)
            // console.log(res?.data)
        }).catch((err) => console.log(err))
    }, [selectBranchInfo])



    useEffect(() => {
        axios.get(`${URL.docsteth}PagesByIdWithoutKeycloak/${selectBranchInfo?.pageId}`, {
            headers: {
                'X-FHIR-TENANT-ID': 'parimitha',
                'X-FHIR-DSID': `fhirparimitha`
            }
        }).then((res) => {
            setOrgPageData(res?.data?.data)
        }).catch((err) => console.log(err))
    }, [selectBranchInfo])

    useEffect(() => {
        if (orgInfo?.id) {
            if (orgInfo?.identifier?.length === 4) {
                setQr(`https://phrsbx.abdm.gov.in/share-profile?hip-id=${orgInfo?.identifier?.[3]?.value}&counter-id=54321`)
            } else {
                setQr(null)
            }
        }
    }, [orgInfo.id, qr])

    return (
        <>

            <Box>
                <BranchImage pageId={selectBranchInfo?.pageId} orgPageData={orgPageData} />
            </Box>
            <Box sx={{ padding: { xs: '0px 0px', sm: '0px 0px', md: '0px 10px', lg: '0px 20px' } }}>
                <Box className="px-5 sm:px-20 pt-7">
                    {/* <div className='text-center font-semibold text-3xl'>Emergency Care</div>
                    <Grid container className='flex flex-row py-5 ' spacing={4} width={'100%'}>
                        <Grid item xs={12} md={6} >
                            <Card className='flex gap-4  p-3' style={{ border: '1px solid #8FC3CD', flex: '1 1 auto' }}>
                                <Box>
                                    <img src={EmergencyDeptIcon} alt='emer' width={100} height={100} />
                                </Box>
                                <Box className="flex-column gap-4">
                                    <div className='font-semibold text-lg'>Emergency Department</div>
                                    <div className='pt-3 font-medium h-16'>A Well Equipped Emergency Department, with Staff trained to save lives with Situational awareness and decission making.</div>
                                    <div className='pt-10'>
                                        <Button className='px-10 capitalize' variant='outlined' style={{ border: '1px solid #8FC3CD', color: '#3E97A8' }}  >Call Emergency</Button>
                                    </div>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card className='flex  gap-4 p-3' style={{ border: '1px solid #8FC3CD', flex: '1 1 auto' }}>
                                <Box>
                                    <img src={Ambulanceicon} alt='emer' width={130} height={130} />
                                </Box>
                                <Box className="flex-column gap-4">
                                    <div className='font-semibold text-lg'>Ambulance Services</div>
                                    <div className='pt-3 font-medium h-16'>A Well Equipped Trained staff and Well Equipped Ambulance is the key to save lives. We are there around the clock to save liveson time.</div>
                                    <div className='pt-10'>
                                        <Button className='px-10 capitalize' variant='contained' style={{ border: '1px solid #8FC3CD', color: '#fff', background: '#3E97A8' }} onClick={() => setEmergencyOpen(true)}>Book Ambulance</Button>
                                    </div>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid> */}
                    <Box >
                        <Grid container className='pt-5 align-start' spacing={5} flexDirection={{ xs: 'column', md: 'row' }} >
                            <Grid item xs={12} md={5} >
                                <Box className="border border-slate-300 " sx={{ height: { md: '290px', lg: '250px' }, flex: '1 1 auto' }}>
                                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/8Dn9MyRk3do?autoplay=1&mute=1" allowfullscreen></iframe>
                                    {/* <iframe width="100%" height="100%"
                                    src="https://www.youtube.com/embed/8Dn9MyRk3do?si=3ApKYMnq02duyhSy?autoplay=1&mute=1">
                                </iframe> */}
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={7} sx={{ padding: '0px 0px 0px 50px ' }}>
                                <Box>
                                    <div className='font-semibold text-xl md:text-2xl text-center' style={{ color: '#E54290' }}>Urgent Care Consultation</div>
                                </Box>
                                <Box className="pt-5 text-center">

                                    <div className='font-normal text-lg pt-2 md:px-20'>Request for urgent care is processed faster, cut down the waiting
                                        time & consultation is processed to immediate availability doctor</div>
                                </Box>
                                <Box className="flex justify-between pt-10 px-5 items-center" sx={{ flexDirection: { xs: 'column', md: 'row' }, gap: { xs: 2 }, justifyContent: { xs: 'center' } }}>
                                    <OutlinedButton text="About Urgent care" borderColor="#E54290" color="#E54290" height="47px" width="220px" sx={{
                                        '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
                                    }} onClick={() => setUrgentCareOpen(true)} />
                                    <ContainedButton text="Request Appointment" bgcolor="#E54290" height="47px" width="220px" onClick={handleClickAppoinment} />
                                </Box>
                            </Grid>

                        </Grid>
                        {/* <Box className="text-xs pt-10 font-semibold">*<span className='text-red-500'>Note:</span> For Trauma, Stroke or any other Life Threatening use Emergency Services</Box> */}
                    </Box>
                </Box>
                <Box className="pt-5">
                    <FindDoctorPage />
                </Box>
                <Box className="px-5 sm:px-20 pt-2">
                    <HealthcarePrograms />
                    <Box sx={{ mt: 9 }}>
                        <div className='text-center text-3xl font-medium' style={{ color: '#E54290' }}>About us</div>
                        <p className='font-normal text-center px-3 mt-2'>
                            Our facilities are equipped with state-of-the-art level-III neonatal and pediatric intensive care units complimented by our expert physicians who come with decades of experience to provide the best care for you and your bundle of joy.
                        </p>

                        <div className='mt-2 flex justify-center'>
                            <OutlinedButton text="Learn More" endIcon={<ArrowForward />} borderColor="#E54290" color="#E54290" height="47px" width="220px" borderRadius="10px" sx={{
                                display: 'flex', justifyContent: 'space-between', '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
                            }} />
                        </div>
                    </Box>
                    <FacilityInformation qr={qr} orgInfo={orgInfo} />
                </Box>
                <EmergencyPopup handleClose={handleClose} open={emergencyOpen} />
                <UrgentCarePopup open={urgentCareOpen} handleClose={handleUrgentCloseDialog} setIsUrgentCareOpen={setUrgentCareOpen} />
            </Box>
        </>
    )
}

export default BranchHome;