import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Grid, useMediaQuery, IconButton, Button } from '@mui/material';
import { Cancel, CancelOutlined, Directions, LocationOn, Restore } from '@mui/icons-material';
import { OutlinedButton } from '../atoms/commonbutton';
import ConsultationIcon from '../../assets/healthportal-icons/consultations.svg';
import { getAppointments, getUpcomingAppointments } from '../../redux/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../localstore/localstore';
import moment from 'moment';
import BranchUrl from '../../services/api_branch_url';
import VisitClinic from './visitClinic';

function Consultations() {
    const isXsScreen = useMediaQuery("(max-width:900px)");
    const usertoken = localstore.getToken();
    const pId = localstore.getPatientIbmId()
    const ibmId = localstore.getIbmId();
    const [appointmentDetails, setAppointmentDetails] = useState('')
    const dispatch = useDispatch();
    const urls = BranchUrl();
    const patientAppointments = useSelector((state) => state.paramitha.patientAppointments);
    const patientDetails = useSelector((state) => state.paramitha.patientData)
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);
    const [openClinic, setOpenClinic] = useState(false)
    // Get current time (IST)
    const currentTime = new Date();
    const currentTimeIST = new Date(currentTime.getTime());

    // Format the date and time in "YYYY-MM-DDTHH:MM" format
    const formattedTime = "gt" + currentTimeIST.toISOString().slice(0, 16);
    // console.log(patientAppointments, "patientappointments");



    useEffect(() => {
        if (usertoken && patientDetails.id) {
            dispatch(getUpcomingAppointments(urls, usertoken, patientDetails.id));
        }
    }, [usertoken, patientDetails.id]);

    const now = moment();
    // console.log(patientAppointments, "patientappointments")

    // const upcomingAppointments = patientAppointments?.entry?.filter(appointment => {
    //     const startDate = appointment?.resource?.start ||
    //         appointment?.resource?.requestedPeriod?.[0]?.start;
    //     return startDate && moment(startDate).isAfter(now);
    // }) || [];

    // const pastAppointments = patientAppointments?.entry?.filter(appointment => {
    //     const startDate = appointment?.resource?.start ||
    //         appointment?.resource?.requestedPeriod?.[0]?.start;
    //     return startDate && moment(startDate).isBefore(now);
    // }) || [];

    const allAppointments = patientAppointments?.entry || [];

    const upcomingAppointments = allAppointments?.filter(appointment => {
        const startDates = [
            appointment?.resource?.start,
            ...(appointment?.resource?.requestedPeriod || []).map(period => period.start)
        ].filter(Boolean); // Remove undefined or null values
        return startDates.some(date => moment(date).isAfter(now));
    });

    const pastAppointments = allAppointments.filter(appointment => {
        const startDates = [
            appointment?.resource?.start,
            ...(appointment?.resource?.requestedPeriod || []).map(period => period.start)
        ].filter(Boolean); // Remove undefined or null values
        return startDates.some(date => moment(date).isBefore(now));
    });

    const handleClickClinc = (value) => {
        setAppointmentDetails(value)
        setOpenClinic(true)
    }
    return (
        <div>
            <Box>
                {!isXsScreen &&
                    <Typography color={'#155775'} className='font-medium text-sm fntwt-600 flex align-item' gap={"10px"}>
                        <img src={ConsultationIcon} alt="consult" /> Consultations
                    </Typography>
                }
                <Box elevation={3} sx={{ p: { xs: "1px 1px", md: "10px 15px" }, mt: { xs: '1px', md: "10px" }, borderRadius: "5px" }} >
                    <Typography className='fntwt-600'>Upcoming Appointments</Typography>
                    <Box p={"5px 0px"}>
                        {upcomingAppointments?.length > 0 ? (
                            upcomingAppointments?.map((doctor, index) => {
                                const appointmentStatus = doctor?.resource?.status
                                return (
                                    <>
                                        <Grid container sx={{ mt: '15px' }} key={index}>
                                            <Grid item md={6} sm={6} pr={"10px"}>
                                                <Typography className='fntsz-14 fntwt-500 capitalize'>{doctor?.resource?.participant?.[1]?.actor?.display}</Typography>
                                                <Typography className='fntsz-13' mt="2px">{doctor?.resource?.participant?.[2]?.actor?.display || ''}</Typography>
                                                <div className='flex items-center text-xs mt-0.5'><span style={{ backgroundColor: '#F1FAFF', padding: '2px' }}><LocationOn sx={{ fontSize: 13 }} />
                                                    {/* Paramita Children's Hospital, */}
                                                    {selectedbranch?.name}</span></div>
                                            </Grid>
                                            <Grid item md={3} sm={3}>
                                                <Typography className='fntsz-14 capitalize'>{doctor?.resource?.appointmentType?.coding?.[0]?.code?.toLowerCase()}</Typography>
                                                <span><Button sx={{ fontSize: '12px', textTransform: 'capitalize' }} variant='outlined'> {doctor?.resource?.status}</Button></span>
                                                <Typography className='fntsz-13' mt="3px">Due {moment(doctor?.resource?.end).format('DD-MM-YYYY')}</Typography>

                                                <Typography sx={{ fontSize: { md: '12px' } }} mt="3px">
                                                    {moment(doctor?.resource?.start).utcOffset('-05:30').format('hh:mm A')} - {moment(doctor?.resource?.end).utcOffset('-05:30').format('hh:mm A')}
                                                </Typography>
                                            </Grid>

                                            <Grid item md={3} sm={3}>
                                                <Box className="mt-0 flex gap-3">
                                                    <div>
                                                        <IconButton><Directions /></IconButton>
                                                        <div style={{ fontSize: '10px' }}>Directions</div>
                                                    </div>
                                                    <div>
                                                        <IconButton><Restore /></IconButton>
                                                        <div style={{ fontSize: '10px' }}>Reschedule</div>
                                                    </div>
                                                    <div>
                                                        <IconButton><Cancel /></IconButton>
                                                        <div style={{ fontSize: '10px' }}>Cancel</div>
                                                    </div>
                                                </Box>

                                                {/* <OutlinedButton text={appointmentStatus === "booked" ? "Visit Clinc" : "Schedule Visit"} borderColor="#ED70AC" borderRadius="10px" width="140px" fontSize="14px" height="32px" onClick={() => appointmentStatus === "booked" ? handleClickClinc(doctor) : ''} /> */}
                                            </Grid>
                                        </Grid >
                                    </>

                                )
                            })
                        ) : (
                            <Typography className='text-medium md:mt-1 text-center'>No upcoming appointments</Typography>
                        )}
                    </Box>
                </Box>

                <Box elevation={3} sx={{ p: { xs: "1px 1px", md: "10px 15px" }, mt: { xs: '10px', md: "10px" }, borderRadius: "5px" }}>
                    <Typography className='fntwt-600'>Consultation History</Typography>
                    <Box p={"5px 0px"}>
                        {pastAppointments.length > 0 ? (
                            pastAppointments.map((doctor, index) => (
                                <Grid container sx={{ mt: '15px' }} key={index}>
                                    <Grid item xs={12} md={6} sm={6} pr={"10px"}>
                                        <Typography className='fntsz-14 fntwt-500 capitalize'>{doctor?.resource?.participant?.[1]?.actor?.display}</Typography>
                                        <Typography className='fntsz-13' mt="2px">{doctor?.PractitionerRole?.specialty?.[0]?.coding?.[0]?.display || ''}</Typography>
                                        <div className='flex items-center text-xs mt-0.5'><span style={{ backgroundColor: '#F1FAFF', padding: '2px' }}><LocationOn sx={{ fontSize: 13 }} /> Paramita Children's Hospital, {selectedbranch?.name}</span></div>
                                    </Grid>
                                    <Grid item xs={6} md={3} sm={3}>
                                        <Box className="mt-2"></Box>
                                        <Typography className='fntsz-14 capitalize'>{doctor?.resource?.appointmentType?.coding?.[0]?.code?.toLowerCase()}</Typography>
                                        <Typography className='fntsz-13' mt="3px">Due {moment(doctor?.resource?.end).format('DD-MM-YYYY')}</Typography>
                                    </Grid>
                                    <Grid item xs={6} md={3} sm={3}>
                                        <Box className="mt-2"></Box>
                                        <OutlinedButton text="View Summary" borderColor="#ED70AC" borderRadius="10px" disabled width="140px" fontSize="14px" height="32px" />
                                    </Grid>
                                </Grid>
                            ))
                        ) : (
                            <Typography className='text-medium mt-2 text-center'>No consultation history</Typography>
                        )}
                    </Box>
                </Box>
                <VisitClinic open={openClinic} setOpenClinic={setOpenClinic} appointmentDetails={appointmentDetails} />

            </Box >
        </div >
    );
}

export default Consultations;
