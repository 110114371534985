import React from 'react'

function LocationInstances() {
    return (
        <div>
            <div className='font-semibold text-base md:text-lg'>LocationInstances</div>
            <div className='pt-2 text-sm md:text[16px]'>No Data Available</div>
        </div>
    )

}

export default LocationInstances