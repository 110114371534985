import moment from 'moment';
// export const Date = date => {
//     return moment(date).format('DD-MMM-YYYY');
// };
export const DateFormat = (date, formatType) => {
    if (formatType === 'dd-mmm-yyyy') {
        return moment(date).format('DD-MMM-YYYY');
    } else if (formatType === 'dd-mmm-yyyy hh:mm:ss a') {
        // Format with time in 12-hour format
        return moment(date).format('DD-MMM-YYYY hh:mm:ss a');
    } else if (formatType === 'toISOString') {
        return moment(date).toISOString();
    } else if (formatType === 'nextYearToISOString') {
        // Add one year to the date and format as ISO string
        const nextYear = moment(date).add(1, 'year');
        return nextYear.toISOString();
    } else if (formatType === 'DateTime') {

        const momentDate = moment.utc(date);
        const formattedDate = momentDate.format('DD-MM-YYYY | HH:mm');
        return formattedDate;
    } else {
        // Default to a specific format if no formatType is provided
        return moment(date).format('DD-MMM-YYYY');
    }
};

