import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Button } from "@mui/material";
import Polling from "react-polling";
import axios from "axios";
// import { getMetaData, getPostById } from "../../../../src/Redux/actions/actions";
import poll_icon from '../../../assets/poll.svg';
// import { headers } from "../../../../redux/Actions/connectActions";
// import UserService from "../../../../services/UserService";
import { useDispatch } from "react-redux";
// import URL from "../../../utility/apiurl";
import { localstore } from "../../localstore/localstore";
// import UserService from "../../../services/UserService";
// import { header } from "../../../shared_modules/Header";


const pollStyles1 = {
    questionSeparator: false,
    questionSeparatorWidth: "question",
    questionBold: false,
    questionColor: "#4F70D6",
    align: "left",
    theme: "blue"
};

export function expiryFun(expiryDate) {
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const differenceMs = new Date(expiryDate) - new Date();
    const days = Math.round(differenceMs / ONE_DAY);

    return days > 0 ? (days > 1 ? `Expires in ${days} days` : `Expires in ${days} day`) : days === 0 ? 'Expires Today' : 'Expired';
}

export default function FeedPoll({ docFeed }) {
    const [load, setLoad] = useState(false);
    const [polldata, setPolldata] = useState(docFeed);
    const [pollAnswers1, setPollAnswers1] = useState(docFeed.pollData?.pollResponseData);

    const dispatch = useDispatch();
    const usertoken = localstore.getToken()
    console.log("polldata1", pollAnswers1)
    console.log("docFeed", docFeed)

    useEffect(() => {
        setPolldata(docFeed)

    }, [docFeed])

    useEffect(() => {
        setLoad(true);
    }, [docFeed]);

    // const handleVote = async (voteAnswer, pollAnswers) => {
    //     const newPollAnswers = pollAnswers.map((answer) => {
    //         if (answer.option === voteAnswer) answer.votes++;
    //         return answer;
    //     });
    //     setPollAnswers1(newPollAnswers);

    //     let data = {
    //         "metadata": await getMetaData(),
    //         "postId": polldata.id,
    //         "questionId": polldata.pollData.id,
    //         "response": voteAnswer,
    //         "userId": sessionStorage.getItem('userId')
    //     };

    //     axios.post(`${URL.addPoll}`, data, header()).then(res => {
    //         if (res.status === 200 || 201) {
    //             setLoad(true);
    //             UpdatePostCache("add_pollresponse", polldata.id);
    //         }
    //         getpolldata();
    //     }).catch(err => console.log(err));
    // };

    // const UpdatePostCache = async (type, id) => {
    //     let postData = "";
    //     await axios.get(`${URL.postById}/${id}`, header())
    //         .then((response) => {
    //             postData = response.data;
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });

    //     let data = {
    //         update: type,
    //         new_post: postData,
    //         post_id: id,
    //     };
    //     UpdateChacheFeed(data);
    // };

    // const UpdateChacheFeed = async (data) => {
    //     await axios.post(`${URL.updateChacheFeed}`, data, header())
    //         .then(res => {
    //             console.log(res);
    //         }).catch(err => {
    //             console.log(err);
    //         });
    // };

    // const handleresponse = () => {
    //     let id = polldata.pollData.pollId;
    //     setLoad(false);
    //     axios.delete(`${URL.deleteresponse}/${id}`, header()).then(res => {
    //         UpdatePostCache("delete_pollresponse", polldata.id);
    //         getpolldata();
    //     });
    // };

    // const getpolldata = async () => {
    //     let data = await getPostById(polldata.id);
    //     console.log(data, "polledd")
    //     if (data.id) {
    //         setPolldata(data);
    //         setPollAnswers1(data.pollData.pollResponseData);
    //         setLoad(true);
    //     } else if (data.error) {
    //         setLoad(true);
    //     }
    // };

    return (
        <Box sx={{ px: "10px", height: { md: 300, lg: 430 } }}>


            {polldata &&

                <Grid container >
                    <Grid item
                    >
                        <Typography component={'div'} className="d-flex" sx={{ justifyContent: 'space-between', p: polldata.border ? '10px' : '0 5px' }}>
                            <p className="color_head d-flex e-align" style={{ fontSize: '18px' }}>
                                {polldata.border ? <><img src={poll_icon} alt={'poll'} width="25px" height="25px" className="celebrate" />&nbsp;</> : ''}
                                {polldata.pollTitle}</p> {expiryFun(polldata.pollData.expiry)}
                        </Typography>
                        {/* {load && (
                            <>
                                <Polling
                                    url={`${URL.postById}/${polldata?.id}`}
                                    interval={10000} // Poll every 10 seconds
                                    retryCount={3}
                                    headers={{ Authorization: `Bearer ${usertoken}` }}
                                    onSuccess={(response) => {
                                        console.log("success", response)
                                        const data = response.data != undefined ? response.data : polldata;
                                        setPolldata(data);
                                        setPollAnswers1(data?.pollData?.pollResponseData);
                                    }}
                                    onFailure={(error) => {
                                        console.log(error);
                                        return true; // Continue polling
                                    }}
                                >
                                    {() => (
                                        <>
                                            <div>
                                                <Typography component="div" sx={{ mt: 2 }}>
                                                    {polldata?.pollData?.question}
                                                </Typography>
                                                <ul style={{}} >
                                                    {pollAnswers1?.map((answer, index) => (
                                                        <li key={index} style={{ listStyleType: 'none', margin: '8px 0' }}>
                                                            <Button className="capitalize"
                                                                sx={{
                                                                    border: '1px solid #4F70D6',
                                                                    borderRadius: '12px',
                                                                    width: '100%',
                                                                    justifyContent: 'center'
                                                                }}
                                                            onClick={() => handleVote(answer.option, pollAnswers1)}
                                                            disabled={polldata.pollData.pollAnswered}
                                                            >
                                                                {answer.option} - {answer.votes} votes
                                                            </Button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {(!load || polldata.pollData.pollAnswered) && (
                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    {expiryFun(polldata.pollData.expiry) !== "Expired" && (
                                                        <Button onClick={handleresponse} className="undo_btn">undo</Button>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Polling>
                            </>
                        )} */}
                    </Grid>
                </Grid>
            }
        </Box >
    );
}