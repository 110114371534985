import { TextField } from "@mui/material";
import { styled } from "@mui/styles";

export const CommentTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        // padding: '11px 15px',
        borderRadius: '10px',
        minHeight: '30px!important',
        padding: '15px 15px'
    },
    width: '100%'
});