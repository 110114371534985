import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, Grid, Paper, Typography } from '@mui/material';
import axios from 'axios';
import URL from '../../services/api_url';
import { useSelector } from 'react-redux';
import healthserviceicon from '../../assets/healthcareprograms/Health Department 2.svg'
import { useNavigate } from 'react-router-dom';
import BranchUrl from '../../services/api_branch_url';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
};
function ServiceOverview({ deptData }) {
    const navigate = useNavigate()
    const baseurl = BranchUrl()
    const branchName = useSelector((state) => state.branch.branch);
    // console.log(deptData, "depta")
    const [editAboutText, setEditAboutText] = useState('');
    const [about, setAbout] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false); // State to track truncation
    const textRef = useRef(null); // Ref to access the text div

    useEffect(() => {
        if (deptData?.extraDetails) {
            if (!about) {
                // Initially show only the truncated text (first 5 lines)
                setEditAboutText(deptData?.extraDetails);
            } else {
                setEditAboutText(deptData?.extraDetails);
            }
        } else {
            setEditAboutText('No data available');
        }
    }, [deptData, about]);

    useEffect(() => {
        // Check if the text is truncated (i.e., it's overflowing)
        if (textRef.current) {
            const isTextTruncated = textRef.current.scrollHeight > textRef.current.clientHeight;
            setIsTruncated(isTextTruncated);
        }
    }, [editAboutText]); // Check if the content is truncated whenever the content changes


    const handleToggleAbout = () => {
        setAbout(prevAbout => !prevAbout);
    };

    const headers = {
        'X-FHIR-TENANT-ID': 'parimitha',
        'X-FHIR-DSID': `fhirparimitha`,
        'realm': 'parimitha',
        'Cache-Control': 'no-cache',
    }
    function AreaOfLocation({ locationId, headers }) {
        const [serviceData, setServiceData] = useState(null);

        useEffect(() => {
            if (locationId) {
                axios.get(`${baseurl.fhir_tenant_orgId}Location/${locationId}`, { headers })
                    .then((res) => {
                        setServiceData(res?.data);
                    })
                    .catch((err) => console.log(err));
            }
        }, [locationId, headers]);

        const handleSelectService = (title, id) => {
            let name = title.replace(/\//g, '')
            navigate(branchName ? `/${branchName}/healthcareservices/${name}/${id}` : `/healthcareservices/${name}/${id}`)
            window.location.reload();
        }

        return (
            <Paper elevation={2} className='flex items-center cursor-pointer px-2 py-2' onClick={() => handleSelectService(serviceData?.name, serviceData?.id)}>
                <img
                    src={serviceData?.photo?.url || healthserviceicon}
                    alt=""
                    width={35}
                    height={35}
                    style={{ marginRight: "8px", height: '35px' }}
                />
                <Box className="truncate">
                    <Grid className='text-base font-semibold capitalize' sx={{ color: "#1D5775" }} >
                        {serviceData?.name}
                    </Grid>
                    <Grid className="truncate text-sm" sx={{ color: "#525C79" }}  >
                        {serviceData?.comment}
                    </Grid>
                </Box>
            </Paper>
        );
    }

    return (
        <div>
            {/* <div className='text-base md:text-lg pb-1 font-semibold '>Description </div> */}
            <div className='text-base md:text-lg pb-1 font-semibold'>About</div>
            <div>
                <Typography my={1}>
                    <div
                        ref={textRef}
                        dangerouslySetInnerHTML={{ __html: editAboutText }}
                        style={{
                            display: !about ? '-webkit-box' : 'block',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: about ? 'none' : 5, // Show 5 lines when truncated
                            whiteSpace: 'normal',
                        }}
                    />
                </Typography>

                {/* Only show the button if the content is truncated */}
                {isTruncated && (
                    <div className='text-center mt-2' onClick={handleToggleAbout}>
                        {about ? (
                            <Button
                                variant="outlined"
                                sx={{
                                    borderColor: "#E54290",
                                    textTransform: "capitalize",
                                    color: "#E54290",
                                    borderRadius: "10px",
                                    width: "150px",
                                    '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
                                }}
                                endIcon={<ExpandLess />}
                            >
                                <Typography sx={{ fontSize: { xs: '14px', md: "16px", fontWeight: 500 } }}>Show Less</Typography>
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"
                                sx={{
                                    borderColor: "#E54290",
                                    textTransform: "capitalize",
                                    color: "#E54290",
                                    borderRadius: "10px",
                                    width: "150px",
                                    '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
                                }}
                                endIcon={<ExpandMore />}
                            >
                                <Typography sx={{ fontSize: { xs: '14px', md: "16px", fontWeight: 500 } }}>Show More</Typography>
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div >
    )
}

export default ServiceOverview;
