import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { FaArrowRightToBracket } from "react-icons/fa6";

import styles from "./styles.module.css";
import { navbarJson } from "../../shared/utils";
import { useNavigate } from "react-router-dom";
import EmergencyPopup from "../branchhome/EmergencyPopup";
import { useDispatch, useSelector } from "react-redux";
import UrgentCarePopup from "../branchhome/UrgentcarePopup";
import { Cancel, Close } from "@mui/icons-material";
import { localstore } from "../localstore/localstore";
import TelemedicinePopup from "../branchhome/TelemedicinePopup";
import { selectedBranchInfo } from "../../redux/slices/SelectedBranchSlice";
// import { Button } from "react-scroll";

const NavbarMenuItems = ({ menuItemsData, handleCloseNavMenu, onClose }) => {
  const navigate = useNavigate();

  const lengthOfItems = menuItemsData.length;

  const dispatch = useDispatch();

  const branchList = useSelector((state) => state.login.branchLists);
  console.log(branchList, "urgentCarePopup123",)

  const [selectBranchPopup, setSelectBranchPopup] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  // const branchName = useSelector((state) => state.branch.branch);
  // const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);

  const [selectedItem, setSelectedItem] = useState("")
  const [isEmergencyOpen, setIsEmergencyOpen] = useState(false);
  const [isUrgentCareOpen, setIsUrgentCareOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
  const branchName = useSelector((state) => state.branch.branch);
  const usertoken = localstore.getToken()
  const [telemedicineOpen, setTelemedicineOpen] = useState(false)

  const ibmId = localstore.getIbmId()

  const handleNext = () => {
    if (selectedBranch) {
      if (selectedItem == "Emergency Services") {
        setIsEmergencyOpen(true)
      } else {
        setIsUrgentCareOpen(true)
      }
      setSelectBranchPopup(false)
      const sanitizedLocation = {
        ...selectedBranch,
        name: selectedBranch?.name?.replace(/[\s,]+/g, '').toLowerCase(),
      };
      // setSelectedLocationName(selectedBranch?.name);
      localStorage.setItem("selectOrg", selectedBranch?.name); // Store the selected index in localStorages
      // dispatch(branchInfo(sanitizedLocation?.name));
      localStorage.setItem("brName", sanitizedLocation?.name);
      localStorage.setItem("selectedbranch", JSON.stringify(selectedBranch));
      dispatch(selectedBranchInfo(selectedBranch));
    } else {
      setAlertMessage('please select the branch')
    }
  }

  const handleClose = () => {
    setSelectBranchPopup(false)
    setSelectedBranch(null)
    setAlertMessage('')
  }

  const handleCloseDialog = () => {
    setIsEmergencyOpen(false);
  };
  const handleUrgentCloseDialog = () => {
    setIsUrgentCareOpen(false);
  };

  const handleTeleCloseDialog = () => {
    setTelemedicineOpen(false);
  };
  const handleItemClick = (item) => {
    if (item.url) {
      onClose(); // Close the popover\
      navigate(branchName ? `/${branchName}${item.url}` : item.url);
      handleCloseNavMenu()
    } else if (item.title === "Emergency Services") {
      // if(branchName){
      setSelectedItem("Emergency Services")
      selectBranchInfo?.id ? setIsEmergencyOpen(true) : setSelectBranchPopup(true)
      // }
      // else{
      //   alert("Please Select Branch")
      // }


    } else if (item.title === "Urgent Care") {
      // if(branchName){
      //   setIsUrgentCareOpen(true);
      // }
      // else{
      //   alert("Please Select Branch")
      // }
      setSelectedItem("Urgent Care")
      selectBranchInfo?.id ? setIsUrgentCareOpen(true) :

        setSelectBranchPopup(true)

    } else if (item.title === "Telemedicine") {
      setTelemedicineOpen(true);
    }
  };


  const handleLogOut = () => {
    localStorage.removeItem("kctoken");
    localStorage.removeItem("IbmId");
    localStorage.removeItem("rctoken")
    localStorage.removeItem("userName")
    localStorage.removeItem("participantId")
    localStorage.removeItem("visitorId")
    localStorage.removeItem("pId")
    localStorage.removeItem("roomId")
    localStorage.removeItem("userId")
    localStorage.removeItem("patientName")
    window.location.href = '/';
  }
  return (
    <>

      <Dialog
        open={selectBranchPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth // Ensures it takes up the full width allowed
        PaperProps={{
          sx: { height: "30%" }, // Custom width & height
        }}

      >
        <DialogTitle id="alert-dialog-title">Please Select the Branch</DialogTitle>

        <DialogContent sx={{ paddingTop: "10px" }}>

          <Autocomplete
            options={branchList || []}
            getOptionLabel={(option) => option.name}
            value={selectedBranch}
            sx={{ paddingTop: "10px" }}
            onChange={(event, newValue) => {
              setSelectedBranch(newValue)
              setAlertMessage('')
            }}
            renderInput={(params) =>
              // <TextField {...params} label="Select Branch" variant="outlined" fullWidth />
              <TextField
                {...params}
                placeholder="Select the Branch"
                variant="outlined"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    height: "42px", // Adjust height as needed
                    minHeight: "42px",
                    borderRadius: "7px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "8px", // Adjust padding for better appearance
                  }
                }}
              />
            }
          />

        </DialogContent>
        <div className="text-center text-[red] text-xs md:text-sm">{alertMessage}</div>
        <DialogActions >
          <Button onClick={handleClose} sx={{ fontSize: "15px", textTransform: "capitalize" }}>Close</Button>
          <Button onClick={handleNext} autoFocus sx={{ fontSize: "15px", textTransform: "capitalize" }}>
            Next
          </Button>
        </DialogActions>
      </Dialog>

      <div className={styles.container}>
        <Grid container columnSpacing={3} rowSpacing={2} className="NavbarMenuItems">
          <Grid item xs={12} md={3} >
            <div>
              <Grid container
                className={styles.portal}
              >
                {navbarJson.map((item, index) => (
                  <Grid item key={index} className={styles.navbarItem} gap={2} onClick={() => handleItemClick(item)}>
                    {React.createElement(item.icon, {
                      style: { color: "#fff", fontSize: "40px" },
                    })}
                    <div style={{ paddingBottom: "15px", cursor: 'pointer', paddingTop: "10px" }}>
                      <div style={{ color: 'white' }}
                      // onClick={(() => item?.url ? navigate(item.url) : console.log(''))}
                      >
                        {item.title}
                      </div>
                      <div
                        // className="text-[11px] text-white"
                        className={[styles.descriptionText]}>
                        {item.description}
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <div className={styles.loginbuttoncontainer}>
                <div >
                  <div className={[styles.patientportaltext].join(" ")}>Patient Portal</div>

                  <div className={styles.logintext}>
                    {usertoken && ibmId ? <span className="cursor-pointer"
                      onClick={
                        handleLogOut
                        // onClose()
                      }>Logout</span> :
                      // onClick={() => {
                      //   handleLogOut()
                      //   // onClose()
                      // }}>Logout</span> :
                      <div>
                        <span className="cursor-pointer"
                          onClick={() => {
                            navigate(branchName ? `/${branchName}/login` : '/login')
                            onClose()
                          }}>Login</span>
                        <span className="px-2">/</span>
                        <span className="cursor-pointer" onClick={() => {
                          navigate(branchName ? `/${branchName}/signup` : '/signup')
                          onClose()
                        }}>Register</span>
                      </div>
                    }

                  </div>
                </div>
                <FaArrowRightToBracket className={styles.righttobracketicon} style={{ fontSize: '38px' }} />
              </div>
            </div>
          </Grid >
          <Grid item xs={0} sm={0} md={0.5}>
            <Divider
              style={{ backgroundColor: "#fff", width: "1%" }}
              orientation="vertical"
              variant="medium"
            />
          </Grid>
          {/* <Grid item md={8.5}>
          <Grid container rowGap={10} columnGap={10}>
            {menuItemsData?.map((serviceMenu, index) => {
              return (
                <Grid
                  item
                  style={{ p: "10px" }}
                  // md={12 / lengthOfItems}
                  // className={styles.serviesmenugridcontainer}
                  key={index}
                >
                  <Grid className="text-white text-base pb-3 pt-1  " >
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>{serviceMenu.title}</Typography>
                  </Grid>
                  {serviceMenu.services.map((service, index) => (
                    <Grid className="text-sm pb-5 text-white" key={index} style={{ fontWeight: "lighter" }} onClick={(() => {
                      if (service?.url) {
                        onClose()
                        navigate(service?.url)
                      }
                    })}>
                      <Typography sx={{ fontSize: "14px", cursor: 'pointer' }}> {service?.name}</Typography>
                    </Grid>
                  ))}
                </Grid>
              );
            })}
          </Grid>
        </Grid> */}


          {/* test */}

          <Grid item xs={12} md={8.5}>
            <Grid container columnSpacing={10} justifyContent={{ xs: 'center', sm: 'center', md: 'start' }}>
              {menuItemsData?.map((serviceMenu, index) => (
                <Grid
                  item
                  sx={{ padding: { xs: "0px 10px", sm: "0px", md: "0px" }, textAlign: { xs: "center", sm: "left" } }} // Center the content
                  key={index}
                >
                  <div className="text-white text-base pb-3 pt-1">
                    <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                      {serviceMenu.title}
                    </Typography>
                  </div>
                  {serviceMenu.services.map((service, index) => (

                    <div
                      className="text-sm pb-5 text-white"
                      key={index}
                      style={{ fontWeight: "lighter", cursor: "pointer" }}
                      onClick={() => {
                        if (service?.url) {
                          onClose();
                          navigate(branchName ? `/${branchName}${service?.url}` : service?.url);
                        }
                      }}
                    >
                      {console.log(service, "service of nav bar")}
                      <Typography sx={{ fontSize: "14px", pointerEvents: service.name === "Diagnostics Tests" && "" }}>{service?.name}</Typography>

                    </div>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <EmergencyPopup open={isEmergencyOpen} handleClose={handleCloseDialog} />
          <UrgentCarePopup open={isUrgentCareOpen} handleClose={handleUrgentCloseDialog} setIsUrgentCareOpen={setIsUrgentCareOpen} />
          {/* {item.title === "Telemedicine" && */}
          <TelemedicinePopup open={telemedicineOpen} handleClose={handleTeleCloseDialog} setTelemedicineOpen={setTelemedicineOpen} />
          {/* } */}
        </Grid >

        <div className="text-center" onClick={onClose}>
          <IconButton sx={{ color: '#FFFFFF' }}><Close sx={{ fontSize: '32px' }} /></IconButton>
        </div>
      </div >
    </>


  );
};

export default NavbarMenuItems;
