"use client";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  // makeStyles,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import LoginIcon from "@mui/icons-material/Login";
import DoctorImg from '../../assets/images/calendar.svg';
import DoctorImg_wt from '../../assets/doctor/Find Doctor.svg';
import CareImg from '../../assets/images/URGENT CARE.svg';
import CareImg_wt from '../../assets/doctor/Uegetcare.svg';
// import EmergencyImg from '../../assets/images/ambulance-icon.svg';
import EmergencyImg from '../../assets/doctor/ambulance-icon.svg';
import TeleImg from '../../assets/images/consult online.svg';
import TeleImg_wt from '../../assets/doctor/Telemedicine.svg';
import PortalImg from '../../assets/images/login.svg';
import PortalImg_wt from '../../assets/doctor/login.png';
import EmergencyPopup from "../branchhome/EmergencyPopup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UrgentCarePopup from "../branchhome/UrgentcarePopup";
import TelemedicinePopup from "../branchhome/TelemedicinePopup";
import { handleLogout } from "../atoms/handlelogout";
import { localstore } from "../localstore/localstore";
import { selectedBranchInfo } from "../../redux/slices/SelectedBranchSlice";

const useStyles = makeStyles((theme) => ({
  heading1: {
    textAlign: "center",
    fontWeight: 500,
    color: "#1D5775",
    // textTransform: "uppercase",
  },
  textcolor: {
    // color: "#1D5775",
    fontWeight: "normal",
    // fontSize: "1.3vw",
    fontSize: { xs: '14px', md: '16px' }
  },
  // bodycolor: {
  //   color: "#898989",
  // },
  card: {
    borderRadius: "10px",
    cursor: "pointer",
    backgroundColor: '#FDF3F7 !important',
    transition: "background-color 0.3s, color 0.3s",
    "&:hover": {
      backgroundColor: "#E54290 !important",  // Change to your desired hover background color
      color: "#FFFFFF",            // Change to your desired hover text color
    },
  },
}));

const CardsData = [
  {
    title: "Find Doctor",
    description: "Schedule a Visit",
    icon: <FollowTheSignsIcon Centre sx={{ fontSize: 48, mr: "10px" }} />,
    imgSrc: DoctorImg,
    imgSrchover: DoctorImg_wt,
    url: '/doctors'
  },
  {
    title: "Urgent Care",
    description: "Immediate Consult",
    icon: <FollowTheSignsIcon sx={{ fontSize: 35, mr: "10px" }} />,
    imgSrc: CareImg,
    imgSrchover: CareImg_wt,

  },
  {
    title: "Emergency",
    description: "Ambulance Services",
    icon: <LoginIcon sx={{ fontSize: 50, mr: "10px" }} />,
    imgSrc: EmergencyImg,
    imgSrchover: EmergencyImg,
  },
  {
    title: "Telemedicine",
    description: "Consult Online",
    icon: <LoginIcon sx={{ fontSize: 35, mr: "10px" }} />,
    imgSrc: TeleImg,
    imgSrchover: TeleImg_wt,
  },
  // {
  //   title: "Talk To Experts",
  //   description: "Get answers & Support",
  //   icon: <AddIcCallIcon sx={{ fontSize: 48, mr: "10px" }} />,
  //   imgSrc: "/images/login.svg",
  // },
  {
    title: "Patient Portal",
    description: "Digital Health Login",
    //   icon: <AddIcCallIcon sx={{ fontSize: 48, mr: "10px" }} />,
    imgSrc: PortalImg,
    imgSrchover: PortalImg_wt,
  },
];

const HelpComponent = ({ urgentCarePopup, setUrgentCarePopup }) => {
  // console.log(urgentCarePopup, "urgentCarePopup11111111111111")
  const Classes = useStyles();
  const dispatch = useDispatch();
  const branchName = useSelector((state) => state.branch.branch)
  const [emergencyOpen, setEmergencyOpen] = useState(false)
  const [urgentCareOpen, setUrgentCareOpen] = useState(false)
  const [telemedicineOpen, setTelemedicineOpen] = useState(false)
  const [hoveredCard, setHoveredCard] = useState(null);
  const [selectBranchPopup, setSelectBranchPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState('')
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedItem, setSelectedItem] = useState("")
  const navigate = useNavigate()
  const usertoken = localstore.getToken()
  const branchList = useSelector((state) => state.login.branchLists);
  const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
  // console.log(selectBranchInfo, "branchName123321")

  useEffect(() => {

    if (urgentCarePopup) {
      setUrgentCareOpen(true)
    }


  }, [urgentCarePopup])

  const handleClose = () => {
    setSelectBranchPopup(false)
    setSelectedBranch(null)
    setAlertMessage('')
  }

  const handleNext = () => {
    if (selectedBranch) {

      if (selectedItem == "Emergency Services") {
        setEmergencyOpen(true)
      } else {
        setUrgentCarePopup(true)
      }
      // setUrgentCarePopup(true)
      setSelectBranchPopup(false)
      const sanitizedLocation = {
        ...selectedBranch,
        name: selectedBranch?.name?.replace(/[\s,]+/g, '').toLowerCase(),
      };
      // setSelectedLocationName(selectedBranch?.name);
      localStorage.setItem("selectOrg", selectedBranch?.name); // Store the selected index in localStorages
      // dispatch(branchInfo(sanitizedLocation?.name));
      localStorage.setItem("brName", sanitizedLocation?.name);
      localStorage.setItem("selectedbranch", JSON.stringify(selectedBranch));
      dispatch(selectedBranchInfo(selectedBranch));

    } else {
      setAlertMessage("please select the branch")
    }
  }



  const handleSelectOpen = (title, item, urgent) => {
    console.log(urgent, "urgentCarePopup11111111111111")
    if (title === "Emergency") {
      setSelectedItem("Emergency Services")
      selectBranchInfo?.id ? setEmergencyOpen(true) : setSelectBranchPopup(true)

    } else if (title === 'Urgent Care') {
      setSelectedItem("Urgent Care")
      selectBranchInfo?.id ? setUrgentCarePopup(true) : setSelectBranchPopup(true)

    } else if (title === 'Telemedicine') {

      setTelemedicineOpen(true)

    } else if (title === 'Find Doctor') {
      navigate(branchName ? `${branchName}/doctors` : '/doctors')
    } else if (title === 'Patient Portal') {
      if (usertoken) {
        handleLogout()
      } else {
        navigate(branchName ? 'login' : '/login')
      }
    } else if (item?.url) {
      navigate(item?.url)
    }

  }

  const handleCloseDialog = () => {
    setEmergencyOpen(false)
  }
  const handleUrgentCloseDialog = () => {
    setUrgentCareOpen(false);
    setUrgentCarePopup(false);
  };
  const handleTeleCloseDialog = () => {
    setTelemedicineOpen(false);
  };

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  }
  return (
    <>

      <Dialog
        open={selectBranchPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth // Ensures it takes up the full width allowed
        PaperProps={{
          sx: { height: "30%" }, // Custom width & height
        }}

      >
        <DialogTitle id="alert-dialog-title"> Please Select the Branch</DialogTitle>

        <DialogContent sx={{ paddingTop: "10px" }}>

          <Autocomplete
            options={branchList || []}
            getOptionLabel={(option) => option.name}
            value={selectedBranch}
            sx={{ paddingTop: "10px" }}
            onChange={(event, newValue) => {
              setSelectedBranch(newValue)
              setAlertMessage('')
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                variant="outlined"
                placeholder="Select the Branch"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    height: "42px", // Adjust height as needed
                    minHeight: "42px",
                    borderRadius: "7px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "8px", // Adjust padding for better appearance
                  }
                }}
              />
            }
          />

        </DialogContent>

        <div className="text-center text-[red]">{alertMessage}</div>
        <DialogActions >
          <Button onClick={handleClose} sx={{ fontSize: "15px", textTransform: "capitalize" }}>Close</Button>
          <Button onClick={handleNext} autoFocus sx={{ fontSize: "15px", textTransform: "capitalize" }}>
            Next
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          mt: { xs: "-200px", sm: "0px", md: "0px" },
          p: { xs: "0px 10px 0px 12px", sm: "0px", md: "0px" },
        }}
      >
        <Typography
          // level="h1"

          sx={{ color: "#E54290", textAlign: 'center', cursor: 'pointer', fontWeight: 500, letterSpacing: 0 }}
          fontSize={{ xs: 24, md: 34 }}
        // className={Classes.heading1}
        >
          How Can We Help?
        </Typography>
        <Grid
          container
          // rowSpacing={1}
          columnSpacing={6} rowSpacing={4}
          // spacing={5}
          mt={5}
          justifyContent={{ xs: 'center', sm: 'center', lg: "space-between" }}
          sx={{ p: { xs: "0px", sm: "10px 0px", md: "10px 0px" }, display: { xs: 'flex' } }}
        >
          {CardsData?.map((item, index) => (
            <Grid item xs={item?.title === "Patient Portal" ? 12 : 6} sm={6} md={3} lg={2.4} sx={{ p: "5px" }} key={index}>
              <>
                <Card
                  variant="outlined"
                  sx={{
                    borderRadius: "10px",
                    cursor: 'pointer',
                    width: { xs: "100%", md: '100%' },
                    height: { xs: "100%", md: '100%' },
                  }}
                  className={Classes.card}
                  onClick={() => handleSelectOpen(item?.title, item, urgentCarePopup)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  <CardContent
                    sx={{
                      "&.MuiCardContent-root": {
                        p: "10px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: {
                          xs: item?.title === "Patient Portal" ? 'row' : 'column', sm: 'column', md: 'column', lg: 'column'
                        },
                        justifyContent: {
                          xs: item?.title === "Patient Portal" ? 'start' : 'space-between', lg: 'space-between'
                        },
                        // alignContent: "center",

                        alignItems: "center",

                        gap: 1,
                      }
                      }
                    >
                      <div className="Classes.iconsize flex justify-center items-center" style={{ width: '60px', height: '60px' }}>
                        <img
                          src={index === hoveredCard ? item.imgSrchover : item.imgSrc}
                          alt="hovercard"
                          // width={35}
                          // height={35}
                          style={{
                            // marginRight: "8px",
                            Height:
                              item.title === "Emergency"
                                ? "60px"
                                : "50px",
                            width:
                              item.title === "Emergency"
                                ? "130%" : (item.title === "Urgent Care" || item.title === "Telemedicine" || (item.title === "Patient Portal" && item.imgSrchover == PortalImg_wt)) ? '45px' : "50px",
                            maxWidth: item.title === "Emergency" && "130%"
                          }}
                        />
                      </div>
                      <Box
                        sx={{
                          height: { sm: "70px", md: "60px" },
                          justifyContent: 'center',
                          textAlign: 'center',
                          mb: {
                            xs: item?.title === "Patient Portal" ? '10px' : '0px', lg: '0px'
                          }
                        }}
                        pt={item.title === "Emergency" ? 2 : 2}
                      >
                        <Typography className={Classes.textcolor}>
                          {item.title}
                        </Typography>
                        <Typography
                          sx={{ fontSize: { xs: '12px', lg: "14px" }, color: index === hoveredCard ? '#fff' : '#898989' }}
                        >
                          {item?.title === "Patient Portal" ? (usertoken ? "Digital Health Logout" : item.description) : item.description}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </>
              {
                item.title === "Emergency" &&
                <EmergencyPopup open={emergencyOpen} handleClose={handleCloseDialog} />
              }
              {
                item.title === "Urgent Care" &&
                <UrgentCarePopup open={urgentCareOpen} handleClose={handleUrgentCloseDialog} setIsUrgentCareOpen={setUrgentCareOpen} setUrgentCarePopup={setUrgentCarePopup} id="1" />
              }
              {
                item.title === "Telemedicine" &&
                <TelemedicinePopup open={telemedicineOpen} handleClose={handleTeleCloseDialog} setTelemedicineOpen={setTelemedicineOpen} />
              }
            </Grid>
          ))}
        </Grid>

      </Box >

    </>

  );
};

export default HelpComponent;
