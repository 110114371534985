import React from 'react'

function SimilarServices() {
    return (
        <div>
            <div className='font-semibold text-lg '>Similar Services</div>
            <div className='pt-3 text[16px] pl-6'>No Data Available</div>
        </div>)
}

export default SimilarServices