import { Box, Divider, Typography } from "@mui/material";
import React, { useState } from "react";

const FeedVideo = ({ docFeed }) => {
    const videoUrl = docFeed.sourceLink.replace(/,\s*$/, "");
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        // setExpanded(true);
    };
    return (
        <Box>

            <div>
                <Typography
                    component={"p"}
                    sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: expanded ? "none" : 2, // Shows 3 lines when collapsed
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: '14px',
                        lineHeight: 1.8,
                        wordBreak: 'break-word', // Prevents horizontal overflow by breaking long words
                        mt: 1
                    }}
                >
                    {docFeed?.description}
                    {/* ihehufeefhdhfjdssjfdssfdsjfdjsfrjirefiewidefsjssfdjsfdsfdrltreiherhewlrewhrewwlhereegjnfdsjnkfdjnfdjncvmn,vcm,cvmnvcnmvcmnfjkfsdkjfdjkvfnxn,fdm,fgj,fdgnjdfnfdfjdsjf.ksdjkshfbhfjerweoidjwischdbjcvbdfjghrejdkfcndkjxmfncnreukdjfnc sdjxmfncnkdsjxfncnneruidjkxfncmsdxjscnndkxjkhfckjdxmdfncdiukxjfnckcdsuxkjfbedsfhcjnemdsfkejd
                    ihehufeefhdhfjdssjfdssfdsjfdjsfrjirefiewidefsjssfdjsfdsfdrltreiherhewlrewhrewwlhereegjnfdsjnkfdjnfdjncvmn,vcm,cvmnvcnmvcmnfjkfsdkjfdjkvfnxn,fdm,fgj,fdgnjdfnfdfjdsjf.ksdjkshfbhfjerweoidjwischdbjcvbdfjghrejdkfcndkjxmfncnreukdjfnc sdjxmfncnkdsjxfncnneruidjkxfncmsdxjscnndkxjkhfckjdxmdfncdiukxjfnckcdsuxkjfbedsfhcjnemdsfkejd */}
                </Typography>
                {docFeed?.description?.length > 80 ? (

                    <Typography
                        component={"span"}
                        onClick={() => setExpanded(!expanded)}
                        sx={{ cursor: "pointer", fontSize: 14, color: "blue" }}
                    >
                        {expanded ? "Read Less" : "Read More"}
                    </Typography>
                ) : (
                    ""
                )}
                <Divider sx={{ m: "10px 0px" }} />


            </div>

        </Box>
    );
};

export default FeedVideo;
