import diagnosticIcon from '../../assets/records-module/diagnostic.svg'
import dischargeIcon from '../../assets/records-module/discharge.svg'
import consultationIcon from '../../assets/records-module/consultation.svg'
import immunisationIcon from '../../assets/records-module/immunisation.svg'
import WellnessRecordIcon from '../../assets/records-module/lifestyle.svg'
import documentIcon from '../../assets/records-module/file-text.svg'
import prescriptionIcon from '../../assets/records-module/prescription.svg'

export const StatusIcon = ({ type }) => {
    // console.log(type, "typeeeeee");
    switch (type) {
        case "DiagnosticReport":
            return <img src={diagnosticIcon} alt="diagnostic" width={25} height={25} />;
        case "DischargeSummary":
            return <img src={dischargeIcon} alt="discharge" width={25} height={25} />;
        case "OPConsultation":
            return <img src={consultationIcon} alt="consult" width={25} height={25} />;
        case "ImmunizationRecord":
            return <img src={immunisationIcon} alt="record" width={25} height={25} />;
        case "WellnessRecord":
            return <img src={WellnessRecordIcon} alt="wellness" width={25} height={25} />;
        case "HealthDocumentRecord":
            return <img src={documentIcon} alt="document" width={25} height={25} />;
        case "Prescription":
            return <img src={prescriptionIcon} alt="prescription" width={25} height={25} />;
        default:
            return null;  // Return null or some default icon if none of the cases match
    }
}
