
import React, { useState, useEffect } from "react";
import { Typography, Popover, Box, Grid, CircularProgress } from "@mui/material";
import { MdOutlineEditLocation, MdOutlineKeyboardArrowDown, } from "react-icons/md";
import styled from "@emotion/styled";
// import Styles from "./styles.module.css"
import "./headerstyles.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { branchInfo } from "../../redux/slices/branchSlice";
import { locationJson } from "../../shared/constants";
import Logo from '../../assets/hospitalimage.jpg'
import { selectedBranchInfo } from "../../redux/slices/SelectedBranchSlice";
import { ArrowForward, Close, ExpandLess, ExpandMore, LocationOn, LocationOnOutlined, MyLocationOutlined } from "@mui/icons-material";
import { localstore } from "../localstore/localstore";
import { useNavigate } from "react-router-dom";
import NavChooseBranches from "./navChooseBranches";
import ContainedButton, { OutlinedButton } from "../atoms/commonbutton";
import { FiHome } from "react-icons/fi";
import axios from "axios";
import URL from "../../services/api_url";

const Overlay = styled('div')({
  position: 'fixed',
  top: 81,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.68)', // Adjust the color and opacity as needed
  zIndex: 10, // Ensure it's above other elements
});


// const SearchCard = styled(Card)({
//   // width: "230px",
//   boxShadow: "none",
//   // backgroundColor: "#fff",
//   height: "auto",
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   paddingTop: "10px",
//   padding: "10px 7px",
//   cursor: "pointer",
//   [`:hover`]: {
//     // backgroundColor:'#F0D3FF',
//     backgroundColor: "#FAF1FF",
//     // opacity: 0.1,
//     zIndex: -99,
//   },
// });

const HospitalLocation = ({ sticky }) => {
  const [selectedLocationName, setSelectedLocationName] = useState(null);
  const [selectBranchOpen, setSelectBranchOpen] = useState(false)
  const [subMenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false)

  const [wid, setWid] = useState(null);
  const branchName = useSelector((state) => state.branch.branch);
  const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
  const branchList = useSelector((state) => state.login.branchLists);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [ourServicePage, setOurServicePage] = useState(false);
  const submenuPopoverOpen = Boolean(subMenuAnchorEl);
  const submenuPopoverId = submenuPopoverOpen ? "submenu" : undefined;


  const handleOnNearestLocation = async () => {
    setLoading(true); // Start loading

    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const { latitude, longitude } = position.coords;
      const response = await axios.get(`${URL.address}distance?coordinates=${latitude},${longitude}`);
      const location = response.data.branch;
      const matchedBranch = locationJson.find(branch => branch.name === location);
      if (matchedBranch) {
        localStorage.setItem("brName", matchedBranch.name);
        localStorage.setItem("selectedbranch", JSON.stringify(matchedBranch));
        dispatch(branchInfo(matchedBranch.name));
        dispatch(selectedBranchInfo(matchedBranch));
        handleClose();
        handleBranchClose()
        navigate('/');

      } else {
        console.error('No matching branch found');
      }
      // navigate('/'); // Uncomment this line if you want to navigate after fetching the location
    } catch (error) {
      console.error('Error fetching geolocation data:', error);

    } finally {
      setLoading(false); // End loading
    }
  };


  const handleUpdateLocation = (location, event) => {
    setSelectedLocationName(location);
    handleClose();
  };


  const handleSelectLocation = (location, index) => {
    const sanitizedLocation = {
      ...location,
      name: location?.name?.replace(/[\s,]+/g, '').toLowerCase(),
    };
    setSelectedLocationName(sanitizedLocation?.name);
    localStorage.setItem("selectOrg", location?.name); // Store the selected index in localStorage
    localStorage.setItem("brName", sanitizedLocation?.name);
    dispatch(branchInfo(sanitizedLocation?.name));
    localStorage.setItem("selectedbranch", JSON.stringify(location));
    dispatch(selectedBranchInfo(location));
    handleBranchClose();
    navigate('/');
  };
  const handleOpen = (event) => {
    // if (!usertoken) {
    setSubmenuAnchorEl(event.currentTarget);
    setOurServicePage(true);
    // } else {
    //   alert('Please logout and Change hospital branch')
    // }
  };

  const handleClose = () => {
    setSubmenuAnchorEl(null);
    setOurServicePage(false);
  };
  const handleCloseBranch = () => {
    setSelectBranchOpen(false)
    // setSelectBranchAnchorEl(null)
    dispatch({
      type: 'SELECT_FACILITY_BRANCH',
      payload: false
    })
  }
  const branch = localStorage.getItem("selectOrg");

  // useEffect(() => {
  //   const storedIndex = localStorage.getItem("selectedIndex");
  //   if (storedIndex !== null) {
  //     setSelectedIndex(parseInt(storedIndex, 10));
  //   }
  // }, []);

  const isXsScreen = useMediaQuery("(max-width:899px)");

  // useEffect(() => {
  //   const matchBranch = locationJson.filter(item => item.name === branchLocation)
  //   console.log(matchBranch, "machaaaaaaaa");
  // }, [branchLocation])
  useEffect(() => {
    let width = window.innerWidth;
    setWid(width);
  }, [wid]);

  React.useEffect(() => {
    setSelectedLocationName(branchName);
  }, [branchName]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickBranch = (event) => {
    // if (!usertoken) {
    setAnchorEl(event.currentTarget);
    // } else {
    //   alert('Please logout and Change hospital branch')
    // }

  };

  const handleBranchClose = () => {
    setAnchorEl(null);
  };

  // console.log(selectBranchInfo, "selectBranchInfo", sticky)

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const options = ['option 1', 'otpion 2', 'option 3']
  return (
    <>
      {isXsScreen ?

        <>
          {/* <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}> */}
          <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
            <Typography
              sx={{
                // flexGrow: 1,
                color: '#FFFFFF',
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
                borderBottom: anchorEl ? "none" : sticky ? 'none' : "2px solid #E44190",
                lineHeight: '31px',
                // fontSize: sticky ? "21px" : '18px',
              }}
              onClick={handleClickBranch}
            >
              <div className="flex items-center gap-1  text-[#E44190] ">
                {anchorEl ? null : sticky ? null : <LocationOn />}
                <div className="capitalize text-base md:text-base">
                  {/* {sticky
                    ? selectBranchInfo?.address?.line?.[1]
                      ? selectBranchInfo.address.line[1].length > 13
                        ? `${selectBranchInfo.address.line[1].slice(0, 13)}...`
                        : selectBranchInfo.address.line[1]
                      : selectBranchInfo?.name
                        ? selectBranchInfo.name.length > 13
                          ? `${selectBranchInfo.name.slice(0, 13)}...`
                          : selectBranchInfo.name
                        : "Choose Branch"
                    : selectBranchInfo?.address?.line?.[1]
                      ? selectBranchInfo.address.line[1].length > 18
                        ? `${selectBranchInfo.address.line[1].slice(0, 18)}...`
                        : selectBranchInfo.address.line[1]
                      : selectBranchInfo?.name
                        ? selectBranchInfo.name.length > 18
                          ? `${selectBranchInfo.name.slice(0, 18)}...`
                          : selectBranchInfo.name
                        : "Choose Branch"
                  } */}

                  {/* new code  */}

                  {sticky && selectBranchInfo?.name && process.env.REACT_APP_CURRENT_ENV_FILE === 'prod' ? selectBranchInfo?.name?.replace(/,/g, '')?.split(' ')[2] :
                    sticky && selectBranchInfo?.name && process.env.REACT_APP_CURRENT_ENV_FILE !== 'prod' ?
                      `${selectBranchInfo.name?.slice(0, 18)}...` :
                      !sticky && selectBranchInfo?.name && process.env.REACT_APP_CURRENT_ENV_FILE === 'prod' ? selectBranchInfo?.name?.replace(/,/g, '')?.split(' ')[2] :
                        !sticky && selectBranchInfo?.name && process.env.REACT_APP_CURRENT_ENV_FILE !== 'prod' ?
                          `${selectBranchInfo.name?.slice(0, 18)}...` : 'Choose Branch'}

                </div>
              </div>
              <div className="text-[#E44190]"> {anchorEl ? < ExpandLess /> : <ExpandMore />}</div>
            </Typography>
          </Box>


          {/* </Toolbar> */}
        </> :
        < div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 30,
          }}
        // className="group px-2"
        // className={matches ? { styles.mobileview } : laptopview}
        >
          <Box
            sx={{
              display: "flex",
              gap: 5,
              alignItems: "center",
              justifyContent: 'space-between',
              border: '1px solid #E44190',
              padding: '0px 5px',
              borderRadius: '12px',
              maxWidth: "100%",
              height: { xs: "40px", lg: "45px" },
              cursor: 'pointer'
            }}
            // className="group-hover:cursor-pointer"
            onClick={handleOpen}
          // aria-describedby="location-popover"
          >
            <div className="flex items-center">
              <MdOutlineEditLocation fontSize={"24px"} color={"#E44190"} />
              <Typography
                sx={{
                  fontSize: { md: 14, lg: 16 },
                  color: "#E44190",
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  p: '5px',
                  textTransform: 'capitalize'
                }}
              >
                {/* {sticky
                  ? selectBranchInfo?.address?.line?.[1]
                    ? selectBranchInfo.address.line[1].length > 12
                      ? `${selectBranchInfo.address.line[1].slice(0, 12)}...`
                      : selectBranchInfo.address.line[1]
                    : selectBranchInfo?.name
                      ? selectBranchInfo.name.length > 12
                        ? `${selectBranchInfo.name.slice(0, 12)}...`
                        : selectBranchInfo.name
                      : "Choose Branch"
                  : selectBranchInfo?.address?.line?.[1]
                    ? selectBranchInfo.address.line[1].length > 14
                      ? `${selectBranchInfo.address.line[1].slice(0, 14)}...`
                      : selectBranchInfo.address.line[1]
                    : selectBranchInfo?.name
                      ? selectBranchInfo.name.length > 14
                        ? `${selectBranchInfo.name.slice(0, 14)}...`
                        : selectBranchInfo.name
                      : "Choose Branch"
                } */}

                {/* new code */}
                {sticky && selectBranchInfo?.name && process.env.REACT_APP_CURRENT_ENV_FILE === 'prod' ? selectBranchInfo?.name?.replace(/,/g, '')?.split(' ')[2] :
                  sticky && selectBranchInfo?.name && process.env.REACT_APP_CURRENT_ENV_FILE !== 'prod' ?
                    `${selectBranchInfo.name?.slice(0, 18)}...` :
                    !sticky && selectBranchInfo?.name && process.env.REACT_APP_CURRENT_ENV_FILE === 'prod' ? selectBranchInfo?.name?.replace(/,/g, '')?.split(' ')[2] :
                      !sticky && selectBranchInfo?.name && process.env.REACT_APP_CURRENT_ENV_FILE !== 'prod' ?
                        `${selectBranchInfo.name?.slice(0, 18)}...` : 'Choose Branch'}

              </Typography>

              {/* {selectBranchInfo?.address?.line?.[1] || selectBranchInfo?.name || "Choose Branch"} */}
            </div>
            <MdOutlineKeyboardArrowDown fontSize={"24px"} color={"#E44190"} />
          </Box>
        </div >
      }


      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleBranchClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ top: sticky ? '30px' : '59px' }}
        className="mobilebranchsubmenu"
      >
        <div className="px-4 pt-2">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div>Select Location</div>
              {/* <CustomAutoComplete
                icon={<LocationOnOutlined sx={{ color: "#E44190" }} />}
                variant="standard"
                options={options}
                placeholder={"Select Location"}
              /> */}
              <div className="flex  items-center gap-2" style={{ borderBottom: '2px solid #E44190' }}>
                <LocationOnOutlined sx={{ color: "#E44190" }} />
                <div className="text-sm md:text-base">Hyderabad,Telangana,India</div>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <ContainedButton bgcolor="#E44190" borderRadius="7px" text="Home" startIcon={<FiHome />} onClick={(event) => {
                localStorage.removeItem("selectOrg");
                localStorage.removeItem("brName");
                localStorage.removeItem("selectedbranch");
                dispatch(branchInfo(''));
                dispatch(selectedBranchInfo({}));
                navigate('/');

              }} />
            </Grid>
            <Grid item xs={6} md={3}>
              <OutlinedButton
                text="Nearest to Me"
                borderColor="#707070"
                color="black"
                borderRadius="7px"
                fontSize={{ xs: 14, sm: 16, md: 16 }}
                height="40px"
                startIcon={loading ? <CircularProgress size={20} /> : <MyLocationOutlined />}
                onClick={handleOnNearestLocation}
              />
            </Grid>
          </Grid>
          <div className="mt-1">
            <Box sx={{
              overflowY: 'scroll', height: '100vh', '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}>
              {branchList?.length > 0 ? branchList.map((location, newindex) => (
                <div key={newindex} style={{
                  border: '1px solid #707070', borderRadius: '8px', padding: '6px', marginTop: '15px', backgroundColor: branch === location?.name ? '#fce4ec' : 'transparent',
                }}
                  onClick={() => handleSelectLocation(location, newindex)}>
                  <Grid container alignItems={'center'} >
                    <Grid item xs={2} sm={2}>
                      <div
                        style={{
                          width: "44px",
                          height: "44px",
                          border: "1px solid #fff",
                          borderRadius: "6px",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",

                        }}
                      >
                        <img
                          src={location.orgImg || Logo}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "top" // Ensures the top part of the image is centered, cutting off the bottom
                          }}
                          alt="hospital"
                        />

                      </div>
                    </Grid>
                    <Grid item xs={8} sm={8}>
                      <Typography variant="subtitle1" sx={{
                        fontWeight: 500, fontSize: '13px', textTransform: 'capitalize',
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxHeight: '40px',  // Adjust based on desired line height and number of lines
                        lineHeight: '20px'
                      }}>
                        {location.name}
                      </Typography>
                      <Typography
                        sx={{
                          // fontSize: { xs: 12, sm: 13, md: 13, lg: 14 },
                          // color: '#707070',
                          // display: 'block',
                          // overflow: 'hidden',
                          // textOverflow: 'ellipsis',
                          // whiteSpace: 'nowrap',
                          // maxHeight: '40px',  // Adjust based on desired line height and number of lines
                          // lineHeight: '20px'
                          fontSize: { xs: 12, sm: 13, md: 13, lg: 14 },
                          color: '#707070',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          lineHeight: '1.2em', // Adjust according to your font size
                          height: '2.4em',
                        }}
                      >
                        {location?.address?.[0]?.line?.[0] || location?.address?.district || location?.address?.state || location?.address?.postalCode
                          ? [location?.address?.[0]?.line?.[0], location?.address?.district, location?.address?.state, location?.address?.postalCode]
                            .filter(Boolean)
                            .join(', ')
                          : 'Address not available'}
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <ArrowForward sx={{ fontSize: "35px", color: '#0007b7', marginLeft: '12px' }} />

                    </Grid>
                  </Grid>

                </div>
              )) : <Grid item textAlign={'center'} mt={8} >
                <Typography variant="h6">
                  No Data Available
                </Typography>
              </Grid>}
              <div className="text-center mt-2">
                <ContainedButton
                  text="Close"
                  bgcolor="#E44190"
                  borderRadius="20px"
                  fontSize={{ xs: 14, sm: 16, md: 16 }}
                  startIcon={<Close />}
                  onClick={handleBranchClose}
                />
              </div>
            </Box>
          </div>
        </div >
      </Popover >

      {ourServicePage ? <Overlay onClick={handleClose} /> : null}

      <Popover
        id={submenuPopoverId}
        open={ourServicePage}
        anchorEl={subMenuAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={0}
        className="branchsubmenu"
        sx={{
          marginTop: sticky ? {
            xs: "32px", md: "70px"
          } : { xs: "31px", md: '70px' },
          // height: '100vh'
          // zIndex: 10003,
          '.MuiPopover-paper': {
            overflowY: 'scroll', height: '100%', '&::-webkit-scrollbar': {
              display: 'none',
            }
          },
          // mb: 5
        }}
      >
        <NavChooseBranches handleClose={handleClose} selectedbranch={selectBranchInfo} handleOnNearestLocation={handleOnNearestLocation} loading={loading} branchList={branchList} branch={branch} />
      </Popover >
    </>
  );
};

export default HospitalLocation;