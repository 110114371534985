import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import DiagnosticIcon from '../../assets/healthportal-icons/diagnostic-reports.svg'


function DiagnosticReports() {
    const isXsScreen = useMediaQuery("(max-width:900px)");
    const medicalrecords = ['Prescription', 'Lab Reports', 'Clinical Notes']
    return (
        <Box>
            {!isXsScreen &&
                <Typography color={'#155775'} className='font-medium text-sm fntwt-600 flex align-item' gap={"10px"}><img src={DiagnosticIcon} alt="medical" /> Diagnostic Reports</Typography>
            }

            <div className='p-10 text-center'>
                Coming Soon
            </div>
        </Box>)
}

export default DiagnosticReports