import React from 'react'
import Header from '../header/header'
import { useDispatch } from 'react-redux';
import { branchInfo } from '../../redux/slices/branchSlice';
import TestimonialPage from './testimonial/testimonial';
import HelpComponent from './Help';
import HealthServices from './specialists/HealthServices';
import AboutComp from './About';
import HealthComp from './healhinfor/HealthCom';
import { selectedBranchInfo } from '../../redux/slices/SelectedBranchSlice';
import patienteducationimg from "../../assets/images/patient Education.svg";
import FAQimg from "../../assets/images/FAQ'S.svg";
import { Box } from '@mui/material';


const DocData = [
  {
    title: "Patient Education",
    description: "Article & resource",
    imgSrc: patienteducationimg,
  },
  {
    title: "Life Health Events",
    description: "Ask doctors,view interview podocast",
    imgSrc: patienteducationimg,
  },
  {
    title: "FAQ'S",
    description: "Find Answers for common Questions ",
    imgSrc: FAQimg,
  },
];

const Home = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    let locationInfo = localStorage.getItem("brName");
    let selectedbranchInfo = JSON.parse(localStorage.getItem("selectedbranch"));
    if (locationInfo) {
      dispatch(branchInfo(locationInfo))
      dispatch(selectedBranchInfo(selectedbranchInfo))
    }

  }, []);



  return (
    <div>
      <Header />
      {/* <Box sx={{ padding: { xs: '0px 20px', md: '0px 90px', lg: '0px 100px', xl: '0px 100px' } }}> */}
      {/* <HelpComponent /> */}
      {/* <HealthServices />
        <HealthComp /> */}
      {/* <HealthCompFeed /> */}
      {/* <Box sx={{ p: { xs: "10px", sm: "0px" } }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{
              p: {
                xs: "0px",
                sm: "10px 10px 10px 25px",
                md: "10px 65px 10px 90px",
              },
            }}
          >
            {DocData.map((val, index) => (
              <Grid item xs={12} sm={4} md={4} sx={{ p: "10px" }} key={index}>
                <Card variant="outlined" sx={{ borderRadius: "10px" }}>
                  <CardContent
                    sx={{
                      "&.MuiCardContent-root": {
                        p: "10px",
                      },
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ mr: "8px" }}>
                        <img
                          src={val.imgSrc}
                          alt=""
                          width={50}
                          height={50}
                          style={{
                            marginRight: "8px",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </Box>
                      <Typography
                        level="h1"
                        sx={{ fontWeight: "bold" }}
                        className={classes.heading}
                      >
                        {val.title}
                      </Typography>
                    </div>
                    <div style={{ marginLeft: "30px" }}>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "12px" }}
                        className={classes.subheading}
                      >
                        {val.description}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box> */}
      {/* <TestimonialPage />
        <AboutComp /> */}
      {/* </Box> */}
    </div>
  )
}

export default Home;