import React, { useState } from "react";
import { Card, CardMedia, Grid, Link, Stack, Typography } from "@mui/material";
// import Image from "next/image";
import YouTubeIcon from '../../assets/images/Icon fa-brands-youtube.svg'
import InstagramIcon from '../../assets/images/Icon corebrands-instagram.svg'
import TwiterIcon from '../../assets/images/Icon fa-brands-x-twitter.svg'
import LinkedInIcon from '../../assets/images/Icon corebrands-linkedin.svg'
import FacebookIcon from '../../assets/images/Icon fa-brands-square-facebook.svg'
import logo from '../../assets/parmitalogo.png'
import maathrulogo from '../../assets/images/maathru-new.svg'
import paramithaIdol from '../../assets/home-images/Paramitha logo-Light.svg'
import paramithaText from '../../assets/home-images/Paramitha Hospitsals-Title.svg'
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { FaXTwitter } from "react-icons/fa6";
import UrgentCarePopup from "../branchhome/UrgentcarePopup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate()
  const [urgentCareOpen, setUrgentCareOpen] = useState(false)
  const branchName = useSelector((state) => state.branch.branch)

  const handleCloseUrgentCare = () => {
    setUrgentCareOpen(false)
  }

  const handleClickAppointment = () => {
    navigate(branchName ? `/${branchName}/doctors` : '/doctors')
  }
  return (

    <div>
      <Grid
        container
        marginTop={"30px"}
        sx={{
          border: "1px solid #A5BCC8",
          background: '#FDF3F7 !important',
          //   height: "300px",
          // height: { xs: "930px", sm: "930px", md: "300px" },
          padding: { xs: '20px 20px', sm: '20px 40px', md: '20px 60px' },
          opacity: 1,
          minWidth: "370px"
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          // sx={{ p: "10px 30px" }}
          justifyContent={"center"}
        >
          <div className="flex justify-center column py-3 px-4 gap-1 items-center" style={{ flexDirection: 'column' }}>
            <div className="pt-2 flex gap-2">
              {/* <img
                src={logo}
                alt="paramitha logo"
                width={150}
                height={60}
              /> */}

              <img src={paramithaIdol} alt="idol" style={{ height: 60 }} />
              <img src={paramithaText} alt="paramithatext" style={{ height: 60 }} />
            </div>
            <div className="pt-8">
              <Typography sx={{ mb: 1, textAlign: 'center', fontSize: 14, color: '#E44190' }}>Copyright 2024 Paramitha Women & Childern&apos;s Hospitals </Typography>
              <hr style={{ color: '#fff' }} className="py-2" />
              <div className="flex justify-between">
                <FaXTwitter style={{ height: 24, width: 24, color: '#E44190' }} />
                <FaInstagram style={{ height: 24, width: 24, color: '#E44190' }} />
                <IoLogoYoutube style={{ height: 24, width: 24, color: '#E44190' }} />
                <FaFacebookSquare style={{ height: 24, width: 24, color: '#E44190' }} />
                <FaLinkedin style={{ height: 24, width: 24, color: '#E44190' }} />


                {/* <img
                  src={TwiterIcon}
                  alt="twitter"
                  width={20}
                  height={20}
                  style={{ marginRight: "8px", height: "20px" }}
                />
                <img
                  src={InstagramIcon}
                  alt="instagram"
                  width={20}
                  height={20}
                  style={{ marginRight: "8px", height: "20px" }}
                />
                <img
                  src={YouTubeIcon}
                  alt="youtube"
                  width={20}
                  height={20}
                  style={{ marginRight: "8px", height: "20px" }}
                />
                <img
                  src={FacebookIcon}
                  alt="facebook"
                  width={20}
                  height={20}
                  style={{ marginRight: "8px", height: "20px" }}
                />
                <img
                  src={LinkedInIcon}
                  alt="linkedin"
                  width={20}
                  height={20}
                  style={{ marginRight: "8px", height: "20px" }}
                /> */}
              </div>
            </div>
          </div>

        </Grid >
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          sx={{ padding: "20px" }}
        >
          <Typography
            level="h1"
            sx={{
              mb: 2,
              color: "#E44190",
              lineHeight: '27px', fontWeight: 500,
              fontSize: "18px",
            }}
          >
            Important Links
          </Typography>
          <Stack spacing={1.5}>
            <Typography sx={{ mb: 0.5, color: "#E44190", cursor: 'pointer', opacity: 1 }} onClick={handleClickAppointment}>
              Schedule Appointment
            </Typography>
            <Typography sx={{ mb: 0.5, color: "#E44190", cursor: 'pointer' }} onClick={() => setUrgentCareOpen(true)}>
              Urgent Care
            </Typography>
            <Typography sx={{ mb: 0.5, color: "#E44190" }}>
              Second Opinion
            </Typography>
            <Typography sx={{ mb: 0.5, color: "#E44190" }}>
              International Patient Center
            </Typography>
            <Typography sx={{ mb: 0.5, color: "#E44190" }}>
              Terms of Use/Privacy Policy
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={3} sx={{ padding: "20px" }}>
          <Typography level="h1" sx={{ mb: 2, color: "#E44190", lineHeight: '27px', fontWeight: 500, fontSize: "18px", }}>
            Explore
          </Typography>
          <Stack spacing={1.5}>
            <Typography sx={{ mb: 0.5, color: "#E44190", opacity: 1 }}>
              Posts & Articles
            </Typography>
            <Typography sx={{ mb: 0.5, color: "#E44190" }}>
              Patient Education
            </Typography>
            {/* <Link href="/about" underline="none"> */}
            <Typography sx={{ mb: 0.5, color: "#E44190", opacity: 1, cursor: 'pointer' }} onClick={() => navigate(branchName ? `/${branchName}/AboutUs` : '/AboutUs')}>
              About Us
            </Typography>
            {/* </Link> */}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ padding: "20px" }}>
          <Typography sx={{ mb: 2, color: "#E44190", lineHeight: '27px', fontWeight: 500, fontSize: "18px", }}>
            Contact Us
          </Typography>

          <Stack spacing={1.5}>
            <Link href="/carrer" underline="none">
              <Typography sx={{ mb: 0.5, color: "#E44190", opacity: 1 }}>
                Career With us
              </Typography>
            </Link>
            <Link href="#" underline="none" >
              <Typography sx={{ mb: 0.5, color: "#E44190" }}>
                Write a Feedback
              </Typography>
            </Link>
            <Link href="#" underline="none">
              <Typography sx={{ mb: 0.5, color: "#E44190" }}>
                Partnership Request
              </Typography>
            </Link>
          </Stack>
        </Grid>
      </Grid >

      <Grid container justifyContent={"center"} sx={{ border: "1px solid #A5BCC8", height: { sm: "40px", md: "40px" }, minWidth: "370px", opacity: 1, }}>
        <Grid item sx={{ p: "5px 0px", flexDirection: "column" }}>
          <Typography>Designed By <span style={{ color: " #0039B9" }}> Docsteth & </span></Typography>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} sx={{ p: "2px 30px", flexDirection: "column" }}>
          <Typography>Copyright 2024 Paramitha Women & Childern&apos;s Hospitals </Typography>
        </Grid> */}
        <Grid item sx={{ p: "5px 5px", flexDirection: "column" }}>
          <Typography>
            {" "}
            Powered By <span style={{ color: " #0039B9" }}>Medxperts</span>
          </Typography>
        </Grid>
      </Grid>

      <UrgentCarePopup open={urgentCareOpen} handleClose={handleCloseUrgentCare} setIsUrgentCareOpen={setUrgentCareOpen} />

    </div >
  );
};

export default Footer;
