import React, { useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { FaCircleChevronLeft, FaCircleChevronRight } from "react-icons/fa6";
import { Carousel } from "react-responsive-carousel";
import { getMaxHeight } from "../../atoms/maxHeightFn";
import { Close } from "@mui/icons-material";

const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(80% - 150px)",
    cursor: "pointer",
};

function PostMedia({ item }) {
    const [openImage, setOpenImage] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const images = item?.sourceLink
        ? item.sourceLink.split(',').slice(0, -1).map((img) => img.trim())
        : [];

    const handleImageClick = (img) => {
        console.log(img, "image")
        setSelectedImage(img);
        setOpenImage(true);
    };

    const handleClose = () => {
        setOpenImage(false);
        setSelectedImage(null);
    };

    const renderCustomLeftArrow = (onClickHandler, hasPrev, label) =>
        hasPrev && (
            <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{ ...arrowStyles, left: 15 }}
            >
                <FaCircleChevronLeft
                    style={{ fontSize: "3vw", color: "#E54290" }}
                />
            </button>
        );

    const renderCustomRightArrow = (onClickHandler, hasNext, label) =>
        hasNext && (
            <button
                type="button"
                onClick={onClickHandler}
                title={label}
                style={{ ...arrowStyles, right: 15 }}
            >
                <FaCircleChevronRight
                    style={{ fontSize: "3vw", color: "#E54290" }}
                />
            </button>
        );

    const maxHeightFn = getMaxHeight()
    return (
        <div>
            {images.length > 0 ? (
                <Box sx={{ width: "100%" }} style={{ cursor: "pointer" }}>
                    {images.length === 1 ? (
                        <img
                            src={images[0]}
                            alt="post"
                            onClick={() => handleImageClick(images[0])}
                            style={{
                                objectFit: "fill",
                                maxHeight: "430px",
                                width: "100%",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                borderRadius: "10px",
                            }}
                        />
                    ) : (
                        <div>
                            <Carousel
                                showArrows
                                showThumbs={false}
                                showStatus={false}
                                showIndicators={false}
                                renderArrowPrev={renderCustomLeftArrow}
                                renderArrowNext={renderCustomRightArrow}
                            >
                                {images.map((img, idx) => (
                                    <div key={idx} onClick={() => handleImageClick(img)}
                                    >
                                        <img
                                            src={img}
                                            alt={`slide-${idx}`}
                                            style={{
                                                objectFit: "fill",
                                                maxHeight: "430px",
                                                width: "100%",
                                                borderRadius: "10px",
                                            }}
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    )}

                    {/* Fullscreen Image Modal */}
                    <Dialog open={openImage} onClose={handleClose} maxWidth="lg" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                        {/* <DialogTitle> */}

                        {/* </DialogTitle> */}
                        <DialogContent sx={{ p: 0 }}>
                            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                <img
                                    src={selectedImage}
                                    alt="full-size"
                                    style={{
                                        objectFit: 'contain', // Ensure the image is contained within the container
                                        height: '100%',
                                        width: '100%',
                                        maxHeight: maxHeightFn,
                                        position: 'relative'
                                    }}
                                />
                                <div style={{
                                    position: 'absolute',
                                    top: '10px', // Adjust as needed
                                    // left: '0px', // Adjust as needed
                                    right: '10px',
                                    zIndex: 1
                                }}
                                    onClick={handleClose}
                                >
                                    <IconButton>
                                        <Close sx={{ color: '#E44190' }} />
                                    </IconButton>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>

                </Box>
            ) : (
                <Box
                    sx={{ height: "430px", display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                    No Image Available
                </Box>
            )}
        </div>
    );
}

export default PostMedia;
