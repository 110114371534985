import React, { useState } from 'react'
import { Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material'
import RequestIcon from '../../assets/images/referral.svg'
import ContainedButton from '../atoms/commonbutton';
import { FaXTwitter } from 'react-icons/fa6';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { IoLogoYoutube } from 'react-icons/io';
import { useSelector } from 'react-redux';
import healthServiceIcon from '../../assets/healthcareprograms/Healthcare Service 1.svg';
import moment from 'moment';
import HealthCareRequestPopup from '../doctors/healthcarePopup';


function HealthServiceContacts({ ContactInfo, deptData, deptImg, timings, setTimings, WorkingDays }) {
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);
    const [open, setOpen] = useState(false)
    const [activeDay, setActiveDay] = useState(null);
    return (
        <div>
            <div className='py-3'>
                <Grid container alignItems={'center'}>
                    <Grid xs={12} md={8.5} lg={9.5} xl={10} p={"10px 0px"}>
                        <div className='text-base md:text-lg font-medium'>Availability</div>
                        <div style={{ display: "flex", gap: "5px", margin: "12px 0px", flexWrap: 'wrap' }}>
                            {WorkingDays?.length > 0 ? WorkingDays?.map((item, i) => {
                                return (
                                    <Button
                                        key={i}
                                        variant="contained"
                                        onClick={() => {
                                            setTimings(item);
                                            setActiveDay(i);
                                        }}
                                        sx={{
                                            minWidth: "50px",
                                            minHeight: '30px',
                                            border: '1px solid #E54290',
                                            background:
                                                activeDay === i && activeDay !== null
                                                    ? "#480223"
                                                    : item.today && activeDay === null
                                                        ? "#480223"
                                                        : !item.allDay && !item.hasOwnProperty("workingHours")
                                                            ? "#fff"
                                                            : "#fff",
                                            padding: "0px 7px",
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: activeDay === i && activeDay !== null
                                                    ? "#fff"
                                                    : item.today && activeDay === null
                                                        ? "#fff"
                                                        : !item.allDay && !item.hasOwnProperty("workingHours")
                                                            ? "#E54290"
                                                            : "#E54290",
                                                textTransform: "capitalize",
                                            }}
                                        // style={customStyles}
                                        >
                                            {item.day}
                                        </Typography>
                                    </Button>
                                );
                            }) : <div className='text-sm md:text-base'>No Slots Available</div>}
                        </div>

                        {timings && timings?.allDay ? (
                            <Typography sx={{ textTransform: 'capitalize' }}>
                                {timings.day} : 24 Hours - All Day
                            </Typography>
                        ) : timings?.allDay == false ? (
                            <Typography variant="body2" className="timing">
                                No Slot avalable
                            </Typography>
                        ) : (
                            timings?.workingHours &&
                            timings?.workingHours?.map((item, ind) => {
                                return (
                                    <Typography
                                        key={ind}
                                        mb={2}
                                        sx={{ marginBottom: "5px !important" }}
                                        variant="body2"
                                        className="timing"
                                    >
                                        {moment(item?.openingTime, "hh:mm").format("HH:mm A")}
                                        &nbsp;&nbsp;-&nbsp;&nbsp;
                                        {moment(item?.closingTime, "hh:mm").format("HH:mm A")}
                                    </Typography>
                                );
                            })
                        )}

                    </Grid>
                    {/* {deptData.appointmentRequired &&

                        <Grid item xs={12} md={3.5} lg={2.5} xl={2}>
                            <ContainedButton
                                variant="outlined"
                                onClick={() => setOpen(true)}
                                startIcon={<img src={RequestIcon} width={'18px'} />}
                                text="Request Appointment"
                                // height="30px"
                                width="100%"
                                fontSize={{ xs: "14px", sm: "14px", md: "14px", lg: "14px", }}
                                borderRadius="10px"
                                bgcolor="#E44190"
                                color="#205072"

                            />
                        </Grid>
                    } */}
                </Grid>
            </div>

            <Divider />
            <div className='py-2'>
                <Grid container alignItems={'center'}>

                    <Grid item xs={8} md={9} lg={9.5} xl={10}>
                        <div className='text-base md:text-lg font-medium'>Contact Information</div>

                        <div className='flex gap-3  items-center mt-1' >
                            {/* <BiSolidPhone style={{ border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} /> */}
                            <FiPhoneCall />
                            <div className='text-sm md:text-base'>{deptData?.telecom?.[0]?.value || 'Not Available'}</div>
                        </div>


                        <div className='flex gap-3  items-center mt-1' >
                            <AiOutlineMail />
                            {/* <CiMail style={{ border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} /> */}
                            <div className='text-sm md:text-base'>
                                {deptData?.telecom?.find(item => item?.system === 'email')?.value || 'Not Available'}
                            </div>
                        </div>
                        {/* <div className='flex gap-3  items-center' >
                            <CiLocationOn style={{ border: '1px solid #205072', padding: '1px', fontSize: '16px', borderRadius: '50%' }} />
                        <div>{deptData?.telecom?.[1]?.value}</div>
                        </div> */}



                    </Grid>

                    <Grid item xs={4} md={3} lg={2.5} xl={2}>
                        <Stack direction="row" spacing={2}>
                            <FaXTwitter style={{ height: 34, width: 34, color: '#E44190' }} />
                            <FaInstagram style={{ height: 34, width: 34, color: '#E44190' }} />
                            <IoLogoYoutube style={{ height: 34, width: 34, color: '#E44190' }} />
                            <FaLinkedin style={{ height: 34, width: 34, color: '#E44190' }} />
                        </Stack>
                    </Grid>
                </Grid>

            </div>
            <HealthCareRequestPopup open={open} setOpen={setOpen} selectedbranch={selectedbranch} deptData={deptData} serviceData={deptData} deptImg={deptImg || healthServiceIcon} comment={deptData?.comment} />

        </div>
    )
}

export default HealthServiceContacts;