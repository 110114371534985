import { Select, MenuItem } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import React from 'react';

export const MenuOptions = (props) => {

    return (
        <Select
            id="demo-simple-select"
            value={props.value}
            onChange={props.onChange}
            inputProps={{ 'aria-label': 'Without label' }}
            size='small'
            fullWidth
            error={props.error}
            style={{ borderRadius: props.borderRadius }}
        >

            {props.children}
        </Select>
    )
}
function SelectOptions(props) {
    return (
        <div>
            <Select
                displayEmpty
                fullWidth
                sx={{ '.MuiSelect-select': { padding: '9px' }, borderRadius: '7px' }}
                value={props.value}
                onChange={props.onChange}
                placeholder='Select your Gender'
                renderValue={(selected) => {
                    if (!selected) {
                        return <span>{props.defaultName}</span>;
                    }
                    return selected;
                }}
                error={props.error}
                MenuProps={{ style: { zIndex: props.zIndex } }}

            >
                {props.children}
                {/* <MenuItem value={'male'}>Male</MenuItem>
                      <MenuItem value={'female'}>Female</MenuItem>
                      <MenuItem value={'other'}>Other</MenuItem> */}
            </Select>
            {/* <Select
                id="demo-simple-select"
                value={props.value}
                onChange={props.onChange}
                inputProps={{ 'aria-label': 'Without label' }}
                size='small'
                fullWidth
                error={props.error}
                style={{ borderRadius: props.borderRadius }}


            >

                {props.children}
            </Select> */}
        </div>
    );
}

export default SelectOptions;
