import React from "react";
import { Box, Button, Card, Paper, Typography } from "@mui/material";
import Faqimg from "../../assets/images/FAQ'S.svg"
import { ContactSupportOutlined } from "@mui/icons-material";

const FrequentQuestion = () => {
  const searchedValues = [
    "About Paramitha hospitals",
    "Appointments at paramitha",
    "Bills and claims",
    "Clinical conditions FAQ's",
    "Diagnostics and Lab",
  ];

  return (
    <Box
      sx={{
        mt: "40px",
      }}
    >
      {/* <Box sx={{ display: "flex" }}>
        <img
          src={Faqimg}
          width={35}
          height={35}
          style={{ marginRight: "10px" }}
        />
        <Typography variant="h4" sx={{ fontWeight: 400 }}>
          Frequently Asked Questions
        </Typography>
      </Box>
      <Typography variant="h6" sx={{ pl: "45px" }}>
        Patient Question Answered by doctors
      </Typography> */}
      <div className="flex gap-2">
        <ContactSupportOutlined sx={{ fontSize: '30px' }} />
        <div>
          <div className="text-lg md:text-xl font-semibold">
            Frequently Asked Questions
          </div>
          <div className=" text-sm md:text-base font-normal">
            Patient Question Answered by doctors
          </div >
        </div>
      </div>

      <div className="p-10 text-center text-sm md:text-base">
        Coming Soon
      </div>
      {/* <Box sx={{ mt: "10px" }}>
        {searchedValues.map((val, index) => (
          <Button
            variant="outlined"
            key={index}
            sx={{
              color: "#000000",
              borderRadius: "20px",
              p: "5px 15px",
              m: "5px 15px",
              border: "1px solid #BEBEBE ",
              textTransform: "capitalize",
            }}
          >
            {val}
          </Button>
        ))}
      </Box>
      <Paper elevation={3} sx={{ mt: "30px", p: "10px 20px" }}>
        <Typography sx={{ fontWeight: 500 }}>
          What can cause digestion problems in 4 months old ?
        </Typography>
        <Typography sx={{ color: "#707070", fontSize: 14 }}>
          {" "}
          Question by Akhil Koli 8/30/2023
        </Typography>
        <Box sx={{ mt: "10px" }}>
          <div className="flex">
            <div className="font-medium">Dr.</div>
            <div>
              <Typography sx={{ fontWeight: 500 }}> Venkat Reddy k</Typography>
              <Typography sx={{ color: "#707070", fontSize: 12 }}>
                {" "}
                12 March 2023
              </Typography>
            </div>
          </div>
          <Typography sx={{ color: "#707070", fontSize: 14, mt: 1 }}>
            A newborn's ability to eat and digest food is essential to growth
            and development. Most babies are able to absorb nutrients and have
            normal bowel movements after being fed. Trouble in any of these
            areas can be a short-term adjustment or a sign of a more serious
            problem. These symptoms may indicate the baby is having digestive
            problems.
          </Typography>
        </Box>
      </Paper>
      <Paper elevation={3} sx={{ mt: "12px", p: "10px 20px", }}>
        <Typography sx={{ fontWeight: 500 }}> What is a Gluten-Free Diet?</Typography>
      </Paper>
      <Paper elevation={3} sx={{ mt: "12px", p: "10px 20px" }}>
        <Typography sx={{ fontWeight: 500 }}>
          {" "}
          What I must feed my New Born 5 months old ?
        </Typography>
      </Paper> */}
    </Box >
  );
};

export default FrequentQuestion;
