import React from 'react'
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material'



export function CustomTextField(props) {
    return (
        <TextField

            sx={{
                ".MuiOutlinedInput-root": {
                    paddingRight: "0px",
                },
                ".MuiInputBase-root": {
                    borderRadius: props.borderRadius,
                    backgroundColor: props.backgroundColor,
                },
                ".MuiOutlinedInput-notchedOutline": {
                    // height: "50px",
                    // border: "none"
                },
                '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                        borderColor: props.borderColor,
                    },
                },
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: props.width,
                // maxWidth: '500px',
            }}
            label={props.label}
            placeholder={props.placeholder}
            size="small"
            fullWidth
            variant="outlined"
            onChange={props.onChange}
            value={props.value}
            name={props.name}
            type={props.type}
            autoFocus={null}
            onBlur={props.onBlur}
            autoComplete={props.autoComplete}
            onKeyDown={props.onKeyDown}
            error={props.error}
            helperText={props.helperText}
            disabled={props.disabled}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {/* <IconButton
                    variant="outlined"
                    onMouseDown={props.onMouseDown}
                    edge="end"
                    onClick={props.onClick}
                    sx={{
                        // backgroundColor: "red", // Adding background color to IconButton
                        borderRadius: 5,
                        fontSize:'10px'
                        // height: '50px',
                        // width: '50px', // Adding width to IconButton for square shape
                    }}
                    
                >
                    {props.icon}
                </IconButton> */}
                        {props.icon ?
                            <IconButton onClick={props.onClick}
                                sx={{
                                    " &.MuiIconButton-root": {
                                        padding: props.padding,
                                    },
                                    pr: '10px'
                                }}>
                                {props.icon}
                            </IconButton> : <div className='pr-4 font-semibold'>{props.text}</div>
                        }
                    </InputAdornment>
                ),
            }}

        />
    )
}


