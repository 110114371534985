import React, { useEffect, useState } from 'react'
import { Box, Checkbox, Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import { Construction } from '@mui/icons-material';
import PrescriptionIcon from '../../assets/records-module/prescription.svg';
import DiagnosticIcon from '../../assets/records-module/diagnostic.svg';
import ConsultIcon from '../../assets/records-module/consultation.svg';
import DischargeIcon from '../../assets/records-module/discharge.svg';
import MedicalRecordIcon from '../../assets/healthportal-icons/journal-medical.svg'
import BranchUrl from '../../services/api_branch_url';
import axios from 'axios';
import { localstore } from '../localstore/localstore';
import { useSelector } from 'react-redux';
import { StatusIcon } from '../atoms/HiTypeIcon';
import { DateFormat } from '../atoms/dateformat';
import { Time } from '../atoms/timeformat';


const HiTypes = (props) => (

    <Paper elevation={3} sx={{
        width: { xs: '100%', sm: "100%", md: "100%" }, mt: 3, cursor: 'pointer',
    }} >

        <Box sx={{ p: '7px 20px' }}>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontSize: { xs: '10px', sm: '14px', md: '16px' } }} color="text.secondary" gutterBottom>
                    {props.title}
                </Typography>
                <Typography sx={{ fontSize: { xs: '10px', sm: '13px', md: '13px' } }} >
                    {/* {DateFormat(item?.resource?.date)} | {Time(item?.resource?.date)} */}
                    {props.date}
                </Typography>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <img src={props.icon} alt="icons" width={25} height={25} />
                    <Box>
                        <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '14px' }, fontWeight: 500 }}>
                            {props.identifier}
                        </Typography>
                        <Typography sx={{ mt: '5px', fontSize: { xs: '13px', sm: '14px', md: '14px' } }}>{props.display}</Typography>
                    </Box>
                </Box>
                <Typography sx={{ fontSize: { xs: '10px', md: '14px', sm: '14px' }, mt: 3 }} >
                    {props.hitypetext}
                </Typography>
            </Box>

        </Box>
    </Paper >
)

function MedicalRecords() {
    const isXsScreen = useMediaQuery("(max-width:900px)");
    const medicalrecords = ['Prescription', 'Lab Reports', 'Clinical Notes']
    const selectBranch = useSelector((state) => state.SelectedBranch.selectedbranch);
    const url = BranchUrl()
    const [filteredData, setFilteredData] = useState([]);  // To store the filtered data
    const patientId = localstore.getPatientIbmId()
    const usertoken = localstore.getToken()
    const params = {
        'Authorization': `Bearer ${usertoken}`,
        'X-FHIR-TENANT-ID': 'parimitha',
        'X-FHIR-DSID': `fhirparimitha`,
        'realm': 'parimitha',
    }
    const getMedicalRecords = async () => {

        try {
            const res = await axios.get(`${url.patient_resource_tenant}Encounter?subject=${patientId}&service-provider=${selectBranch?.id}&_count=10&_page=1`, {
                headers: params
            })
            if (res.status === 200) {
                const encounterIds = res?.data?.entry?.map(item => item.resource.id);
                // console.log('Encounter IDs:', encounterIds);

                // Use Promise.all to call the second API for all encounter IDs concurrently
                const encounterDetailsPromises = encounterIds.map(encounterId =>
                    axios.get(`${url.patient_resource_tenant}Bundle/${encounterId}`, {
                        headers: params
                    })
                );
                // Wait for all requests to complete
                const encounterDetailsArray = await Promise.allSettled(encounterDetailsPromises);
                // Step 3: Filter for 'Composition' resourceType
                console.log(encounterDetailsArray, "encounterDetailsArray")
                const filteredEntries = encounterDetailsArray
                    .filter(result => result.status === 'fulfilled' && result.value.data?.entry)
                    .map(result => result.value.data.entry.filter(entry => entry?.resource?.resourceType === 'Composition'))
                    .flat();  // Flatten the array (because we return an array of arrays)
                // Step 4: Store the filtered data
                setFilteredData(filteredEntries);
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getMedicalRecords()
    }, [patientId])

    return (
        <Box>
            {!isXsScreen &&
                <Typography color={'#155775'} className='font-medium text-sm fntwt-600 flex align-item' gap={"10px"}><img src={MedicalRecordIcon} alt="medical" /> Medical Records</Typography>
            }
            {/* 
            <div className='p-10 text-center'>
                Coming Soon
            </div> */}
            {/* <Box sx={{ display: 'flex', gap: 1, mt: '10px' }}>
                {medicalrecords.map((record, index) => (
                    <Box key={index} display="flex" alignItems="center">
                        <Checkbox color="primary" />
                        <Typography>{record}</Typography>
                    </Box>
                ))}

            </Box> */}

            {
                filteredData.length > 0 ? filteredData?.map((item, index) => {
                    const result = item.resource.type.text
                    return (
                        <Box sx={{ p: "0px 10px" }}>
                            {/* <HiTypes title={result} icon={DischargeIcon} date="12-Mar-2022 | 3:00 PM" identifier="Paramitha Super Speciality Childre.." display="Dr Venkat Reddy K - Neonatologist" hitypetext={result} /> */}
                            {/* <HiTypes title={result} icon={DischargeIcon} date="12-Mar-2022 | 3:00 PM" identifier="Paramitha Super Speciality Childre.." display="Dr Venkat Reddy K - Neonatologist" hitypetext="Discharge Note" />
                            <HiTypes title={result} icon={DiagnosticIcon} date="12-Mar-2022 | 3:00 PM" identifier="Paramitha Super Speciality Childre.." display="Dr Venkat Reddy K - Neonatologist" hitypetext="Lab Report" />
                            <HiTypes title={result} icon={ConsultIcon} date="12-Mar-2022 | 3:00 PM" identifier="Paramitha Super Speciality Childre.." display="Dr Venkat Reddy K - Neonatologist" hitypetext="OP Consult" />
                            <HiTypes title={result} icon={PrescriptionIcon} date="12-Mar-2022 | 3:00 PM" identifier="Paramitha Super Speciality Childre.." display="Dr Venkat Reddy K - Neonatologist" hitypetext="Imaging Report" /> */}
                            <Paper elevation={3} sx={{
                                width: { xs: '100%', sm: "100%", md: "100%" }, mt: 3, cursor: 'pointer',
                            }} >

                                <Box sx={{ p: '7px 20px' }}>
                                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography sx={{ fontSize: { xs: '10px', sm: '14px', md: '16px' } }} color="text.secondary" gutterBottom>
                                            {result}
                                        </Typography>
                                        <Typography sx={{ fontSize: { xs: '10px', sm: '13px', md: '13px' } }} >
                                            {DateFormat(item?.resource?.date)} | {Time(item?.resource?.date)}
                                            {/* {props.date} */}
                                        </Typography>
                                    </Grid>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                            {/* <img src={StatusIcon(result)} alt="icons" width={25} height={25} /> */}
                                            <StatusIcon type={result} />

                                            <Box>
                                                <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '14px' }, fontWeight: 500 }}>
                                                    {/* Paramitha Super Speciality Childrens Hospitals */}
                                                    {selectBranch?.name}
                                                </Typography>
                                                <Typography sx={{ mt: '5px', fontSize: { xs: '13px', sm: '14px', md: '14px' } }}>
                                                    {/* Dr Venkat Reddy K - Neonatologist */}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Typography sx={{ fontSize: { xs: '10px', md: '14px', sm: '14px' }, mt: 3 }} >
                                            {result}
                                        </Typography>
                                    </Box>

                                </Box>
                            </Paper >
                        </Box>
                    )
                }) :
                    <div className='p-10 text-center'>
                        Coming Soon
                    </div>
            }

        </Box>
    )
}

export default MedicalRecords
