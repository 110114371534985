import { Box, Button, Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { PostData } from "../healhinfor/PostData";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import URL from "../../../services/api_url";
import { useLocation } from "react-router-dom";

const HomeAllPosts = () => {
    // const [posts, setPosts] = React.useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation()
    const posts = location?.state?.posts
    // useEffect(() => {
    //     postAPIS();
    // }, []);

    // const postAPIS = async (key) => {
    //     console.log("hello");
    //     setLoading(true);
    //     await axios
    //         .get(
    //             `${URL.feed}feed/getpostbypageid/359?limit=25&offset=0`
    //         )
    //         .then((res) => {
    //             setPosts(res.data?.results);
    //             console.log(res.data.results, "555");
    //         })
    //         .catch((error) => console.log(error, "6666666666"))
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // };
    // console.log(posts, loading, "vvvvv");
    return (
        <Box
            sx={{
                p: {
                    xs: "5px",
                    sm: "5px 20px",
                    md: "5px 80px",
                },
                mt: 2,
            }}
        >

            <Grid container gap={2} sx={{ mt: "10px" }}>
                {/* {(console.log(posts), "vvv")} */}
                {posts?.length > 0
                    ? posts?.map((post, val) => (
                        <Grid item xs={12} sm={5.8} md={3.9} key={val}>
                            {/* <Card> */}
                            <PostData feedData={post} key={val} />
                            {/* </Card> */}
                        </Grid>
                    ))
                    : null}
                {/* {posts.map((post, val) => (
          <Grid item xs={12} sm={5.9} md={3.9}>
          
            <PostData feedData={post} key={val} />
         
          </Grid>
        ))}  */}
            </Grid>

        </Box>
    );
};

export default HomeAllPosts;
