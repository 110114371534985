import {CircularProgress} from '@mui/material';
import React from 'react';

const LoadingComponent = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <CircularProgress />
    </div>
  );
}

export default LoadingComponent;
