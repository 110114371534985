import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { IoIosCall } from "react-icons/io";

const MapComp = ({ center, zoom, locations, orgInfo }) => {
  const [map, setMap] = useState(null);
  // console.log(locations);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBoKERaSenAxv2oAp-Ma3qNVDlJIwh0UY8`;
    script.async = true;
    script.onload = initializeMap;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  //   const initializeMap = () => {
  //     const center = { lat: 17.4065, lng: 78.4772 };
  //     const newMap = new window.google.maps.Map(document.getElementById("map"), {
  //       zoom: 10,
  //       center: center,
  //     });

  //     setMap(newMap);

  //     const infowindow = new window.google.maps.InfoWindow({});

  //     locations.forEach((location) => {
  //       const marker = new window.google.maps.Marker({
  //         position: { lat: location[1], lng: location[2] },
  //         map: newMap,
  //         title: location[0],
  //       });

  //       window.google.maps.event.addListener(marker, "click", () => {
  //         infowindow.setContent(location[0]);
  //         infowindow.open(newMap, marker);
  //       });
  //     });
  //   };

  const initializeMap = () => {
    const center = { lat: 41.8781, lng: -87.6298 };
    const newMap = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 10,
      center: center,
    });

    setMap(newMap);

    const infowindow = new window.google.maps.InfoWindow({});
    const bounds = new window.google.maps.LatLngBounds(); // Create bounds object

    locations.forEach((location) => {
      const position = { lat: location[1], lng: location[2] };

  //     const content1 = `
  //     <div style="width: 200px;">
  //       <img src="https://s3.ap-south-1.amazonaws.com/docsteth-users-dev/organization/88878b22-a3fc-4b3f-92d2-5f43ebedbcb5__.jpeg" style="width: 40px; height: auto;">
  //       <p style="font-weight: bold; margin-top: 5px; text-transform:capitalize">${location[0]}</p>
  //      <a style="text-decoration: none; background: #1D5775; color: #fff; padding: 1px 5px; border-radius:8px; font-size: 12px;" target="_blank"
  //                 href={https://www.google.com/maps?q=${position?.lat},${position?.lng}}
  //               >
  //                 Get Directions </a>
  //     </div>
  //   `;
  //     const content = `
  //   <div style="width: 150px;">
  //     <div style="display:flex; justify-content:space-between;">
  //       <div>
  //         <img src="https://s3.ap-south-1.amazonaws.com/docsteth-users-dev/organization/88878b22-a3fc-4b3f-92d2-5f43ebedbcb5__.jpeg" style="width: 50px; height: auto; border-radius: 5px 5px 0 0;">
  //       </div>
  //       <div style="display:flex; flex-direction:column; align-items:flex-start; justify-content:space-between">
  //         <a style="text-decoration: none; background: #1D5775; color: #fff; padding: 1px 5px; border-radius:4px; font-size: 12px;"
  //                     href={tel:${orgInfo?.telecom?.[0]?.value}}>
  //                     <${IoIosCall} color={"#fff"} fontSize={20} /> Call
  //         </a>
  //         <a style="text-decoration: none; background: #1D5775; color: #fff; padding: 1px 5px; border-radius:4px; font-size: 12px;" target="_blank"
  //                 href={https://www.google.com/maps?q=${position?.lat},${position?.lng}}
  //           >
  //           Get Directions 
  //         </a>
  //       </div>
  //     </div>
  //       <p style="font-weight: bold; margin-top: 5px; text-transform:capitalize">${location[0]}</p>
  //   </div>
  // `;
  const content1 = `
  <div style="width: 200px;">
    <img src="https://s3.ap-south-1.amazonaws.com/docsteth-users-dev/organization/88878b22-a3fc-4b3f-92d2-5f43ebedbcb5__.jpeg" style="width: 40px; height: auto;">
    <p style="font-weight: bold; margin-top: 5px; text-transform:capitalize">${location[0]}</p>
    <a style="text-decoration: none; background: #1D5775; color: #fff; padding: 1px 5px; border-radius:8px; font-size: 12px;" target="_blank"
       href="https://www.google.com/maps?q=${position.lat},${position.lng}">
       Get Directions 
    </a>
  </div>
`;

const content = `
  <div style="width: 150px;">
    <div style="display:flex; justify-content:space-between;">
      <div>
        <img src="https://s3.ap-south-1.amazonaws.com/docsteth-users-dev/organization/88878b22-a3fc-4b3f-92d2-5f43ebedbcb5__.jpeg" style="width: 50px; height: auto; border-radius: 5px 5px 0 0;">
      </div>
      <div style="display:flex; flex-direction:column; align-items:flex-start; justify-content:space-between">
        <a style="text-decoration: none; background: #1D5775; color: #fff; padding: 1px 5px; border-radius:4px; font-size: 12px;"
           href="tel:${orgInfo?.telecom?.[0]?.value}">
          Call
        </a>
        <a style="text-decoration: none; background: #1D5775; color: #fff; padding: 1px 5px; border-radius:4px; font-size: 12px;" target="_blank"
           href="https://www.google.com/maps?q=${position.lat},${position.lng}">
           Get Directions 
        </a>
      </div>
    </div>
    <p style="font-weight: bold; margin-top: 5px; text-transform:capitalize">${location[0]}</p>
  </div>
`;


      bounds.extend(position); // Extend bounds with each marker position

      const marker = new window.google.maps.Marker({
        position: position,
        map: newMap,
        title: location[0],
      });

      window.google.maps.event.addListener(marker, "click", () => {
        infowindow.setContent(content);
        infowindow.open(newMap, marker);
      });
    });

    newMap.fitBounds(bounds); // Fit the map to the bounds
  };

  return (
    // <div style={{ height: "400px", width: "100%" }}>
    //   <GoogleMapReact
    //     bootstrapURLKeys={{ key: "AIzaSyBoKERaSenAxv2oAp-Ma3qNVDlJIwh0UY8" }}
    //     defaultCenter={{ lat: 17.4065, lng: 78.4772 }}
    //     defaultZoom={zoom}
    //   >
    //     {/* You can add markers, polygons, or other components here */}
    //     <AddLocationIcon lat={17.4065} lng={78.4772} text="My Marker" />
    //   </GoogleMapReact>
    // </div>
    <div>
      <div
        id="map"
        style={{
          height: "400px",
          width: "100%",
          // border: "1px solid red",
          borderRadius: "20px",
        }}
      ></div>
    </div>
  );
};

export default MapComp;
