import { Box, Popover, Typography } from '@mui/material';
import React, { useState } from 'react'
import ContainedButton, { OutlinedButton } from './commonbutton';
import { CustomTextField } from './TextField';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function LoginPopOver({ open, anchorEl, handleClose, selectBranchInfo }) {
    const navigate = useNavigate()
    const [textBlur, setTextBlur] = useState(false);
    const branchName = useSelector((state) => state.branch.branch)

    const handleSignupClick = () => {
        navigate(
            branchName ? `/${branchName}/signup` : "/signup"
        );
        handleClose();
    };
    const handleLoginClick = () => {
        // UserService.doLogin()
        navigate(
            branchName ? `/${branchName}/login` : "/login"
        );
        handleClose();
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            sx={{ zIndex: 1000, top: 25 }}
        >
            <Box sx={{ p: "20px 20px" }}>
                <Typography sx={{ fontWeight: 600, mb: "5px" }}>
                    New to Paramitha Hospital ?
                </Typography>
                <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    Is this the first time you are visiting our Hospital or
                    <br /> Website ? If yes, Click Register Now to Signup
                </Typography>
                <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
                    <ContainedButton
                        width="160px"
                        height="32px"
                        bgcolor="#1B5775"
                        text="Register Now"
                        borderRadius="10px"
                        onClick={handleSignupClick} // onClick event handler
                        startIcon={null}
                        endIcon={null}
                    />
                </Box>

                <Box sx={{ mt: "30px" }}>
                    <Box
                        sx={{
                            pointerEvents: !textBlur ? "none" : "auto",
                            opacity: !textBlur ? 0.4 : 1,
                        }}
                    >
                        <Typography sx={{ fontWeight: 600, mb: "5px" }}>
                            Already visited us before ?
                        </Typography>
                        <Typography sx={{ mb: "5px", fontSize: 14, fontWeight: 500 }}>
                            If your previously visited Paramitha Hospital before, or <br />{" "}
                            Registered with us before, please continue to login
                        </Typography>
                        <Typography
                            sx={{ mb: "2px", mt: "18px", fontSize: 14, fontWeight: 500 }}
                        >
                            Registered Phone Number / User Name
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                            <CustomTextField
                                sx={{
                                    ".MuiInputBase-root": {
                                        paddingRight: "0px !important",
                                    },
                                }}
                                placeholder="Enter Your Phone Number"
                                type={"text"}
                                borderColor="#707070"
                                width="100%"
                                borderRadius="10px"
                                padding="0px"
                                icon={
                                    <ArrowForward
                                        sx={{
                                            backgroundColor: "#1B5775",
                                            fontSize: "40px",
                                            color: "white",
                                            borderRadius: "0px 10px 10px 0px",
                                            // border: "1px solid blue"
                                        }}
                                    />
                                }
                            // value={phoneNumber}
                            // onChange={handleChangePhoneNumber}

                            // onClick={handleClickPhoneNumber}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ mt: "20px", display: "flex", justifyContent: "center", mb: '30px' }}>

                        <OutlinedButton width="160px" height="35px" borderColor="#1B5775" text="Login Options" color="#1B5775" borderRadius="10px"
                            onClick={handleLoginClick} // onClick event handler
                            // disabled={!fullName || !username || !phonenum || !EmailId || !createpassword}
                            startIcon={null}
                            endIcon={null}
                        />
                    </Box>
                </Box>
            </Box>
        </Popover>
    )
}

export default LoginPopOver;