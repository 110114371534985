import { Box, Card, Grid, Typography, Avatar, IconButton, CardMedia, Button, } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import PtEduImg from '../../assets/images/patient Education.svg';

// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import URL from "../../services/api_url";
import { useSelector } from "react-redux";
import { LiveTv, OnlinePrediction } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

const ViewAllEvents = () => {
    const location = useLocation()
    const eventData = location?.state?.eventData
    const [expanded, setExpanded] = useState(false);
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch);
    const navigate = useNavigate()
    // const timeSince = (date) => {
    //   var ms = new Date().getTime() - new Date(date).getTime();
    //   var seconds = Math.floor(ms / 1000);
    //   var minutes = Math.floor(seconds / 60);
    //   var hours = Math.floor(minutes / 60);
    //   var days = Math.floor(hours / 24);
    //   var months = Math.floor(days / 30);
    //   var years = Math.floor(months / 12);
    //   if (seconds < 1) {
    //     return "Just now";
    //   }
    //   if (seconds < 60) {
    //     return seconds + "s";
    //   }
    //   if (minutes < 60) {
    //     return minutes + "m";
    //   }
    //   if (hours < 24) {
    //     return hours + "h";
    //   }
    //   if (days < 30) {
    //     return days + "d";
    //   }
    //   if (months < 12) {
    //     return months + " month";
    //   } else {
    //     return years + "y";
    //   }
    // };

    // const timeSince = (date) => {
    //   var ms = new Date().getTime() - new Date(date).getTime();
    //   var seconds = Math.floor(ms / 1000);
    //   var minutes = Math.floor(seconds / 60);
    //   var hours = Math.floor(minutes / 60);
    //   var days = Math.floor(hours / 24);
    //   var months = Math.floor(days / 30);
    //   var years = Math.floor(months / 12);
    //   if (seconds < 1) {
    //     return "Just now";
    //   }
    //   if (seconds < 60) {
    //     return seconds + "s";
    //   }
    //   if (minutes < 60) {
    //     return minutes + "m";
    //   }
    //   if (hours < 24) {
    //     return hours + "h";
    //   }
    //   if (days < 30) {
    //     return days + "d";
    //   }
    //   if (months < 12) {
    //     return months + " month";
    //   } else {
    //     return years + "y";
    //   }
    // };

    // const timeSince = (date) => {
    //   var ms = new Date().getTime() - new Date(date).getTime();
    //   var seconds = Math.floor(ms / 1000);
    //   var minutes = Math.floor(seconds / 60);
    //   var hours = Math.floor(minutes / 60);

    //   if (hours > 0) {
    //     return hours + "h " + (minutes % 60) + "m " + (seconds % 60) + "s";
    //   } else if (minutes > 0) {
    //     return minutes + "m " + (seconds % 60) + "s";
    //   } else {
    //     return seconds + "s";
    //   }
    // };

    const timeSince = (date) => {
        var postDate = new Date(date);
        var hours = postDate.getHours();
        var minutes = postDate.getMinutes();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // Handle midnight (0 hours)
        minutes = minutes < 10 ? "0" + minutes : minutes; // Add leading zero to minutes if less than 10

        return hours + ":" + minutes + " " + ampm;
    };


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    return (
        <Box
            sx={{
                mt: 2, p: { xs: "5px", sm: "5px 20px", md: "5px 80px", lg: '5px 100px' }
            }}
        >
            <Box sx={{ display: "flex", gap: 1 }}>
                <LiveTv sx={{ color: '#1D5775', fontSize: '30px' }} />
                <div>
                    <div className="text-xl font-semibold">
                        Live Health Events
                    </div>
                    <div className="font-normal mt-0.5">
                        Ask Doctors,View Posts,Interview Podcasts
                    </div >
                </div>
            </Box>


            <Grid container columnSpacing={5} sx={{ mt: "10px" }}>
                {eventData?.map((item) => (
                    <>
                        <Grid item xs={12} sm={5.8} md={6.9} >
                            <div>
                                <div
                                    style={{
                                        marginBottom: "20px",
                                        // border: "1px solid red"
                                    }}
                                >
                                    {item.eventData.eventImage ? (
                                        <Card>
                                            <CardMedia
                                                className="object-fill"
                                                component="img"
                                                // height="194"
                                                sx={{
                                                    height: { xs: "290px", sm: "290px", md: "290px" },

                                                    //   border: "1px solid red",
                                                }}
                                                src={item.eventData.eventImage}
                                                alt=""
                                            />
                                        </Card>
                                    ) : (
                                        <div>
                                            <img
                                                src={
                                                    "https://media.istockphoto.com/id/1396814518/vector/image-coming-soon-no-photo-no-thumbnail-image-available-vector-illustration.jpg?s=612x612&w=0&k=20&c=hnh2OZgQGhf0b46-J2z7aHbIWwq8HNlSDaNp2wn_iko="
                                                }
                                                alt="cover"
                                                style={{
                                                    height: "290px",
                                                    //   maxWidth: "100%",
                                                    //   objectFit: "cover",
                                                    width: "100%",
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={5.8}
                            md={4.9}
                        // sx={{ border: "1px solid red" }}

                        >
                            <div
                                style={{
                                    marginBottom: "20px",
                                    // border: "1px solid red"
                                }}
                            >
                                <Box
                                    sx={{
                                        p: "10px",
                                        height: "290px",
                                    }}
                                >
                                    <div className="overflow_hidden">
                                        <div className="flex items-center gap-2 ">
                                            <OnlinePrediction sx={{ color: '#BC0404' }} />
                                            <div className="font-medium"> Live Podcast</div>
                                        </div>
                                        <Typography
                                            className="d-flex e-align color_head font-bold cursor capitalize"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                mt: "5px",
                                            }}
                                        // onClick={() =>
                                        //   data.link
                                        //     ? console.log("")
                                        //     : docFeed.id && navigate(`/webinar/eventdata-${data.id}`)
                                        // }
                                        >
                                            {/* <CalendarMonthIcon /> */}
                                            {/* &nbsp; */}
                                            <span className="text-ellipsis text-2xl font-semibold">
                                                {item.eventData.title}
                                            </span>
                                            &nbsp;
                                            {/* <span className="text-ellipsis">
                        <Typography>{item?.title}</Typography>
                      </span> */}
                                        </Typography>
                                        <div className="mt-1 text-xs" style={{ color: 'gray' }}>
                                            <span>Starts at<span className="pl-2">
                                                {moment(item?.metadata?.dateTime).format("DD/MM/YYYY")}</span>
                                            </span><span className="pl-2">|</span><span className="pl-2">{`${timeSince(
                                                moment.utc(item.createDate).local()
                                            )}`} IST</span>
                                        </div>
                                        {/* <Typography
                      sx={{
                        mt: "5px",
                        color: "gray",
                        fontSize: "12px",
                        margin: "0px 4px",
                      }}
                    >
                      <span style={{ color: "gray" }}>Starts at </span>

                      {moment(item?.metadata?.dateTime).format("DD/MM/YYYY")}

                      <span
                        style={{
                          margin: "0px 4px",
                          paddingLeft: "10px",
                        }}
                      >{`${timeSince(
                        moment.utc(item.createDate).local()
                      )}`}</span>
                    </Typography> */}

                                        <Typography sx={{ mt: "5px", fontSize: "14px" }}>
                                            {item?.description ? (
                                                <>
                                                    {expanded
                                                        ? item.description
                                                        : `${item.description.slice(0, 114)}`}
                                                    {item.description.length > 95 ? (
                                                        <Typography
                                                            component={"span"}
                                                            onClick={handleExpandClick}
                                                            sx={{ cursor: "pointer", fontSize: 14, ml: 1 }}
                                                        >
                                                            {expanded ? "...see less" : "...see more"}{" "}
                                                        </Typography>
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </Typography>
                                    </div>
                                    <Box
                                        sx={{
                                            // display: "flex",
                                            // justifyContent: "flex-start",
                                            // alignItems: "flex-start",
                                            mt: "40px",
                                            // border: "1px solid red",
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#ACACAC",
                                                color: "#000000",
                                                boxShadow: "none",
                                                textTransform: "capitalize",
                                                marginX: "auto",
                                                border: "1px solid #BEBEBE",

                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                "&:hover": {
                                                    backgroundColor: "transparent",
                                                },
                                                borderRadius: "50px",
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: 500, fontSize: 14 }}> Register now</Typography>
                                            <ArrowRightAltIcon sx={{ ml: "10px" }} />
                                        </Button>
                                    </Box>
                                </Box>
                            </div>
                        </Grid>
                    </>
                ))}
            </Grid>
        </Box>
    );
};

export default ViewAllEvents;
