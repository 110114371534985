import React, { useState } from "react";
import { Box, Button, CardMedia, Divider, Grid, Typography } from "@mui/material";
import dummyimage from '../../../assets/youcandoit.jpg'
import { OutlinedButton } from "../../atoms/commonbutton";
import DOMPurify from 'dompurify';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const FeedArticle = ({ docFeed }) => {
    const articleData = docFeed.articleData;
    const navigate = useNavigate()
    const [expand, setExpand] = useState(false)
    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)
    const sanitizedHtml = DOMPurify.sanitize(articleData?.articleDescription, {
        FORBID_TAGS: ['style', 'font'],
        FORBID_ATTR: ['style']
    });
    const handleViewArticle = () => {
        setExpand(!expand)
        // navigate(selectedbranch?.name ? `/${selectedbranch?.name}/viewarticle/${docFeed.id}` : `/viewarticle/${docFeed.id}`, { state: { docFeed } })
    }
    return (

        <Grid container sx={{ p: "0px 5px" }}>
            <Grid item xs={12} sm={12} md={12} position={'relative'} >
                <div>
                    {docFeed?.articleData?.coAuthors ? (
                        <Typography sx={{ p: "0px 10px", fontSize: "14px" }}>
                            Co-Authors:{" "}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: docFeed.articleData.coAuthors,
                                }}
                                style={{ whiteSpace: 'pre-wrap' }}
                            />
                        </Typography>
                    ) : (
                        ""
                    )}
                </div>
                <div>
                    {docFeed?.articleData?.contributors ? (
                        <Typography sx={{ p: "0px 10px 5px 10px", fontSize: "14px" }}>
                            Contributors:{" "}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: docFeed.articleData.contributors,
                                }}
                            />
                        </Typography>
                    ) : (
                        ""
                    )}
                </div>
                <div>
                    <div

                        style={{
                            height: expand ? 'auto' : '30px',
                            overflow: 'hidden',
                            fontSize: '14px',
                            lineHeight: '1.8',
                            padding: '0px 8px',
                            whiteSpace: 'pre-wrap', wordBreak: 'break-word'

                        }}
                        dangerouslySetInnerHTML={{
                            __html: sanitizedHtml.slice(0, expand ? undefined : 102)
                        }}
                    />
                    <Typography
                        component={"span"}
                        // onClick={() => setExpand(!expand)}
                        onClick={handleViewArticle}
                        sx={{ cursor: "pointer", fontSize: 14, ml: 1, color: '#193BAA' }}
                    >
                        {expand ? "Read Less" : "Read More"}
                    </Typography>
                </div>
                <Divider sx={{ m: "10px 0px" }} />

                {/* <Box sx={{ height: '22px' }}>

                </Box> */}

                {/* <div style={{
                    position: 'absolute', bottom: 0, left: 0, right: 0,
                }}
                    onClick={handleViewArticle}>
                    {docFeed?.articleData?.articleTitle !== null ? (
                        // <a href={`/viewarticle/${docFeed.id}`}>
                        <div
                            style={{
                                backgroundColor: "#e8e8e0",
                                display: "flex",
                                padding: "10px 20px", opacity: 0.9,
                                justifyContent: "space-between",
                                // marginBottom: "100px",
                                // border: "1px solid red",
                            }}
                            className="d-flex cursor"
                        >
                            {articleData?.articleTitle ? (
                                <p style={{ overflow: "hidden", flex: 1 }}>
                                    <Typography
                                        className="font-bold capitalize add-cover text-ellipsis "
                                        style={{ pt: 1, fontSize: "18px" }}
                                    >
                                        {articleData?.articleTitle?.slice(0, 50)}
                                    </Typography>
                                </p>
                            ) : null}

                            <OutlinedButton text="Read Article" fontSize="14px" />
                        </div>
                        // </a>
                    ) : null}
                </div > */}
            </Grid >

        </Grid >

    );
};

export default FeedArticle;
