export const handleLogout = () => {
    localStorage.removeItem("kctoken");
    localStorage.removeItem("IbmId");
    localStorage.removeItem("rctoken")
    localStorage.removeItem("userName")
    localStorage.removeItem("participantId")
    localStorage.removeItem("visitorId")
    localStorage.removeItem("pId")
    localStorage.removeItem("roomId")
    localStorage.removeItem("userId")
    localStorage.removeItem("patientName")
    window.location.href = '/';
} 