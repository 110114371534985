import React, { useState } from 'react';
import { google, outlook, office365, yahoo, ics } from 'calendar-link';
import { Button, Menu, MenuItem } from '@mui/material';

const CalendarLinks = ({ event }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Log the event object to verify its structure
    // console.log(event);

    // Check if the event object has the required properties
    if (!event || !event.start || !event.end || !event.title) {
        return <div>Error: Event details are missing or incorrect.</div>;
    }

    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleClick}
                sx={{ color: '#fff', textTransform: 'capitalize', height: '30px', borderRadius: '7px' }}
            >
                Set Reminder On Calendar
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => window.open(google(event), '_blank', 'noopener noreferrer')}>
                    Add to Google Calendar
                </MenuItem>
                {/* <MenuItem onClick={() => window.open(outlook(event), '_blank', 'noopener noreferrer')}>
                    Add to Outlook Calendar
                </MenuItem> */}
                <MenuItem onClick={() => window.open(office365(event), '_blank', 'noopener noreferrer')}>
                    Add to Office 365 Calendar
                </MenuItem>
                <MenuItem onClick={() => window.open(yahoo(event), '_blank', 'noopener noreferrer')}>
                    Add to Yahoo Calendar
                </MenuItem>
                <MenuItem onClick={() => window.open(ics(event), '_blank', 'noopener noreferrer')}>
                    Download ICS File
                </MenuItem>
            </Menu>
        </div>
    );
};

export default CalendarLinks;
