import * as React from 'react';
import { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Close, Message, WhatsApp } from '@mui/icons-material';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import ChatMessage from './chat';
import "./chat.css";
import { createInteractionData } from '../atoms/getLocations';
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useEffect } from 'react';
import { closeWebSocket, initializeWebSocket } from '../doctors/webSocketManager';
import { Alert, Badge, Snackbar } from '@mui/material';
import { fetchLoc } from '../../redux/actions/loginActions';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { localstore } from '../localstore/localstore';
import useWebSocket, { ReadyState } from "react-use-websocket";
import notification from '../../assets/notification.mp3'



export default function FloatingMenu() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [showWhatsApp, setShowWhatsApp] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [visitorId, setVisitorId] = useState("");
    const visitId = localStorage.getItem("visitorId");
    const [messages, setMessages] = useState([]);
    const [contentMessage, setContentMessage] = useState([])
    const dispatch = useDispatch()
    // const activity = ""
    // const pageTitle = "Paramitha Hospitals"
    // const pageId = window.location.pathname;
    const [welcomeMessage, setWelcomeMessage] = useState("");
    const [messageHistory, setMessageHistory] = useState([]);
    const [show, setShow] = useState(1)
    // console.log(messageHistory, "hello")
    // console.log(messages, "contentmessages")
    const [message, setMessage] = useState("");
    const [name, setName] = useState(null);
    const [phone, setPhone] = useState("");
    // const [visitorId, setVisitorId] = useState("");
    const [typing, setTyping] = useState(false);
    const [adminSeen, setAdminSeen] = useState(false)
    const [chatData, setChatData] = useState('')
    const [participantId, setParticipantId] = useState("");
    const [socketUrl, setSocketUrl] = useState("");
    const [userListOpen, setUserListopen] = useState(false);

    const [userDetails, setUserDetails] = useState({});
    const [latLong, setLatLong] = useState(null);
    const [reply, setReply] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackOpen, setSnackOpen] = useState(true);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const usertoken = localstore.getToken()
    let ptId = localStorage.getItem("participantId");
    // let visitorId = localStorage.getItem("visitorId");
    const roomId = localStorage?.getItem('roomId')
    const timeoutRef = useRef(null);
    const [newMessages, setNewMessages] = useState([]); // Stores only new messages
    const [hasPlayedAudio, setHasPlayedAudio] = useState(false); // Flag to ensure sound plays only once

    const pageTitle = "Paramitha Hospitals"
    const activity = window.location.pathname;
    const pageId = window.location.pathname;

    // console.log(activity, "userDetails")
    // console.log(pageId, "pageId")

    useEffect(() => {
        const generateVisitorId = async () => {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            setVisitorId(result?.visitorId);
            // setVisitorId("83a571f136f3fbbfdf99ca51ca3d2000");
            localStorage.setItem("visitorId", result?.visitorId);
            // localStorage.setItem("visitorId", "83a571f136f3fbbfdf99ca51ca3d2000");
        };
        generateVisitorId(); // Generate visitorId once on initial load
    }, []);

    useEffect(() => {
        dispatch(fetchLoc());
    }, []);

    useEffect(() => {
        if (visitorId) {
            const setupWebSocket = async () => {
                const initialInteractionData = await createInteractionData(pageId, pageTitle, activity);
                // Initialize WebSocket when the component mounts
                const handleMessage = (message) => {
                    // console.log("Message received:", message);

                    // setMessages((prevMessages) => [...prevMessages, message] || []); // Append the message to the state
                    setMessages((prev) => {
                        const updatedHistory = [...prev, message];
                        // Filter messages for valid content
                        const messageContent = updatedHistory.filter((msg) => {
                            try {
                                const parsedMessage = (msg);
                                return parsedMessage?.content; // Only include messages with content
                            } catch {
                                return false; // Exclude invalid JSON
                            }
                        });
                        // console.log("Filtered Messages:", messageContent);
                        return updatedHistory;
                    })
                };
                initializeWebSocket(initialInteractionData, handleMessage);
            };

            setupWebSocket();
            // setWebSocketInitialized(true); // Set to true after initializing

            return () => {
                // Clean up the WebSocket connection when the component unmounts
                closeWebSocket();
            };
        }
    }, [visitorId]);


    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleWhatsAppClick = () => {
        setShowWhatsApp(true);
        setShowChat(false);
        setMenuOpen(false); // Optionally close menu after selecting
    };

    const handleChatClick = () => {
        setShowChat(true);
        setShowWhatsApp(false);
        setMenuOpen(false); // Optionally close menu after selecting
    };

    useEffect(() => {
        const messageContent = messages.filter(msg => {
            try {
                const parsedMessage = JSON.parse(msg);
                return parsedMessage?.content; // Only count messages with content
            } catch {
                return false; // If parsing fails, don't count it
            }
        });

        // Set the contentMessage state once after filtering
        setContentMessage(messageContent || []);

        // Log for debugging
        // console.log(messageContent, "helcontent");
        // console.log(messages, "messagesssss");
    }, [messages]); // Run effect when messages change

    const contentMessageCount = contentMessage?.length;
    const newMessageCount = newMessages?.length

    // console.log(newMessages, "newmessages")
    // console.log(contentMessage, "helcontent")
    // console.log(messages, "messagesssss")
    // console.log(contentMessage, "contentMessage")
    // console.log(messageCount, "messagecount")
    useEffect(() => {
        sendJsonMessage({
            "roomid": roomId,
            "type": "message.seen",
        })
    }, [])

    const { getWebSocket, readyState, sendJsonMessage } = useWebSocket(socketUrl, {
        onOpen: () => {
            // console.log("Connected!");
        },
        onClose: () => {
            // console.log("Disconnected!");
        },
        onMessage: (e) => {
            const data = JSON.parse(e.data);
            switch (data.type) {
                case "welcome_message":
                    setWelcomeMessage(data.message);
                    break;
                case "chat.message":
                    sendJsonMessage({
                        "roomid": roomId,
                        "type": "message.seen",
                        "user": "patient"
                    })
                    setMessageHistory((prev) => [data, ...prev]);
                    setNewMessages((prev) => {
                        const updatedHistory = [...prev, data];
                        // Filter messages for valid content
                        const messageContent = updatedHistory.filter((msg) => {
                            try {
                                const parsedMessage = (msg);
                                return parsedMessage?.content; // Only include messages with content
                            } catch {
                                return false; // Exclude invalid JSON
                            }
                        });
                        // console.log("Filtered Messages:", messageContent);
                        return updatedHistory;
                    })

                    break;
                case "message.typing":
                    // console.log("typpinggggg", data)

                    if (data?.user === "admin") {

                        setTyping(true)
                    }
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }

                    // Set a timeout to set typing to false after 3 seconds
                    timeoutRef.current = setTimeout(() => {
                        setTyping(false);
                    }, 3000);


                    // console.log("typpinggggg")
                    // setTyping(true)
                    // if (timeoutRef.current) {
                    //     clearTimeout(timeoutRef.current);
                    // }

                    // // Set a timeout to set typing to false after 3 seconds
                    // timeoutRef.current = setTimeout(() => {
                    //     setTyping(false);
                    // }, 3000);

                    break;

                case "message.seen":
                    if (data?.user === "admin") {
                        setAdminSeen(true)
                    }
                default:
                    // console.log("Unknown message type!");
                    break;
            }
        },
        shouldReconnect: (closeEvent) => true,
    });

    // console.log(newMessages, "typing")

    // useEffect(() => {
    //     if (contentMessage?.length > 0 || newMessages?.length > 0) {
    //         const audio = new Audio(notification);
    //         audio.play().catch((err) => {
    //             console.error("Failed to play notification sound:", err);
    //         });
    //         setSnackbarOpen(true)
    //     }
    // }, [contentMessage, newMessages, notification]);
    const audioRef = useRef(new Audio(notification));

    useEffect(() => {
        // Preload the audio
        audioRef.current.preload = 'auto';
    }, []);

    // console.log(contentMessage, "contentmessges")
    // console.log(newMessages, "newmessges")
    useEffect(() => {
        if (contentMessage?.length > 0 || newMessages?.length > 0) {
            const playAudio = async () => {
                try {
                    await audioRef.current.play();
                } catch (err) {
                    console.error("Failed to play notification sound:", err);
                }
            };
            playAudio();
            if (userListOpen) {
                setSnackOpen(false)
            } else {
                setSnackbarOpen(true)
            }
        }
    }, [contentMessage, newMessages]);
    // useEffect(() => {
    //     // Only play sound once when new messages are received
    //     if (newMessageCount || contentMessageCount) {
    //         if (!hasPlayedAudio) {
    //             const audio = new Audio(notification);

    //             // Set up an event listener to play the sound only when it's ready
    //             audio.play().catch((err) => {
    //                 console.error("Failed to play notification sound:", err);
    //             });

    //             // Set state to show the snackbar message and open it
    //             setSnackbarMessage("You have a new notification!");
    //             setSnackbarOpen(true);

    //             // Set the flag to true to prevent the sound from playing again
    //             setHasPlayedAudio(true);
    //         }
    //     }
    // }, [hasPlayedAudio, notification]);

    // useEffect(() => {
    //     // Reset audio played flag when content or newMessages change (or any other logic you prefer)
    //     setHasPlayedAudio(false);
    // }, [newMessageCount, contentMessageCount]);


    useEffect(() => {
        return () => {
            const socket = getWebSocket();
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.close();
            }
        };
    }, [getWebSocket]);

    useEffect(() => {
        if (participantId) {
            axios.get(`${process.env.REACT_APP_CHAT_URL}GetParticipant/${participantId}`).then((res) => {
                if (res?.status === 204) {
                    setUserDetails({});
                } else {
                    setUserDetails(res?.data);
                }
            }).catch((err) => console.log(err));
        }
    }, [participantId]);

    useEffect(() => {
        if (localstore.getToken() && localstore.getUserId()) {
            axios.get(`${process.env.REACT_APP_CHAT_URL}GetParticipantIds?userId=${localstore.getUserId()}`, {
                headers: {
                    "Authorization": `Bearer ${usertoken}`
                }
            }).then((res) => {
                if (res?.data?.length === 0) {
                    const data = {
                        "name": localstore.getUsername(),
                        "phone": '',
                        "email": ""
                    };
                    axios.post(`createNewParticipant`, data).then((res) => {
                        setUserDetails(res?.data);
                        localStorage.setItem("participantId", res?.data?.ParticipantId);
                        setParticipantId(res?.data?.ParticipantId);
                        localStorage.setItem("roomId", res?.data?.roomid);
                    }).catch((err) => console.log(err));
                } else {
                    setUserDetails(res?.data?.[0]);
                    localStorage.setItem("participantId", res?.data?.[0]?.Participant?.participentId);
                    setParticipantId(res?.data?.[0]?.Participant?.participentId);
                    localStorage.setItem("roomId", res?.data?.[0]?.roomid);
                }
            }).catch((err) => console.log(err));
        }
    }, [localstore.getToken()]);


    const handleOnNearestLocation = async () => {
        // setLoading(true); // Start loading
        try {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });

            const { latitude, longitude } = position.coords;
            const latLongString = `${latitude},${longitude}`;
            setLatLong(latLongString)
        } catch (error) {
            console.error('Error fetching geolocation data:', error);

        } finally {
            console.log(false); // End loading
        }
    }


    useEffect(() => {
        if (visitorId && ptId) {

            let surl = usertoken
                ? `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/group?visitorId=${visitorId}&participantId=${ptId}&patient=${usertoken}`
                : `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/group?participantId=${ptId}&visitorId=${visitorId}${latLong ? `&latLong=${latLong}` : ''}`;
            setSocketUrl(surl);
        }
    }, [visitorId, ptId, latLong]);


    useEffect(() => {
        if (socketUrl) {
            getWebSocket();
        }
    }, [socketUrl]);

    function handleChangeMessage(e) {
        setMessage(e.target.value);
    }

    function handleChangeName(e) {
        setName(e.target.value);
    }

    function handleChangePhone(e) {
        setPhone(e.target.value?.replace(/\D/g, "")?.slice(0, 10));
    }


    useEffect(() => {
        if (roomId) {
            fetchMessages(roomId);
        }

        handleOnNearestLocation()
    }, [roomId]);


    const updateUserInfo = async () => {
        const data = {
            "name": name,
            "phone": phone,
            "email": ""
        };

        try {
            const res = await axios.post(`${process.env.REACT_APP_CHAT_URL}createNewParticipant`, data);

            if (res.status === 200 || res.status === 201) {
                // Set user details and store participantId and roomId in localStorage
                setUserDetails(res?.data);
                localStorage.setItem("participantId", res?.data?.Participant?.participentId);
                localStorage.setItem("roomId", res?.data?.roomid);

                // Add roomId to each message in the messages array
                const updatedMessages = contentMessage?.map((msg) => {
                    try {
                        const parsedMsg = JSON.parse(msg); // Parse each message
                        const { type, ...messageWithoutType } = parsedMsg; // Remove the 'type' property
                        return {
                            ...messageWithoutType, // Spread the message without the 'type' property
                            roomid: res?.data?.roomid // Add roomId to the message
                        };
                    } catch (error) {
                        console.error("Error parsing message:", error);
                        return msg; // Return the original message if parsing fails
                    }
                });

                // Optionally, send the updated messages along with the request to store interaction conversations
                const resdata = await axios.post(`${process.env.REACT_APP_CHAT_URL}storeInteractionConversations`, updatedMessages, {
                    messages: updatedMessages // Send updated messages with roomId
                });
                if (resdata.status === 200 || resdata.status === 201) {
                    // Delay fetchMessages by 2 seconds
                    // setTimeout(() => {
                    fetchMessages(res?.data?.roomid);
                    // }, 2000); // 2000ms = 2 seconds
                }

                // Return the response data
                return res?.data;
            }
        } catch (err) {
            console.log("Error:", err);
        }
    };

    const fetchMessages = (roomId) => {
        axios.get(`${process.env.REACT_APP_CHAT_URL}getRoomConversations/${roomId}`, {
        }).then((res) => {
            setMessageHistory(res?.data);
        }).catch((err) => console.log(err));
    };

    const handleSubmit = async () => {
        if (!name?.trim()) {
            alert("Please enter your name.");
            return;
        }

        if (!phone?.trim() || !/^\d{10}$/.test(phone)) {
            alert("Please enter a valid 10-digit phone number.");
            return;
        }

        const userInfo = await updateUserInfo();
        if (userInfo) {
            if (visitorId && ptId) {
                const surl = localstore.getToken()
                    ? `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/group?visitorId=${visitorId}&participantId=${ptId}&patient=${usertoken}`
                    : `wss://${process.env.REACT_APP_WEBSOCKET_URL}socket/group?participantId=${ptId}&visitorId=${visitorId}${latLong ? `&latLong=${latLong}` : ''}`;
                setSocketUrl(surl);
            }

            // sendJsonMessage({
            //     "roomid": localStorage.getItem('roomId'),
            //     // "content": message,
            //     "attachment": "",
            //     "urls": "",
            //     "subject": "help"    
            // });

            setName("");
            setMessage("");
            setPhone("");
        }
    };

    const handleOpenChat = () => {
        setUserListopen(true)
        setSnackbarOpen(false)
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };


    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 25,
                right: 30,
                zIndex: 1000,
            }}
        >
            {/* Main Add Button */}
            {menuOpen ? <IconButton
                sx={{
                    backgroundColor: '#E44190',
                    color: '#FFFFFF',
                    '&:hover': {
                        backgroundColor: '#E44190',
                    },
                    width: 56,
                    height: 56,
                    zIndex: 1001, // Ensure it stays above the menu
                }}
                onClick={toggleMenu}
            >
                {/* {menuOpen ? <Close /> : <Add />} */}
                {/* {menuOpen ? <Close /> : <Message />} */}
                <Close />
            </IconButton> :

                <div>
                    {contentMessageCount > 0 ?

                        <Badge sx={{
                            '.MuiBadge-badge': {
                                right: '8px', top: '10px', zIndex: '1001', bgcolor: '#EB0014', color: 'white'
                            }
                        }} badgeContent={contentMessageCount} onClick={() => setSnackbarOpen(false)} >
                            <ChatMessage messages={contentMessage} setContentMessage={setContentMessage} setSnackbarOpen={setSnackbarOpen} setNewMessages={setNewMessages} userListOpen={userListOpen} setUserListopen={setUserListopen} userDetails={userDetails} phone={phone} handleChangeName={handleChangeName} handleChangePhone={handleChangePhone} typing={typing} messageHistory={messageHistory} handleSubmit={handleSubmit} sendJsonMessage={sendJsonMessage} name={name} ptId={ptId} />
                            {/* } */}
                        </Badge> :
                        <>
                            {
                                ptId ?
                                    <Badge sx={{
                                        '.MuiBadge-badge': {
                                            right: '8px', top: '10px', zIndex: '1001', bgcolor: '#EB0014', color: 'white'
                                        }
                                    }} badgeContent={newMessageCount} onClick={() => setSnackbarOpen(false)} >
                                        <ChatMessage messages={contentMessage} adminSeen={adminSeen} setSnackbarOpen={setSnackbarOpen} setContentMessage={setContentMessage} setNewMessages={setNewMessages} userListOpen={userListOpen} setUserListopen={setUserListopen} userDetails={userDetails} phone={phone} handleChangeName={handleChangeName} handleChangePhone={handleChangePhone} typing={typing} messageHistory={messageHistory} handleSubmit={handleSubmit} sendJsonMessage={sendJsonMessage} name={name} ptId={ptId} />
                                        {/* } */}
                                    </Badge> : <Badge sx={{
                                        '.MuiBadge-badge': {
                                            right: '8px', bgcolor: '#EB0014', color: 'white', zIndex: 1001
                                        }
                                    }} badgeContent={contentMessageCount} >
                                        <IconButton
                                            sx={{
                                                backgroundColor: '#E44190',
                                                color: '#FFFFFF',
                                                '&:hover': {
                                                    backgroundColor: '#E44190',
                                                },
                                                width: 56,
                                                height: 56,
                                                zIndex: 1001, // Ensure it stays above the menu
                                            }}
                                            onClick={toggleMenu}
                                        >
                                            {/* {menuOpen ? <Close /> : <Add />} */}
                                            {/* {menuOpen ? <Close /> : <Message />} */}
                                            {/* {menuOpen ? <Close /> : */}

                                            <Message />

                                            {/* } */}
                                        </IconButton>
                                    </Badge>
                            }
                        </>
                    }

                </div>

                // <Badge sx={{
                //     '.MuiBadge-badge': {
                //         right: '8px', bgcolor: '#EB0014', color: 'white', zIndex: 1001
                //     }
                // }} badgeContent={messageCount} >
                //     <IconButton
                //         sx={{
                //             backgroundColor: '#E44190',
                //             color: '#FFFFFF',
                //             '&:hover': {
                //                 backgroundColor: '#E44190',
                //             },
                //             width: 56,
                //             height: 56,
                //             zIndex: 1001, // Ensure it stays above the menu
                //         }}
                //         onClick={toggleMenu}
                //     >
                //         {/* {menuOpen ? <Close /> : <Add />} */}
                //         {/* {menuOpen ? <Close /> : <Message />} */}
                //         {/* {menuOpen ? <Close /> : */}

                //         <Message />

                //         {/* } */}
                //     </IconButton>
                // </Badge>
            }


            {ptId ?
                <div className='mt-2'>
                    {newMessages?.map((msg, index) => {
                        const parsedMessage = msg;
                        return (
                            <Snackbar
                                key={index}
                                open={snackbarOpen}

                                autoHideDuration={null} // Disable auto-hide
                                // onClose={() => handleSnackbarClose(index)}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                sx={{
                                    mt: `${index * 60}px`, // Add margin to stack snackbars
                                    cursor: "pointer",

                                }}
                                onClick={handleOpenChat}
                            // Open chat on click
                            >
                                <Alert
                                    onClose={() => handleSnackbarClose(index)}
                                    // severity="info"
                                    // variant="filled"
                                    sx={{
                                        backgroundColor: "#827b67", // Set your custom background color here
                                        color: 'white',
                                        width: "300px", // Fixed width
                                        maxHeight: "300px", // Optional: Set a max height for better control
                                        overflowY: "auto", // Add scroll if height exceeds maxHeight
                                        wordWrap: "break-word", // Break long words
                                    }}
                                    icon={< Message sx={{ color: 'white' }} fontSize="inherit" />} // Custom message icon
                                >
                                    {msg.content}
                                </Alert>
                            </Snackbar>
                        )
                    })}
                </div> :
                <div>
                    {contentMessage?.map((msg, index) => {
                        const parsedMessage = JSON.parse(msg);
                        return (
                            <Snackbar
                                key={index}
                                open={snackbarOpen}
                                autoHideDuration={null} // Disable auto-hide
                                // onClose={() => handleSnackbarClose(index)}
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                sx={{
                                    mt: `${index * 60}px`, // Add margin to stack snackbars
                                    cursor: "pointer",

                                }}
                                onClick={handleOpenChat}
                            >
                                <Alert
                                    onClose={() => handleSnackbarClose(index)}

                                    sx={{
                                        backgroundColor: "#827b67", // Set your custom background color here
                                        color: 'white',
                                        width: "300px", // Fixed width
                                        maxHeight: "300px", // Optional: Set a max height for better control
                                        overflowY: "auto", // Add scroll if height exceeds maxHeight
                                        wordWrap: "break-word", // Break long words
                                    }}
                                    icon={< Message sx={{ color: 'white' }} fontSize="inherit" />} // Custom message icon
                                >
                                    {parsedMessage?.content}
                                </Alert>
                            </Snackbar>
                        )
                    })}
                </div>
            }


            {/* Circular Menu */}
            {
                menuOpen && (
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 70, // Adjust based on your main button position
                            right: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 1, // Spacing between buttons
                            }}
                        >
                            {/* WhatsApp Button */}
                            <IconButton
                                sx={{
                                    backgroundColor: '#00E676',
                                    color: 'black',

                                    width: 56,
                                    height: 56,
                                    '&:hover': {
                                        backgroundColor: '#00E676',
                                    },
                                }}
                            // onClick={handleWhatsAppClick}
                            >
                                <FloatingWhatsApp
                                    phoneNumber='+917207771225'
                                    accountName="Paramitha Hospitals"
                                    className='whatsappButton'
                                    avatar="https://paramith-website-contents.s3.ap-south-1.amazonaws.com/Paramitha+logo-Light.jpg"
                                />
                            </IconButton>

                            {/* Chat Button */}
                            {/* <IconButton
                            sx={{
                                backgroundColor: '#E44190',
                                // color: '#FFFFFF',
                                width: 56,
                                height: 56,
                                '&:hover': {
                                    backgroundColor: '#E44190',
                                },
                                // mt: 1
                            }}
                        // onClick={handleChatClick}
                        >
                        </IconButton> */}
                            <div>
                                {ptId ?
                                    <Badge sx={{
                                        '.MuiBadge-badge': {
                                            right: '8px', top: '10px', zIndex: '1001', bgcolor: '#EB0014', color: 'white'
                                        }
                                    }} badgeContent={newMessageCount} onClick={() => setSnackbarOpen(false)} >
                                        <ChatMessage messages={contentMessage} adminSeen={adminSeen} setSnackbarOpen={setSnackbarOpen} setContentMessage={setContentMessage} setNewMessages={setNewMessages} userListOpen={userListOpen} setUserListopen={setUserListopen} userDetails={userDetails} phone={phone} handleChangeName={handleChangeName} handleChangePhone={handleChangePhone} typing={typing} messageHistory={messageHistory} handleSubmit={handleSubmit} sendJsonMessage={sendJsonMessage} name={name} ptId={ptId} />
                                        {/* } */}
                                    </Badge> :
                                    <Badge sx={{
                                        '.MuiBadge-badge': {
                                            right: '8px', top: '10px', zIndex: '1001', bgcolor: '#EB0014', color: 'white'
                                        }
                                    }} badgeContent={contentMessageCount} onClick={() => setSnackbarOpen(false)} >
                                        <ChatMessage messages={contentMessage} adminSeen={adminSeen} setSnackbarOpen={setSnackbarOpen} setContentMessage={setContentMessage} setNewMessages={setNewMessages} userListOpen={userListOpen} setUserListopen={setUserListopen} userDetails={userDetails} phone={phone} handleChangeName={handleChangeName} handleChangePhone={handleChangePhone} typing={typing} messageHistory={messageHistory} handleSubmit={handleSubmit} sendJsonMessage={sendJsonMessage} name={name} ptId={ptId} />
                                        {/* } */}
                                    </Badge>}
                            </div>

                        </Box>

                    </Box>
                )
            }


        </Box >
    );
}


