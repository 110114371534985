import React from 'react'

function PatientEducation() {
    return (
        <div>
            <div className='font-semibold text-base md:text-lg'>Patient Education</div>
            <div className='pt-2 text-sm md:text[16px]'>No Data Available</div>
        </div>

    )
}

export default PatientEducation