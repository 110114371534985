
export const Patientdata = {
    'patientData': {
        'active': true,
        'address': [],
        'birthDate': '',
        'communication': [],
        'gender': '',
        'identifier': [],
        'name': [
            {
                'use': 'official',
                'given': [],
                // 'family': '',
            },
        ],
        'photo': [],
        'resourceType': 'patient',
        'telecom': [
            {
                use: 'mobile',
                value: '',
                system: 'phone',
            },
            {
                value: '',
                system: 'email',
            },
        ],
    },
    'phoneNumber': '',
    'language': 'English',
};
export default Patientdata;

export const Ibmpatient = {
    'active': true,
    'address': [],
    'birthDate': '',
    'communication': [],
    'gender': '',
    'identifier': [],
    'name': [
        {
            'use': 'official',
            'given': [],
            // 'family': '',
        },
    ],
    'photo': [],
    'resourceType': 'patient',
    'telecom': [
        {
            use: 'mobile',
            value: '',
            system: 'phone',
        },
    ],
};
