import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RepresentNoDetailsPage = ({ contextInfo }) => {

  return (
    <div className='text-xl text-center flex items-center justify-center h-screen'>
      {contextInfo}
    </div>
  );
};

export default RepresentNoDetailsPage;
