import React, { useEffect, useState } from 'react'
import CommonDialog from '../atoms/commonModal'
import { Box, Typography, Grid, Divider, Button, TextField, Select, CircularProgress, DialogContent, DialogActions, DialogTitle, CardMedia, Tooltip, MenuItem, InputAdornment } from '@mui/material'
import { Close, EventAvailable, LocationOnOutlined } from '@mui/icons-material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import moment from 'moment';
import { CustomTextField } from '../atoms/TextField';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import URL from '../../services/api_url';
import { localstore } from '../localstore/localstore';
import ErrorStatusMsg from '../atoms/ErrorStatusMsg';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import editIcon from '../../assets/edit_24dp_FILL0_wght400_GRAD0_opsz24.svg';
import timeIcon from '../../assets/images/field-time-outlined.svg';
import BranchUrl from '../../services/api_branch_url';
import ReCAPTCHA from 'react-google-recaptcha';
import HealthCareAppointment from './healthcareAppointment';


function HealthCareRequestPopup({ open, setOpen, selectedbranch, locationId, deptData, deptImg, comment, serviceData }) {

  const selectedBranchId = selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`

  const [show, setShow] = useState(1)
  const [value, setValue] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verifyOtp, setVerifyotp] = useState("");
  const [otpOpen, setOtpOpen] = useState(false)
  const dispatch = useDispatch()
  const urls = BranchUrl()
  const [searchTerm, setSearchTerm] = useState('');
  const [searchedItems, setSearchedItems] = useState([]);
  const [sheduleSlot, setSheduleSlot] = useState([]);
  const [captchaToken, setCaptchToken] = useState(null)
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [alertmessage, setAlertmessage] = useState('')
  const [alertstatus, setAlertstatus] = useState('')
  const [patientDetails, setPatientDetails] = useState([])
  const [requestedPeriod, setRequestedPeriod] = useState('')
  const [requestedPeriodError, setRequestedPeriodError] = useState('')
  const [startDate, setStartDate] = useState('')
  const [timeRange, setTimeRange] = useState('');

  const [endDate, setEndDate] = useState('')
  const [phoneError, setPhoneError] = useState('')

  const [loading, setLoading] = useState(false)
  const ibmId = localstore.getIbmId()
  const usertoken = localstore.getToken()
  const randomId = sessionStorage.getItem('randomId');
  const { id } = useParams()
  const [countdown, setCountdown] = useState(60);

  const [dialogValue, setDialogValue] = React.useState({
    title: '',
    year: '',
  });

  const initialSlot = 13

  const [visibleSlots, setVisibleSlots] = useState(initialSlot);

  const loadMoreSlots = () => {
    setVisibleSlots(prev => prev + 4); // Load 5 more slots
  };

  // const handleClose = () => {
  //     setDialogValue({
  //         title: '',
  //         year: '',
  //     });
  //     // setPatientOpen(false);
  // };

  console.log(value, "value")

  useEffect(() => {
    let timer;
    if (otpOpen && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [otpOpen, countdown]);

  const handleSearch = () => {
    if (searchTerm.trim() !== '') {
      setSearchedItems((prevItems) => [...prevItems, searchTerm]);
      setSearchTerm('');
    }
  };



  const handleRemoveItem = (index) => {
    setSearchedItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setValue({
      title: dialogValue.title,
      year: parseInt(dialogValue.year, 10),
    });
    handleClose();
  };


  const handleChangePhoneNumber = (e) => {
    // setPhoneNumber(e.target.value?.replace(/\D/g, "")?.slice(0, 10))
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, '').slice(0, 10); // Remove non-numeric characters

    // Update phone state
    setPhoneNumber(numericValue);
    setPhoneError('')
    // Validate phone number
    // if (numericValue.length !== 10) {
    //   setPhoneError('Phone number must be exactly 10 digits');
    // } else {
    //   setPhoneError(''); // Clear error if valid
    // }
  }


  const handleChangeOtp = (e) => {
    const inputValue = e.target.value.replace(/\D/g, '');
    setVerifyotp(inputValue.slice(0, 5));
  }

  const handleAppointment = async (date) => {
    // Check if the date is valid
    if (date && dayjs(date).isValid()) {
      const formattedDate = dayjs(date).toDate();  // Convert to native Date if necessary
      console.log(formattedDate, "valid date");
      setValue(formattedDate);  // Save the native Date
    } else {
      console.error("Invalid date selected");
    }
  };




  const handleSelectSlot = (slot) => {
    setSelectedSlot(slot);

  }

  const formatTime = (date) => {
    const hours = date.getUTCHours() % 12 || 12;
    const minutes = date.getUTCMinutes();
    const period = date.getUTCHours() < 12 ? 'AM' : 'PM';
    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
  };

  const handleChangeRequest = (e) => {
    const selectedValue = e.target.value;
    setRequestedPeriod(selectedValue)
    setRequestedPeriodError('')
    let start, end;
    const date = moment(); // Assuming you're using the current date for this example
    const formattedDate = date.format('YYYY-MM-DD'); // Ensure format is correct for ISO string

    switch (selectedValue) {
      case 'Morning':
        start = new Date(`${formattedDate}T08:00:00Z`);
        end = new Date(`${formattedDate}T12:00:00Z`);
        break;
      case 'AfterNoon':
        start = new Date(`${formattedDate}T12:00:00Z`);
        end = new Date(`${formattedDate}T16:00:00Z`);
        break;
      case 'Evening':
        start = new Date(`${formattedDate}T16:00:00Z`);
        end = new Date(`${formattedDate}T20:00:00Z`);
        break;
      case 'Night':
        start = new Date(`${formattedDate}T20:00:00Z`);
        end = new Date(`${formattedDate}T24:00:00Z`);
        break;
      default:
        start = null;
        end = null;
    }

    // Validate if start and end dates are valid Date objects
    if (start && !isNaN(start.getTime()) && end && !isNaN(end.getTime())) {
      setStartDate(start);
      setEndDate(end);
      const formattedTimeRange = `${formatTime(start)} - ${formatTime(end)}`;
      setTimeRange(formattedTimeRange); // Set the time range
    } else {
      console.error('Invalid date selected');
      setTimeRange('Invalid Date'); // If invalid, display an error
    }
  };


  const handleRecaptchaChange = (value) => {
    setCaptchToken(value)
    setAlertmessage('')
  }
  const handleSendOtp = () => {

    const data = {
      "id": randomId,
      "phoneNumber": `+91${phoneNumber}`,
      "messagetype": "OTP",
      "apptype": "Paramitha Appointment Booking",
      "email": ""
    }
    // timeinterval();


    axios.get(`${URL.check}/check/validMobileNumber/${phoneNumber}`).then(
      res => {

        if (captchaToken) {


          setLoading(true)

          axios.post(`${URL.sendOtpAppointment}`, data, {
            headers: {
              'Usertoken': `Bearer ${captchaToken}`
            }
          }).then(res => {
            if (res.status === 200 || res.status === 201) {
              setAlertmessage('OTP send successfully')
              setAlertstatus('success')
              setOtpOpen(true)
              setCountdown(60); // Reset countdown when OTP is sent

            }
          }).catch((error) => {
            const errorMessage = error.response ? error.response.data.msg : "An error occurred while sending OTP.";

            setAlertmessage(errorMessage)
            setAlertstatus('error')
          }).finally(() => {
            setLoading(false)
          })

        } else {
          setAlertmessage('Please verify the reCAPTCHA!')

          setAlertstatus('error')
        }
      }).catch(error => {
        if (error.response && error.response.status === 404) {
          setPhoneError('Invalid Mobile Number')
        }
      })
  }

  const handleClickOtp = async () => {
    const data = {
      "phoneNumber": `+91${phoneNumber}`,
      "otp": verifyOtp,
      "email": "",
      "realm": 'parimitha',
      "organizationId": selectedBranchId
    }
    setLoading(true)
    await axios.post(`${URL.verifyOtpAppointment}`, data, {
      headers: {
        "Usertoken": `True`,
      }
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        setAlertmessage('OTP verified successfully')
        setAlertstatus('success')
        // setPatientDetails(res?.data?.entry)
        setShow(3)
        // setOtpSuccess((prev) => !prev)
      }

    }).catch((err) => {
      setAlertmessage(err.message)
      setAlertstatus('error')
    }).finally(() => {
      setLoading(false)
    })

  }

  const handleOnRequestAppointment = () => {
    if (!requestedPeriod) {
      setRequestedPeriodError('Please Select Slot'); // Corrected typo in state name
    } else {
      setRequestedPeriodError(''); // Clear the error if a slot is selected
      if (ibmId) {
        setShow(3);
      } else {
        setShow(2);
      }
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleBackCalender = () => {
    setPhoneNumber('')
    setOtpOpen(false)
    setVerifyotp('')
    setLoading(false)
    setAlertmessage('')
    setAlertstatus('')
    setShow(1)
  }


  const handleCancel = () => {
    setPhoneNumber('');
    setOtpOpen(false);
    setVerifyotp('');
    setLoading(false);
    setAlertmessage('');
    setAlertstatus('');
    setValue(dayjs());
    setSheduleSlot([]);
    setSelectedSlot('');
    setCaptchToken('')
    setRequestedPeriod('')
    setValue('');
    setRequestedPeriodError('');
    // setVisibleSlots(0);
    setVisibleSlots(initialSlot)
    setShow(1); // Navi
    handleClose()
  }

  return (
    <div>
      <CommonDialog maxWidth={show === 3 ? 'lg' : 'lg'} open={open}
      //  onClose={show === 1 ? () => setOpen(false) : () => { }}

      >
        {/* <DialogTitle sx={{ p: '5px' }}>
          <div className='text-center text-lg md:text-2xl font-medium'>Schedule Consultation Appointment</div>
          <div className='text-xs text-center font-normal text-[#E54290]'>@ {selectedbranch?.name || 'Paramitha'} </div>
        </DialogTitle> */}
        <DialogTitle sx={{ p: '5px 20px' }}>
          <div className='flex justify-between'>
            <div></div>
            <div>
              <div className='text-center text-lg md:text-2xl font-medium'>Schedule Consultation Appointment</div>
              <div className='text-xs text-center font-normal text-[#E54290]'>@ {selectedbranch?.name || 'Paramitha'} </div>
            </div>
            <div className='mr-2 cursor-pointer' onClick={handleCancel}><Close color='error' /></div>
          </div>
        </DialogTitle>
        <Divider />
        {/* <Grid > */}

        <div className='flex items-center justify-between px-3 py-1'>
          <Grid container alignItems={'center'}>
            <Grid item xs={12} md={3.5} lg={4}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>

                <div className='capitalize flex'>
                  {/* <img src={deptImg || healthServiceicon} */}
                  <img src={deptImg}

                    alt="" width={40} height={40} style={{ marginRight: "8px", height: "50px" }} />
                  <div>
                    <span className='text-sm lg:text-xl font-medium '>
                      {deptData?.name}
                    </span>
                    <br />
                    <span className='text-sm'>{comment}</span>
                  </div>
                  {/* @ Paramitha Hospitals, {selectBranchInfo?.name} */}
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={show === 1 ? 4.5 : 2.5} ></Grid>
            {(show === 2 || show === 3) && (
              <Grid item xs={12} md={4.5} lg={5.5}>
                <div className='flex items-center gap-6 mt-1 md:mt-0'>
                  <div>
                    <div className='font-medium text-medium text-xs lg:text-sm'>Appointment Date</div>
                    <Typography className='flex items-center fntsz-13'>
                      <EventAvailable sx={{ fontSize: '18px', color: "#4B4C52", mr: "5px" }} />
                      {moment(value).format('Do MMM YYYY')}
                    </Typography>
                  </div>
                  <div>
                    <div className='font-medium text-medium text-xs lg:text-sm'>Appointment Time</div>
                    <div className='font-medium text-medium flex fntsz-13 gap-2 items-center'>
                      <img src={timeIcon} width="18px" height="18px" alt="time" />
                      {console.log(timeRange, "timerange")}
                      <span>{timeRange}</span>

                      <div onClick={handleBackCalender} className='cursor-pointer'>
                        <img src={editIcon} alt="edit" width={18} height={18} />
                      </div>
                    </div>
                  </div>

                </div>
              </Grid>
            )}

            {
              show === 1 &&
              <Grid item xs={12} md={4} lg={3.5}>
                <div>
                  <div className='text-sm pr-1'>Pediatrics OP Consultation</div>
                  <div className='flex pt-1 items-center'>
                    <div className='flex'>
                      <LocationOnOutlined sx={{ fontSize: "18px", color: "#0F21B1", mr: "2px" }} />
                      <div className='text-xs lg:text-sm font-medium'>Available at</div>
                      <span className="text-xs lg:text-sm pl-2" style={{ color: "#0F4BBE" }} > {selectedbranch?.name || 'Paramitha'} </span>
                    </div>
                  </div>
                  <Typography sx={{ color: "#000000" }} className="text-xs lg:text-sm font-medium flex pt-1 items-center" >
                  </Typography>
                </div>
              </Grid>
            }
            {/* </div> */}
            {/* {console.log(selectedSlot, 431)} */}
            {/* </Grid> */}
          </Grid>
        </div>
        <Divider />
        {
          show === 1 ?
            (
              <>
                <DialogContent sx={{ p: '5px 20px' }}>
                  <Grid container columnSpacing={4} >
                    <Grid item xs={12} sm={12} md={6}>
                      <div className='font-medium text-medium'>Select Date</div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar root={{ background: 'red' }}
                          minDate={dayjs()}
                          slotProps={{
                            root: { background: 'red' },

                            calendarHeader: {
                              sx: {
                                position: "relative",
                                '& .MuiPickersArrowSwitcher-root': {
                                  width: 0
                                },
                                "& .MuiPickersCalendarHeader-labelContainer": {
                                  margin: "auto"
                                },
                                "& .MuiIconButton-edgeEnd": {
                                  position: "absolute",
                                  left: 0, top: 0, bottom: 0
                                },
                                "& .MuiIconButton-edgeStart": {
                                  position: "absolute",
                                  right: 0, top: 0, bottom: 0
                                }
                              }
                            },
                            leftArrowIcon: {
                              sx: { color: "#606060", fontSize: "2rem" }
                            },
                            rightArrowIcon: {
                              sx: { color: "#606060", fontSize: "2rem" }
                            },
                          }}
                          sx={{ '.muidatecalendar-root': { margin: 0, background: 'red' } }}
                          value={value && dayjs(value).isValid() ? dayjs(value) : null}
                          onChange={handleAppointment} />
                      </LocalizationProvider>
                    </Grid>
                    {/* {console.log(value, 41)} */}
                    {/* {value && */}
                    <Grid item xs={12} sm={12} md={6}>
                      <div className='font-medium text-medium'>Select Slot</div>
                      <div style={{ color: '#606060', fontSize: '15px', marginTop: '10px' }}>Available Slots for  {value ? `${moment(value).format('Do MMMM YYYY,dddd')}` : `Today`} </div>
                      <Box sx={{
                        overflowY: 'scroll', maxHeight: 'calc(100vh - 400px)', '&::-webkit-scrollbar': { display: 'none' }
                      }}>
                        {loading ? (
                          <div className="flex justify-center items-center mt-10">
                            <CircularProgress />
                          </div>
                        ) : (
                          <div className="flex flex-wrap">
                            <Grid item xs={12} sm={12} md={6} >

                              {/* <div className='mt-1'>No slots available</div> */}
                              <div className='text-sm mt-1 '>Date & Time Choice for consultation</div>
                              <Select
                                displayEmpty
                                fullWidth
                                sx={{ '.MuiSelect-select': { padding: '10px' }, mt: "10px", borderRadius: '7px' }}
                                name="requestedPeriod"
                                disabled={!value} // Enable or disable dynamically

                                value={requestedPeriod}
                                onChange={handleChangeRequest}
                                onClick={() => {
                                  if (!value) {
                                    setRequestedPeriodError("Please Select Date")
                                  }
                                }}
                                placeholder='Select - Slot'
                                renderValue={(selected) => {
                                  if (!selected) {
                                    return <span style={{ color: '#636363' }} className='text-sm'>Select - Slot</span>;
                                  }
                                  return selected;
                                }}
                              // MenuProps={{ style: { zIndex: 999999 } }}
                              >
                                <MenuItem value={'Morning'}>Morning</MenuItem>
                                <MenuItem value={'AfterNoon'}>AfterNoon</MenuItem>
                                <MenuItem value={'Evening'}>Evening</MenuItem>
                                <MenuItem value={'Night'}>Night</MenuItem>
                              </Select>
                              {requestedPeriodError && <Typography sx={{ fontSize: '13px', color: '#D32F2F', mt: 1 }}>{requestedPeriodError}</Typography>}
                            </Grid>


                          </div>
                        )}
                      </Box>
                    </Grid>

                  </Grid>
                  <Grid container justifyContent={"flex-end"}>
                  </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                  <Grid container justifyContent={"space-between"} px={3}>
                    <div></div>
                    {/* <Button
                      color='error' sx={{ textTransform: 'capitalize' }} onClick={() => {
                        setValue(dayjs());
                        setSheduleSlot([]);
                        setSelectedSlot('');
                        setVisibleSlots(initialSlot)
                        setRequestedPeriod('')
                        setRequestedPeriodError('')
                        handleClose()
                      }} >Cancel</Button> */}
                    <div className='flex gap-2'>
                      <Button variant="contained" className='text-lg' sx={{ color: '#fff', background: '#205072', textTransform: 'capitalize', "&:hover": { background: '#205072' } }} onClick={handleOnRequestAppointment}>Request Appointment</Button>
                    </div>
                  </Grid>
                </DialogActions>
              </>
            )
            : show === 2 ?
              (
                <>
                  <DialogContent sx={{ p: '20px 40px' }}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={6} lg={6}>
                        <Box >
                          <Typography sx={{ fontSize: 16, fontWeight: 500 }} >Register with Phone Number</Typography>
                        </Box>
                        <Box sx={{ mt: 1 }}>
                          {/* <CustomTextField
                                                        sx={{ ".MuiInputBase-root": { paddingRight: "0px !important" } }}
                                                        placeholder="Enter Your Phone Number"
                                                        type={'text'}
                                                        borderColor="#707070"
                                                        borderRadius="7px"
                                                        width="100%"
                                                        padding="0px"
                                                        // borderColor="#707070"

                                                        // icon={!otpOpen && phoneNumber.length === 10 ?
                                                        //     <ArrowForwardIcon sx={{ backgroundColor: "#1B5775", fontSize: '40px', color: "white", borderRadius: "0px 7px 7px 0px", }}
                                                        //         onClick={handleSendOtp} /> : null}
                                                        value={phoneNumber}
                                                        onChange={handleChangePhoneNumber}
                                                    /> */}
                          <TextField
                            fullWidth
                            size="small"
                            type={'text'}
                            value={phoneNumber}
                            onChange={handleChangePhoneNumber}
                            // onBlur={handleBlur('phone')}

                            placeholder="Enter Phone Number"
                            sx={{
                              width: '100%',
                              ".MuiInputBase-root": {
                                borderRadius: '7px',
                                // backgroundColor: "rgb(243, 244, 246)"
                              },
                              '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                  borderColor: '#59D8FA',
                                },
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  +91
                                </InputAdornment>
                              )
                            }}

                          />
                          {phoneError && (
                            <Typography sx={{ color: '#D32F2F', fontSize: '14px' }}>
                              {phoneError}
                            </Typography>
                          )}
                        </Box>
                        {!otpOpen &&
                          <Box sx={{ mt: 4, pl: "0px", transform: 'scale(0.8)', transformOrigin: '0 0' }}>

                            <ReCAPTCHA
                              sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                              onChange={handleRecaptchaChange}
                              size="normal"
                            // style={{ transform: 'scale(0.9)' }}
                            />
                          </Box>
                        }

                        {otpOpen &&
                          <>
                            <Box sx={{ mt: 3 }}>
                              <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16 }, fontWeight: 500 }}>Verify OTP</Typography>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                              <CustomTextField placeholder="Enter OTP Here" type={'text'} borderColor="#59D8FA" borderRadius="7px" value={verifyOtp} onChange={handleChangeOtp} />
                              <div className='flex align-center justify-between mt-1'>
                                <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>{countdown > 0 ? `${countdown} sec` : "Time's up"}</Typography>
                                <Typography component='button' sx={{ borderRadius: '4px', fontSize: '13px', fontWeight: 500 }} onClick={handleSendOtp} disabled={countdown > 0}>Resend OTP</Typography>
                              </div>
                            </Box>

                            {/* <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
                                                            <div></div>
                                                            <div>
                                                                <OutlinedButton
                                                                    width="140px"
                                                                    height="32px"
                                                                    borderColor="#1B5775"
                                                                    color="#1B5775"
                                                                    borderRadius="7px"
                                                                    text="Verify OTP"
                                                                    onClick={handleClickOtp} // onClick event handler
                                                                    disabled={!verifyOtp}
                                                                    startIcon={null}
                                                                    endIcon={loading ? <CircularProgress size={20} color="inherit" /> : null} />
                                                            </div>
                                                        </Box> */}
                          </>
                        }
                      </Grid>
                      <Grid item md={1} lg={2}></Grid>
                      <Grid item md={5} lg={4} mt={2} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        <div>
                          <div className='text-sm pr-1'>Pediatrics OP Consultation</div>
                          <Typography sx={{ color: "#000000", fontSize: "14px", }} className="text-sm font-medium flex pt-1 items-center" >
                            <LocationOnOutlined sx={{ fontSize: "18px", color: "#0F21B1", mr: "2px" }} />
                            Available at <span className="text-sm pl-2" style={{ color: "#0F4BBE" }} > {selectedbranch?.name || 'Paramitha'} </span>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                    <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />

                  </DialogContent>
                  <Divider />
                  <DialogActions>
                    <Grid container justifyContent={"space-between"} px={3}>
                      <Button
                        color='error' sx={{ textTransform: 'capitalize' }} onClick={handleCancel} >Cancel</Button>
                      <Button type="submit" variant="contained" sx={{ width: "150px", textTransform: 'capitalize', bgcolor: '#1B5775', "&:hover": { bgcolor: '#1B5775' }, height: "33px", borderRadius: '7px' }} onClick={otpOpen ? handleClickOtp : handleSendOtp} disabled={otpOpen ? !verifyOtp : phoneNumber.length < 10}>
                        {/* Submit */}{otpOpen ? 'Verify OTP' : 'Send OTP'}
                      </Button>
                    </Grid>
                  </DialogActions>

                </>
              )
              : show === 3 ?
                (
                  <HealthCareAppointment phoneNumber={phoneNumber} startDate={startDate} endDate={endDate} id={id} locationId={locationId} handleCancel={handleCancel} handleClose={handleClose} setShow={setShow} value={value} serviceData={serviceData} deptData={deptData} />
                )
                : null
        }

      </CommonDialog>

    </div >
  )
}

export default HealthCareRequestPopup;