import React, { useEffect, useState } from 'react';
import CommonDialog from '../../atoms/commonModal';
import { Autocomplete, Box, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTextField } from '../../atoms/TextField';
import ContainedButton, { OutlinedButton } from '../../atoms/commonbutton';
import { localstore } from '../../localstore/localstore';
import URL from '../../../services/api_url';
import { getDistricts, getPatientdatafromibm, getPatientIdDetails, getStates, getSubDistricts } from '../../../redux/actions/actions';
import ErrorStatusMsg from '../../atoms/ErrorStatusMsg';
import axios from 'axios';
import { lang } from 'moment';
import BranchUrl from '../../../services/api_branch_url';

function AddressUpdate({ open, setEditAddress, selectedbranch, patientDetails }) {
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertStatus, setAlertStatus] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [language, setLanguage] = useState([])

    const ibmId = localstore.getPatientIbmId();
    const userToken = localstore.getToken();
    const dispatch = useDispatch();
    const urls = BranchUrl()
    const states = useSelector(state => state.paramitha.states);
    const districts = useSelector(state => state.paramitha.districts);
    const subdistricts = useSelector(state => state.paramitha.subdistricts);

    const phoneNumber = patientDetails?.telecom?.[0]?.value;
    const email = patientDetails?.telecom?.[1]?.value;

    const handleClose = () => {
        setEditAddress(false);
        setAlertMessage('')
    };

    const handleChangeState = (e, newValue) => {
        if (newValue) {
            setState(newValue);
            dispatch(getDistricts(userToken, newValue));
        }
    };

    const handleChangeDistrict = (e, newValue) => {
        if (newValue) {
            setDistrict(newValue);
            dispatch(getSubDistricts(userToken, newValue.toUpperCase()));
        }
    };

    const handleChangeCity = (e, newValue) => {
        setCity(newValue);
    };

    const handleChangePincode = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setPincode(inputValue.slice(0, 6));
    };

    useEffect(() => {
        dispatch(getStates(userToken));
    }, []);
    useEffect(() => {
        if (patientDetails) {
            setAddress1(patientDetails?.address?.[0]?.line[0] || '');
            setAddress2(patientDetails?.address?.[0]?.line[1] || '');
            setPincode(patientDetails?.address?.[0]?.postalCode || '');
            const stateName = patientDetails?.address?.[0]?.state;
            const cityName = patientDetails?.address?.[0]?.city;
            const districtName = patientDetails?.address?.[0]?.district;
            const communication = patientDetails?.communication?.map(options => options?.language?.coding?.[0])
            setLanguage(communication)
            setState(stateName || '');
            setDistrict(districtName || '');
            setCity(cityName || '');

            if (stateName) {
                dispatch(getDistricts(userToken, stateName));
            }
        }
    }, [patientDetails]);
    const handleSave = async () => {
        const data = {
            ...patientDetails, "address": [
                {
                    "use": "home",
                    "type": "both",
                    "line": [address1, address2],
                    "city": city,
                    "district": district,
                    "state": state,
                    "postalCode": pincode,
                }
            ],
            // "resourceType": "Patient",
            // "id": ibmId,
            // "active": true,
            // "address": [
            //     {
            //         "use": "home",
            //         "type": "both",
            //         "line": [address1, address2],
            //         "city": city,
            //         "district": district,
            //         "state": state,
            //         "postalCode": pincode,
            //     }
            // ],
            // "identifier": patientDetails?.identifier ? [
            //     {
            //         "system": patientDetails?.identifier?.[0]?.system,
            //         "value": patientDetails?.identifier?.[0]?.value
            //     }
            // ] : [],
            // "name": patientDetails?.name ? [
            //     {
            //         "use": "official",
            //         "family": patientDetails?.name?.[0]?.family,
            //         "text": patientDetails?.name?.[0]?.given[0],
            //         "given": [patientDetails?.name?.[0]?.given[0]]
            //     }
            // ] : [],
            // "telecom": [
            //     {
            //         "system": "phone",
            //         "value": phoneNumber,
            //         "use": "work"
            //     },
            //     // {
            //     //     "system": "email",
            //     //     "value": email,
            //     //     "use": "work"
            //     // }
            // ],
            // "gender": patientDetails?.gender,
            // "birthDate": patientDetails?.birthDate,
            // "managingOrganization": {
            //     "reference": `Organization/${selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`}`

            // },
            // "contact": patientDetails?.contact ?
            //     [
            //         {
            //             "relationship": [
            //                 {
            //                     "coding": [
            //                         {
            //                             "system": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[0]?.system,
            //                             "code": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[0]?.code
            //                         },
            //                         {
            //                             "system": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[1]?.system,
            //                             "code": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[1]?.code,
            //                             "display": patientDetails?.contact?.[0]?.relationship?.[0]?.coding?.[1]?.display
            //                         },
            //                     ]
            //                 }
            //             ],
            //             "name": {
            //                 "family": patientDetails?.contact?.[0]?.name?.family,
            //                 "_family": {
            //                     "extension": [
            //                         {
            //                             "url": "http://hl7.org/fhir/StructureDefinition/humanname-own-prefix",
            //                             "valueString": "VV"
            //                         }
            //                     ]
            //                 },
            //                 "given": patientDetails?.contact?.[0]?.name?.given
            //             },
            //             "telecom": [
            //                 {
            //                     "system": "phone",
            //                     "value": patientDetails?.contact?.[0]?.telecom?.[0].value || ''
            //                 },
            //                 {
            //                     "system": "email",
            //                     "value": patientDetails?.contact?.[0]?.telecom?.[1].value || ''
            //                 }
            //             ],
            //             "address": {
            //                 "use": "home",
            //                 "type": "both",
            //                 "line": [
            //                     patientDetails?.contact?.[0]?.address?.line[0] || '', patientDetails?.contact?.[0]?.address?.line[1] || ''
            //                 ],
            //                 "city": patientDetails?.contact?.[0]?.address?.city,
            //                 "district": patientDetails?.contact?.[0]?.address?.district,
            //                 "state": patientDetails?.contact?.[0]?.address?.state,
            //                 "postalCode": patientDetails?.contact?.[0]?.address?.postalCode || '',
            //             },
            //             "gender": patientDetails?.contact?.[0]?.gender,
            //         }
            //     ] : [],
            // "communication": language ? language?.map(options => {
            //     return {
            //         'language': {
            //             "coding": [
            //                 {
            //                     "system": "urn:ietf:bcp:47",
            //                     "code": options.code,
            //                     "display": options.display
            //                 }
            //             ],
            //         }
            //     }
            // }) : []
        };

        try {
            setLoading(true);
            const res = await axios.put(`${urls.person_tenant_orgId}Patient/${ibmId}`, data, {
                headers: {
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha`,
                    'realm': 'parimitha',
                    'Authorization': `Bearer ${userToken}`
                }
            });
            setAlertMessage('Updated successfully');
            setAlertStatus('success');
            dispatch(getPatientIdDetails(urls, userToken, ibmId));
            handleClose();
        } catch (error) {
            setAlertMessage(error.message);
            setAlertStatus('error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <CommonDialog open={open} maxWidth="md" onClose={handleClose} sx={{ '.MuiDialog-paper': { top: { xs: 0, sm: 0, md: -26 } } }}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>Address</Typography>
                    <IconButton onClick={handleClose}><Close sx={{ color: 'red' }} /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{
                    // overflowY: 'scroll', height: { xs: '62vh', sm: '61vh', md: '47vh' }, '&::-webkit-scrollbar': {
                    //     display: 'none',
                    // }
                }}>
                    <div className='px-2'  >
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Address Line1</label>
                                    </Box>
                                    <CustomTextField
                                        placeholder="Enter your Address Line1"
                                        type='text'
                                        borderColor="#59D8FA"
                                        borderRadius="7px"
                                        value={address1}
                                        onChange={(e) => setAddress1(e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Address Line2</label>
                                    </Box>
                                    <CustomTextField
                                        placeholder="Enter your Address Line2"
                                        type='text'
                                        borderColor="#59D8FA"
                                        borderRadius="7px"
                                        value={address2}
                                        onChange={(e) => setAddress2(e.target.value)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>Select State</label>
                                </Box>
                                <Autocomplete
                                    id="state-select"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#59D8FA',
                                            },
                                        },
                                        ".MuiInputBase-root": {
                                            borderRadius: '7px',
                                        },
                                    }}
                                    options={states}
                                    size='small'
                                    autoHighlight
                                    value={state}
                                    onChange={handleChangeState}
                                    fullWidth
                                    getOptionLabel={(option) => option}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ textTransform: 'capitalize' }} {...props}>
                                            {option}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder='Select State'
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>Select District</label>
                                </Box>
                                <Autocomplete
                                    id="district-select"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#59D8FA',
                                            },
                                        },
                                        ".MuiInputBase-root": {
                                            borderRadius: '7px',
                                        },
                                    }}
                                    options={districts}
                                    size='small'
                                    autoHighlight
                                    value={district}
                                    onChange={handleChangeDistrict}
                                    fullWidth
                                    getOptionLabel={(option) => option}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                            {option}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder='Select District'
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>Select City/Sub-District</label>
                                </Box>
                                <Autocomplete
                                    id="city-select"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#59D8FA',
                                            },
                                        },
                                        ".MuiInputBase-root": {
                                            borderRadius: '7px',
                                        },
                                    }}
                                    options={subdistricts}
                                    size='small'
                                    autoHighlight
                                    value={city}
                                    onChange={handleChangeCity}
                                    fullWidth
                                    getOptionLabel={(option) => option}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props}>
                                            {option}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder='Select City/Sub-District'
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box>
                                    <label style={{ fontWeight: 500, fontSize: '14px' }}>PinCode</label>
                                </Box>
                                <CustomTextField
                                    placeholder="Enter Pincode"
                                    type='text'
                                    borderColor="#59D8FA"
                                    borderRadius="7px"
                                    value={pincode}
                                    onChange={handleChangePincode}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <Divider />
                <div className='text-center'>
                    <ErrorStatusMsg alertMessage={alertMessage} alertStatus={alertStatus} />
                </div>
                <DialogActions sx={{ m: "5px 20px" }}>
                    <div>
                        <OutlinedButton
                            text="Save Changes"
                            borderColor="#1B5775"
                            color="#1B5775"
                            borderRadius="7px"
                            disabled={!address1 || !address2 || !pincode}
                            endIcon={loading ? <CircularProgress size={20} color='inherit' /> : null}
                            onClick={handleSave}
                        />
                    </div>
                </DialogActions>
            </CommonDialog>
        </div >
    );
}

export default AddressUpdate;
