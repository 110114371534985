import { combineReducers } from "@reduxjs/toolkit";
import branchReducer from "./slices/branchSlice";
import SelectedBranchReducer from "./slices/SelectedBranchSlice";
import loginReducer from "./reducers/loginReducer";
import paramithaReducer from "./reducers/reducer";

const rootReducer = combineReducers({
  branch: branchReducer,
  SelectedBranch: SelectedBranchReducer,
  login: loginReducer,
  paramitha: paramithaReducer

});
export default rootReducer;