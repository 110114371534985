import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import followIcon from '../../assets/images/Follow.svg';
import { ShareOutlined } from '@mui/icons-material';
import { OutlinedButton } from '../atoms/commonbutton';
// import PostPublications from './PostPublications';
import URL from '../../services/api_url';
import axios from 'axios';
import DepartmentOverview from './departmentOverview';
import healthDeparticon from '../../assets/healthcareprograms/Health Department 2.svg';
import '../healthcare_services/Navbar.css'
import PractitionerGroups from './practitionerGroups';
import HealthServiceCare from './healthServiceCare';
import BranchUrl from '../../services/api_branch_url';
import PostAndArticle from './postAndArticle';
import PatientEducation from './patientEducation';
import MediaGallery from './mediaGallery';
import LocationInstances from './locationInstances';
import FrequentlyAsked from './frequentlyAsked';
import DepartContacts from './departContacts';
import SocialMediaShare from '../atoms/socialMediaShare';
import DoctorUnfollow from '../doctors/doctorUnfollow';
import { localstore } from '../localstore/localstore';
import NoAccess from '../atoms/NoAccess';
import RepresentNoDetailsPage from '../representNoDetailsPage';

const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
const daysCompare = { mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6, sun: 7 };


// const ContactInfo = ({ contactDetails, system }) => {
//     console.log(contactDetails)
//     const phoneInfo = contactDetails?.find(info => info.system === system);
//     return (
//         <div>{phoneInfo ? (<p className='text-sm' style={{ color: '#0A0AC9' }}>{phoneInfo.value}</p>) : '-------'}</div>
//     );
// };

const Healthcaredepart = () => {
    const [value, setValue] = React.useState(0);
    const [deptData, setDeptData] = React.useState({});
    const [isshedulePage, setIsShedulePage] = React.useState(false);
    const [practitioner, setPractitioner] = useState([])
    const [locationInfo, setLocationInfo] = useState([])

    const [showStickyHeader, setShowStickyHeader] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [beingFollowed, setBeingFollowed] = useState()
    const [isFollowing, setIsFollowing] = useState();
    const [followData, setFollowData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [ind, setInd] = useState('')
    const [modal, setModal] = useState(false)
    const usertoken = localstore.getToken()
    const { id } = useParams()

    const [openShare, setOpenShare] = useState(false)
    const [openMessage, setOpenMessage] = useState(false)
    const [activeSection, setActiveSection] = useState('overview');
    const [timings, setTimings] = useState({});
    const [departTabs, setDepartTabs] = useState({})
    const [deptImg, setDeptImg] = useState();
    const [activeDay, setActiveDay] = useState(null);
    const [WorkingDays, setWorkingDays] = useState([]);
    const [healthCareSerivces, setHealthCareServices] = useState([]);
    const branchName = useSelector((state) => state.branch.branch);
    const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
    const baseurl = BranchUrl()

    useEffect(() => {
        const handleScroll = () => {
            // Check if the scroll position is greater than or equal to 48vh
            // console.log(window.scrollY >= window.innerHeight * 0.48)
            if (window?.scrollY >= window?.innerHeight * 0.48) {
                setShowStickyHeader(true);
            } else {
                setShowStickyHeader(false);
            }
        };
        // Add the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        if (id) {
            axios.get(`${baseurl.fhir_tenant_orgId}Organization/${id}`, {
                headers: {
                    'Realm': 'parimitha',
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': 'fhirparimitha',
                    'Cache-Control': 'no-cache',
                }
            }).then((res) => {
                setDeptData(res?.data)
                // const imgSrc = res?.data?.identifier?.filter((item) => item?.system === 'https://dev.api.docsteth.com/api/api/v1/logo')
                // const imgSrc = res?.data?.identifier?.[0]?.value
                const imgSrc = res?.data?.identifier?.filter((val) => val?.system === `${process.env.REACT_APP_API_Docsteth}logo`)?.[0]?.value;                                        // const imgSrc = val?.resource?.identifier?.[0]?.value

                // const isValidImage = typeof imgSrc === 'string' && imgSrc.trim() !== '' && isNaN(imgSrc);
                // setValidImage(isValidImage)
                // console.log(imgSrc, 112)
                setDeptImg(imgSrc)
                // const locationId = res?.data?.location?.[0]?.reference?.replace("Location/", "")
                // if (locationId) {
                //     axios.get(`${ URL.getServicePractitioner } ? location = ${ locationId }`, {
                //         headers: {
                //             'X-FHIR-TENANT-ID': 'parimitha' || 'parimitha',
                //             'X-FHIR-DSID': `fhir${ selectBranchInfo?.tenant || 'parimitha'} `,
                //             'realm': `${ selectBranchInfo?.tenant || 'parimitha' } `,
                //         }
                //     }).then((res) => {
                //         console.log(res)
                //         // setDeptData(res?.data)
                //         setPractitioner(res?.data?.entry || [])

                //     }).catch((err) => console.log(err))
                // }
            }).catch((err) => console.log(err))
        }
    }, [])

    console.log(deptData, "dept")

    useEffect(() => {
        if (id) {
            axios.get(`${baseurl.fhir_tenant}${id}/Location`, {
                headers: {
                    'Realm': 'parimitha',
                    'X-FHIR-TENANT-ID': 'parimitha',
                    'X-FHIR-DSID': `fhirparimitha `
                }
            }).then((res) => {
                // console.log(res?.data?.entry?.[0])
                setLocationInfo(res?.data?.entry?.[0])
                let timeinfo = res?.data?.entry?.[0]?.resource
                // console.log(224, timeinfo)
                if (timeinfo) {
                    let data = [];
                    const date = new Date();
                    const today = days[date.getDay() - 1];
                    {
                        timeinfo?.hoursOfOperation?.map((item, i) => {
                            let openingTime = item.openingTime;
                            let closingTime = item.closingTime;
                            if (item.allDay == true) {
                                item?.daysOfWeek?.map((child) => {
                                    data.push({
                                        day: child,
                                        allDay: item.allDay,
                                        today: child == today ? true : false,
                                    });
                                });
                            } else if (item.allDay == false) {
                                item.daysOfWeek?.map((child) => {
                                    data.push({
                                        day: child,
                                        allDay: item.allDay,
                                        today: child == today ? true : false,
                                    });
                                });
                            } else {
                                item.daysOfWeek.map((child) => {
                                    data.push({
                                        day: child,
                                        workingHours: [
                                            { openingTime: openingTime, closingTime: closingTime },
                                        ],
                                        today: child == today ? true : false,
                                    });
                                });
                            }
                        });
                    }

                    const mergedSchedule = [];

                    for (let i = 0; i < data.length; i++) {
                        let day = data[i].day;
                        let hours = data[i].workingHours;

                        let existingDay = mergedSchedule.find((item) => item.day === day);
                        // console.log(existingDay, "existingDay")
                        if (existingDay) {
                            existingDay.workingHours = existingDay.workingHours.concat(hours);
                            // console.log(existingDay, "existingDay11")
                        } else {
                            mergedSchedule.push(data[i]);
                        }
                    }
                    // console.log(mergedSchedule, "mergedSchedule,258");

                    const sorted = mergedSchedule.sort((a, b) => {
                        return daysCompare[a.day] - daysCompare[b.day];
                    });
                    // console.log(sorted, "sorted");
                    setWorkingDays(sorted);
                }

            }).catch((err) => console.log(err))
        }
    }, [id])


    useEffect(() => {
        if (id) {
            axios.get(`${URL.docsteth}getcomponentsByOrgId/${id}`)
                .then(res => {
                    setDepartTabs(res?.data)
                }).catch(error => {
                    console.log(error)
                })
        }
    }, [id])
    // useEffect(() => {
    //     if (deptData && deptData?.identifier?.[0]?.value) {
    //         axios.get(`${ URL.docstethDocterData }/${deptData?.identifier?.[0]?.value}`).then((res) => {
    //             // console.log(res?.data?.entry)
    //             setDoctorData(res?.data)

    //         }).catch((err) => console.log(err))

    //     }
    // }, [practitioner])

    useEffect(() => {
        if (locationInfo?.resource?.id) {
            Promise.all([
                axios.get(`${baseurl.fhir_tenant_orgId}HealthcareService?coverage-area=${locationInfo?.resource?.id}`, {
                    headers: {
                        'X-FHIR-TENANT-ID': 'parimitha',
                        'X-FHIR-DSID': `fhirparimitha`,
                        'realm': 'parimitha',
                        'Cache-Control': 'no-cache',
                    }
                }),
                axios.get(`${baseurl.fhir_tenant_orgId}HealthcareService?location=${locationInfo?.resource?.id}`, {
                    headers: {
                        'X-FHIR-TENANT-ID': 'parimitha',
                        'X-FHIR-DSID': `fhirparimitha`,
                        'realm': 'parimitha',
                        'Cache-Control': 'no-cache',
                    }
                })
            ]).then(([coverageAreaRes, locationRes]) => {
                const combinedServices = [
                    ...(coverageAreaRes?.data?.entry || []),
                    ...(locationRes?.data?.entry || [])
                ];
                setHealthCareServices(combinedServices);
            }).catch((err) => console.log(err));
        }
    }, [locationInfo, selectBranchInfo]);



    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // const appointmentBooking = () => {
    //     if (branchName) {
    //         setIsShedulePage(true)
    //     } else {
    //         // setOpenSnack(true);
    //         dispatch({
    //             type: 'SELECT_FACILITY_BRANCH',
    //             payload: true
    //         })

    //     }
    // }


    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const schedulePcgFn = () => {
        setIsShedulePage((prev) => !prev)
    };

    useEffect(() => {
        const date = new Date();
        const today = days[date.getDay() - 1];
        WorkingDays?.find((item) => {
            if (item.day == today) {
                setTimings(item);
            }
        });
    }, [WorkingDays]);

    // const scrolldiv = (index, val) => {
    //     const elem = document.getElementById(val);
    //     window.scrollTo({ top: 10, behavior: 'smooth' });
    //     const top = elem?.offsetTop;
    //     if (elem) {
    //         document.getElementById('main').scroll({
    //             top: top - 300,
    //             behavior: 'smooth',
    //         });
    //     }
    //     if (val === 'overview') {
    //         setActiveSection('overview');
    //         setActiveIndex(-1);
    //     } else {
    //         setActiveSection(val);
    //         setActiveIndex(index);
    //     }
    // };
    const scrolldiv = (index, val) => {
        const elem = document.getElementById(val);
        const container = document.getElementById('main');
        if (elem && container) {
            const top = elem.offsetTop - container.offsetTop;
            container.scrollTo({
                top: top,
                behavior: 'smooth',
            });
        }
        if (val === 'overview') {
            setActiveSection('overview');
            setActiveIndex(-1);
        } else {
            setActiveSection(val);
            setActiveIndex(index);
        }
    };

    useEffect(() => {
        const getLocationService = (val) => {
            const locationId = locationInfo?.resource?.id
            if (locationId) {
                axios.get(`${baseurl.fhir_tenant_orgId}PractitionerRole?location=${locationId}`, {
                    headers: {
                        'X-FHIR-TENANT-ID': 'parimitha',
                        'X-FHIR-DSID': `fhirparimitha`,
                        'realm': 'parimitha',
                    }
                }).then((res) => {
                    // setDeptData(res?.data)
                    setPractitioner(res?.data?.entry || [])

                }).catch((err) => console.log(err))
            }
            // setDeptData(res?.data)
            // (res?.data || [])
        }
        getLocationService()
    }, [locationInfo?.resource?.id])

    const handleGetFollowing = () => {
        axios.get(`${URL.publish}getfollowing?pageId=${id}`, {
            headers: {
                "Authorization": `Bearer ${usertoken}`
            }
        }).then(res => {
            setFollowData(res?.data)
            setBeingFollowed(res?.data?.follower)
        })
    }

    useEffect(() => {
        if (usertoken) {
            handleGetFollowing()
        }
    }, [])

    useEffect(() => {
        setIsFollowing(beingFollowed)
    }, [beingFollowed])

    const handleFollow = (index) => {
        if (usertoken) {
            setInd(index)
            setLoading(true);

            const data = {
                "pageId": id
            };
            axios.post(`${URL.publish}follow`, data, {
                headers: {
                    "Authorization": `Bearer ${usertoken}`
                }
            })
                .then((res) => {
                    // Handle success response here
                    handleGetFollowing()

                    console.log("Follow successful:", res.data);
                })
                .catch((err) => {
                    // Handle error here
                    console.error("Error following:", err);
                }).finally(() => {
                    setLoading(false);
                    setInd('');
                });
        } else {
            // alert("Please login and follow");
            setOpenMessage(true)
        }
    };


    const handleUnFollowDoc = () => {
        setLoading(true)
        axios.delete(`${URL.publish}unfollow/${followData?.id}`, {
            headers: {
                "Authorization": `Bearer ${usertoken}`
            }
        }).then((res) => {
            handleGetFollowing()
            setModal(false)
        }).catch((err) => {
            // Handle error here
            console.error("Error following:", err);
        }).finally(() => {
            setLoading(false);
        });
        setIsFollowing(!isFollowing);
    }

    const contentList = [
        <DepartmentOverview deptData={deptData} locationInfo={locationInfo} />,
        ...(departTabs?.hashcomponents?.map((component) => {
            switch (component?.name) {
                case 'Practitioners Groups':
                    return <PractitionerGroups practitioner={practitioner} />;
                case 'HealthCare Services':
                    return <HealthServiceCare healthCareSerivces={healthCareSerivces} selectBranchInfo={selectBranchInfo} />;

                case 'Posts & Articles':
                    return <PostAndArticle healthCareSerivces={healthCareSerivces} selectBranchInfo={selectBranchInfo} />;
                case 'Patient Education':
                    return <PatientEducation healthCareSerivces={healthCareSerivces} selectBranchInfo={selectBranchInfo} />;

                case 'Media Gallery':
                    return <MediaGallery healthCareSerivces={healthCareSerivces} selectBranchInfo={selectBranchInfo} locationInfo={locationInfo} />;
                case 'Location Instances':
                    return <LocationInstances healthCareSerivces={healthCareSerivces} selectBranchInfo={selectBranchInfo} />;

                case 'Frequently Asked Question':
                    return <FrequentlyAsked healthCareSerivces={healthCareSerivces} selectBranchInfo={selectBranchInfo} id={id} />;

                default:
                    return null;
            }
        }) || []),
    ];

    const title = deptData?.name

    const url = `https://www.paramithahospitals.in/${branchName ? `${branchName?.replace(/[\s,]+/g, '%20')}/healthcaredept/${title?.replace(/[\s,]+/g, '')}/${id}` : `/healthcaredept/${title?.replace(/[\s,]+/g, '')}/${id}`}`;
    // if (departTabs) {
    //     contentList[8] = <DepartContacts timings={timings} setTimings={setTimings} activeDay={activeDay} setActiveDay={setActiveDay} WorkingDays={WorkingDays} appointmentBooking={appointmentBooking} deptData={deptData} />;
    // } else {
    //     contentList[1] = <DepartContacts timings={timings} setTimings={setTimings} activeDay={activeDay} setActiveDay={setActiveDay} WorkingDays={WorkingDays} appointmentBooking={appointmentBooking} deptData={deptData} />;
    // }
    // const contactorsIndex = contentList?.length;
    // contentList?.push(
    //     <DepartContacts timings={timings} setTimings={setTimings} activeDay={activeDay} setActiveDay={setActiveDay} WorkingDays={WorkingDays} appointmentBooking={appointmentBooking} deptData={deptData} />
    // )
    // console.log(deptData, "depart")
    return (
        <Box>
            {
                deptData?.id ?
                    <>
                        <div style={{
                            position: 'sticky',
                            top: 64,
                            zIndex: 10,
                        }}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} md={12} >
                                    {/* <Box style={{ marginTop: -130 }}> */}
                                    <Box>
                                        <Card className='px-4 pt-3' sx={{ p: { sm: '10px 40px 0px 40px', md: '20px 80px 0px 80px', lg: '20px 100px 0px 100px', xl: '20px 125px 0px 125px' } }}>

                                            <div className='flex   flex-col md:flex-row justify-between '>
                                                <div className='capitalize flex'>
                                                    {/* <img src={deptImg || healthServiceicon} */}
                                                    <img src={deptImg || healthDeparticon}

                                                        alt="healthdepart" width={40} height={40} style={{ marginRight: "8px", height: "50px" }} />
                                                    <div>
                                                        <span className='text-xl md:text-2xl font-medium '>
                                                            {deptData?.name}
                                                        </span>
                                                        <br />
                                                        <span className='text-sm'>{deptData?.alias?.[0]}</span>
                                                    </div>
                                                    {/* @ Paramitha Hospitals, {selectBranchInfo?.name} */}
                                                </div>

                                                <div className='flex gap-5 items-center justify-end mt-1 md:m-0'>
                                                    <Button
                                                        variant="outlined"
                                                        startIcon={<img src={followIcon} width={20} height={20} alt="tele" />}
                                                        sx={{
                                                            height: "30px",
                                                            fontSize: { xs: "14px", lg: "16px" },
                                                            // justifyContent: 'space-between',
                                                            // padding:'5px',
                                                            textTransform: "capitalize",
                                                            borderRadius: "10px",
                                                            borderColor: "#205072",
                                                            color: "#205072",
                                                            "&:hover": { borderColor: "#205072" },
                                                        }}
                                                        onClick={() => {
                                                            if (loading === false) {
                                                                if (isFollowing) {
                                                                    setModal(true)
                                                                } else {
                                                                    handleFollow(ind);
                                                                }
                                                                // setIsFollowing(!isFollowing);
                                                            } else {
                                                                return;
                                                            }
                                                        }}

                                                    >
                                                        {isFollowing ? "following" : "follow"}
                                                    </Button>
                                                    <OutlinedButton text="Refer" borderRadius="10px" borderColor="#205072" color="#205072" width="110px" height="30px" fontSize={{ xs: "14px", lg: "16px" }} startIcon={<ShareOutlined sx={{ fontSize: 12 }} />} onClick={() => setOpenShare(true)} />
                                                </div>
                                            </div>

                                            <hr style={{ marginTop: '10px' }} />


                                            <Box sx={{ width: '100%' }}>
                                                <nav className="navbar">
                                                    <div className="nav-menu-container">
                                                        <ul className="nav-menu">
                                                            {/* <li className={`nav - item cursor - pointer ${ activeSection === "overview" ? "active" : "pt-2.5" } `} onClick={() => { scrolldiv(0, 'overview') }}> */}
                                                            <li
                                                                className={`nav-item cursor-pointer ${activeSection === 'overview' ? 'active' : 'pt-2.5'} `}
                                                                onClick={() => { scrolldiv(0, 'overview') }}
                                                            >
                                                                Overview
                                                            </li>
                                                            {
                                                                departTabs?.hashcomponents?.map((depart, index) => {
                                                                    return (
                                                                        <li
                                                                            key={index}
                                                                            className={`nav-item cursor-pointer ${index === activeIndex ? "active" : "pt-2.5"} `}
                                                                            onClick={() => scrolldiv(index, depart?.name)}
                                                                        >
                                                                            {depart?.name === "Practitioners Groups" ? "Our Experts" : depart?.name}
                                                                        </li>
                                                                    );
                                                                })
                                                            }
                                                            <li
                                                                className={`nav-item cursor-pointer ${activeSection === 'contact' ? 'active' : ''} `}
                                                                onClick={() => scrolldiv(departTabs?.hashcomponents?.length + 1, 'contact')}
                                                            >
                                                                Contact
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </nav>
                                            </Box>

                                        </Card>

                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                        <Box sx={{ p: { xs: '0px 20px', sm: '0px 40px', md: '0px 80px', lg: '0px 100px', xl: "0px 125px" } }}>
                            <Grid container columnSpacing={5}>
                                <Grid item xs={12} md={12}>

                                    <Box className="pt-3"
                                        sx={{
                                            overflowY: 'scroll', maxHeight: 'calc(100vh - 240px)', '&::-webkit-scrollbar': { display: 'none' },
                                            padding: '0px 10px'
                                        }} id="main">
                                        <Stack spacing={6}>
                                            <div id="overview">
                                                <Box className='pt-2'>
                                                    <DepartmentOverview deptData={deptData} locationInfo={locationInfo} />
                                                </Box>
                                            </div>
                                            {departTabs?.hashcomponents?.map((depart, index) => (
                                                <div key={index} id={depart?.name} >
                                                    {depart?.name === 'Practitioners Groups' && (
                                                        <div className='mt-1'>
                                                            <PractitionerGroups practitioner={practitioner} />
                                                        </div>
                                                    )}
                                                    {depart?.name === 'HealthCare Services' && (
                                                        <div className='mt-1'>
                                                            <HealthServiceCare healthCareSerivces={healthCareSerivces} selectBranchInfo={selectBranchInfo} />
                                                        </div>
                                                    )}

                                                    {depart?.name === 'Posts & Articles' && (
                                                        <div className='mt-1'>
                                                            <PostAndArticle healthCareSerivces={healthCareSerivces} selectBranchInfo={selectBranchInfo} />
                                                        </div>
                                                    )}
                                                    {depart?.name === 'Patient Education' && (
                                                        <div className='mt-1'>
                                                            <PatientEducation healthCareSerivces={healthCareSerivces} selectBranchInfo={selectBranchInfo} />
                                                        </div>
                                                    )}
                                                    {depart?.name === 'Media Gallery' && (
                                                        <div className='mt-1'>
                                                            <MediaGallery healthCareSerivces={healthCareSerivces} selectBranchInfo={selectBranchInfo} locationInfo={locationInfo} />
                                                        </div>
                                                    )}
                                                    {depart?.name === 'Location Instances' && (
                                                        <div className='mt-1'>
                                                            <LocationInstances healthCareSerivces={healthCareSerivces} selectBranchInfo={selectBranchInfo} />
                                                        </div>
                                                    )}
                                                    {depart?.name === 'Frequently Asked Question' && (
                                                        < div className='mt-1'>
                                                            <FrequentlyAsked healthCareSerivces={healthCareSerivces} selectBranchInfo={selectBranchInfo} id={id} />
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                            <div id="contact">
                                                <Box>
                                                    <DepartContacts timings={timings} setTimings={setTimings} activeDay={activeDay} setActiveDay={setActiveDay} WorkingDays={WorkingDays} deptData={deptData} selectedbranch={selectBranchInfo} locationInfo={locationInfo} deptImg={deptImg} />
                                                </Box>
                                            </div>
                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <SocialMediaShare open={openShare} handleClose={() => setOpenShare(false)} url={url} title={title} />
                        <DoctorUnfollow open={modal} handleClose={() => setModal(false)} handleUnFollowDoc={handleUnFollowDoc} />
                        <NoAccess open={openMessage} handleClose={() => setOpenMessage(false)} />
                    </>
                    :
                    <>
                        <RepresentNoDetailsPage contextInfo="No, Particular Department details has not-found" />
                    </>


            }



        </Box >
    )
}

export default Healthcaredepart;