import { Box, IconButton } from '@mui/material';
import DOMPurify from 'dompurify';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import DoctorsPic from '../../../assets/doctorspics.png'; // Assuming a default image

function ViewArticle() {
    const location = useLocation();
    const item = location?.state?.docFeed;
    const articleData = location?.state?.docFeed?.articleData;
    const navigate = useNavigate();
    const { id } = useParams();
    const image = item?.sourceLink?.replace(/,\s*$/, "");

    // Sanitize HTML content
    const sanitizedHtml = DOMPurify.sanitize(articleData?.articleDescription, {
        FORBID_TAGS: ['style', 'font'],
        FORBID_ATTR: ['style'],
    });

    return (
        <Box
            sx={{
                p: {
                    xs: '5px 0px',
                    sm: '5px 0px',
                    md: '0px 80px',
                    lg: '0px 90px',
                },
            }}
        >
            {/* Back button */}
            <IconButton onClick={() => navigate(-1)} sx={{ position: 'absolute', zIndex: 1000, bgcolor: '#FFFFFF', opacity: '0.7' }}>
                <ArrowBack sx={{ color: '#000000' }} />
            </IconButton>

            {/* Cover Image */}
            <Box sx={{
                textAlign: 'center', mt: 2,
            }}>
                <img
                    src={image || DoctorsPic} // Use dynamic cover image if available, otherwise default
                    alt="Cover"
                    style={{
                        objectFit: 'fill',
                        width: '100%',
                        maxHeight: "300px",
                        position: 'relative',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundAttachment: 'fixed',
                        borderRadius: '10px'
                    }}
                />
            </Box>

            {/* Article Title */}
            <div className="text-center text-3xl font-medium mt-4">
                {articleData?.articleTitle}
            </div>

            {/* Article Content */}
            <div
                className="mt-2"
                dangerouslySetInnerHTML={{
                    __html: sanitizedHtml,
                }}
            />

            {/* Uncomment if PostActions are needed */}
            {/* <div className='mt-2'>
                <PostActions item={location?.state?.docFeed} />
            </div> */}
        </Box>
    );
}

export default ViewArticle;
