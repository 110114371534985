import Keycloak from "keycloak-js";
import { jwtDecode } from 'jwt-decode';

import _kc from '../keycloak'

// const _kc = new Keycloak(process.env.REACT_APP_REALM === 'docstethprod' ? './keycloakprod.json' : '/keycloak.json');
// const _kc = new Keycloak('/keycloak.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    onLoad: 'check-sso',

    // onLoad: 'login-required',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  })
    .then((authenticated) => {
      if (!authenticated) {
        // console.log("user is not authenticated..!");
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => {
  let token = sessionStorage.getItem("kctoken");
  // console.log(token,'fghjk')
  let newtoken = token ? jwtDecode(token) : null
  // console.log(_kc)
  if (_kc.token && new Date(_kc.tokenParsed.exp * 1000) > new Date()) {
    return _kc.token
  }
  else if ((newtoken && new Date(newtoken.exp * 1000) > new Date())) {
    return token
  }
  else {
    // console.log(_kc.tokenParsed,_kc.token)
    // doLogin()
  }

  // return _kc.token || token 
}

setInterval(() => {
  _kc.updateToken(180)
}, 180000)

const isLoggedIn = () => {
  return !!_kc.token;
}

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getUserId = () => _kc.tokenParsed?.userId || sessionStorage.getItem("userId");

const getName = () => _kc.tokenParsed?.name;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getUserId,
  hasRole,
  getName,
};
// sessionStorage.setItem('kcToken', _kc.token)
// sessionStorage.setItem('kcToken', updateToken)

export default UserService;