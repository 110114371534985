'use client'
import React, { useState } from 'react';
import { Grid, Paper, TextField } from '@mui/material';
import { BiRightArrowAlt } from 'react-icons/bi';
import styles from "./header/styles.module.css";
import { BsBuilding } from 'react-icons/bs';
import SearchIcon from '@mui/icons-material/Search';
import { MdMyLocation } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { locationJson } from '../shared/constants';
import { branchInfo } from '../redux/slices/branchSlice';
import hospitalImg from '../assets/hospitalimage.jpg'
import { selectedBranchInfo } from '../redux/slices/SelectedBranchSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import URL from '../services/api_url';

const BranchComponent = ({ departlistInfo, specialistInfo }) => {
    const branchLists = useSelector((state) => state.login.branchLists);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchBranch, setSearchBranch] = useState('');
    const [loading, setLoading] = useState(false)

    const handleSearchBranch = (e) => {
        setSearchBranch(e.target.value);
    };

    const filteredLocations = branchLists.filter(location =>
        location?.name?.toLowerCase().includes(searchBranch.toLowerCase())
    );

    const handleSelectBranch = (location) => {
        const sanitizedLocation = {
            ...location,
            name: location?.name?.replace(/[\s,]+/g, '').toLowerCase(),
        };
        localStorage.setItem("brName", sanitizedLocation?.name);
        localStorage.setItem("selectOrg", location?.name);

        localStorage.setItem("selectedbranch", JSON.stringify(sanitizedLocation));
        dispatch(branchInfo(sanitizedLocation?.name));
        dispatch(selectedBranchInfo(sanitizedLocation));

        if (departlistInfo && departlistInfo?.length === 0) {
            navigate(sanitizedLocation?.name ? `/${sanitizedLocation?.name}/healthcaredept` : '/healthcaredept');
        } else if (specialistInfo && specialistInfo?.length === 0) {
            navigate(sanitizedLocation?.name ? `/${sanitizedLocation?.name}/healthcareservices` : '/healthcareservices');
        } else {
            navigate(sanitizedLocation?.name ? `/${sanitizedLocation?.name}/doctors` : '/doctors');
        }
    };

    const handleClickNearestPlace = async () => {
        setLoading(true); // Start loading

        try {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });

            const { latitude, longitude } = position.coords;
            const response = await axios.get(`${URL.address}distance?coordinates=${latitude},${longitude}`);
            const location = response.data.branch;
            const matchedBranch = locationJson.find(branch => branch.name === location);
            if (matchedBranch) {
                localStorage.setItem("brName", matchedBranch.name);
                localStorage.setItem("selectedbranch", JSON.stringify(matchedBranch));
                dispatch(branchInfo(matchedBranch.name));
                dispatch(selectedBranchInfo(matchedBranch));
                navigate('/');

            } else {
                console.error('No matching branch found');
            }
            // navigate('/'); // Uncomment this line if you want to navigate after fetching the location
        } catch (error) {
            console.error('Error fetching geolocation data:', error);

        } finally {
            setLoading(false); // End loading
        }
    }

    return (
        <div>
            <Paper elevation={3} className="cursor-pointer" sx={{ p: "10px 15px" }}>
                <div className='text-sm font-semibold pb-2 flex items-center'>
                    <BsBuilding /> &nbsp; Select Branch
                </div>
                <TextField
                    id="standard-basic"
                    fullWidth
                    value={searchBranch}
                    placeholder='Search Branch Name'
                    onChange={handleSearchBranch}
                    variant="standard"
                    size='small'
                    InputProps={{
                        startAdornment: <SearchIcon />,
                    }}
                />
                {/* <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                        padding: "2px 7px",
                        color: "#333",
                        borderRadius: "8px",
                    }}
                    onClick={handleClickNearestPlace}
                >
                    <MdMyLocation fontSize={"20px"} />
                    <div className='pt-2'>
                        <div className="text-sm font-semibold">Set Nearest Facility</div>
                        <div className='text-[10px]' style={{ color: '#636363' }}>Based on my Current Location</div>
                    </div>
                </div> */}
                <Grid container>
                    {filteredLocations.map((location) => (
                        <Grid key={location.name} item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer", padding: "10px 0" }}>
                            <div className='flex items-center gap-2' onClick={() => handleSelectBranch(location)}>
                                <img src={location.orgImg || hospitalImg} width={34} height={34} alt="hospital" className='shadow-2xl'
                                    style={{ height: "34px", border: "3px solid #fff", borderRadius: "50%" }}
                                />
                                <div className='text-small font-medium'>{location.name}</div>
                            </div>
                            <BiRightArrowAlt style={{ fontSize: "20px", color: 'blue' }} />
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </div>
    );
}

export default BranchComponent;
