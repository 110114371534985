import { createSlice } from "@reduxjs/toolkit";

const selectedBranchSlice = createSlice({
  name: 'selectedbranch',
  initialState: '',
  reducers: {
    selectedBranchInfo: (state, action) => {
      // console.log('selectedBranchInfo', state, action)
      return {
        ...state,
        selectedbranch: action.payload
      }
    },
  },
});
export const { selectedBranchInfo } = selectedBranchSlice.actions;
export default selectedBranchSlice.reducer;