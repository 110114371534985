import React from 'react'

function PostAndArticle() {
    return (
        <div>
            <div className='font-semibold text-base md:text-lg '>Post & Article</div>
            <div className='pt-2  text-sm md:text[16px]'>No Data Available</div>
        </div>

    )
}

export default PostAndArticle