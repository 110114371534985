import axios from "axios";
import { API } from "../../services/ApiService";
import URL from "../../services/api_url";
import { createInteractionData, getLoc } from "../../components/atoms/getLocations";

// Action to set token
export const getToken = (token) => {
    return {
        type: "SET_TOKEN",
        payload: token,
    };
};

// Action to fetch branch lists
export const getBranchLists = (mainOrgId) => {
    return (dispatch) => {
        API.get(`branch_list_/Organization?partof=${mainOrgId}`)
            .then(res => {
                dispatch({
                    type: 'BRANCHLISTS',
                    payload: res.data,
                });
            })
            .catch(err => {
                dispatch({
                    type: 'BRANCHLISTS',
                    payload: [],
                });
            });
    };
};

// Action to fetch post by ID
export const getpostbyid = (id, token) => {
    return (dispatch) => {
        axios.get(`${URL.publish}getpostById/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(res => {
                dispatch({
                    type: 'POSTBYID',
                    payload: res.data,
                });
            })
            .catch(err => {
                dispatch({
                    type: 'POSTBYID',
                    payload: [],
                });
            });
    };
};



export const fetchLoc = () => {
    return async (dispatch) => {
        try {
            const result = await getLoc();
            // console.log('Raw result:', result); // Log the raw result to inspect its structure

            // Dispatch the result to the Redux store
            dispatch({
                type: 'LOCATION',
                payload: result,
            });


        } catch (error) {
            console.error('Error fetching location:', error);
            // Dispatch an empty array or error payload in case of an error
            dispatch({
                type: 'LOCATION',
                payload: '', // Or use an error message if preferred
            });
        }
    }
};

export const sendWebSocketMessage = (message) => {
    return (dispatch, getState) => {
        const { webSocket } = getState(); // Access the current WebSocket instance from the state
        if (webSocket && webSocket.readyState === WebSocket.OPEN) {
            webSocket.send(JSON.stringify(message));
            dispatch({ type: 'SEND_MESSAGE', payload: message }); // Optional: Dispatch an action for logging purposes
        } else {
            console.log('WebSocket is not open');
        }
    };
};