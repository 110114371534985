import React, { useCallback, useEffect, useState } from 'react'
import CommonDialog from '../atoms/commonModal'
import { Box, Checkbox, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, InputAdornment, TextField, Typography, Button, CircularProgress, FormControl, RadioGroup, Radio, Autocomplete, debounce } from '@mui/material'
import EmergencyImg from '../../assets/images/ambulance-icon.svg';
import { CustomTextField } from '../atoms/TextField';
import ContainedButton, { OutlinedButton } from '../atoms/commonbutton';
import Location from './location';

import { VscSend } from "react-icons/vsc";
import { FiPhoneCall } from "react-icons/fi";

import URL from '../../services/api_url';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { localstore } from '../localstore/localstore';
import ErrorStatusMsg from '../atoms/ErrorStatusMsg';
import BranchUrl from '../../services/api_branch_url';
import { Close } from '@mui/icons-material';

function EmergencyPopup({ open, handleClose }) {
    // const coding = [
    //     {
    //         "system": "http://snomed.info/sct",
    //         "code": "792847005",
    //         "display": "Emergency ambulance service"
    //     },
    //     {
    //         "system": "http://snomed.info/sct",
    //         "code": "715543004",
    //         "display": "Transportation by ambulance with assistance of two people"
    //     }
    // ]
    const locationCode = [
        {
            "coding": [
                {
                    "system": "http://terminology.hl7.org/ValueSet/v3-ServiceDeliveryLocationRoleType",
                    "code": "ACC",
                    "display": "accident site"
                }
            ]
        },
        {
            "coding": [
                {
                    "system": "http://terminology.hl7.org/ValueSet/v3-ServiceDeliveryLocationRoleType",
                    "code": "PTRES",
                    "display": "Patient's Residence"
                }
            ]
        }

    ]
    const priorities = {
        urgent: 'urgent',
        stat: 'routine'
    }

    const selectedbranch = useSelector((state) => state.SelectedBranch.selectedbranch)
    const patientDetails = useSelector(state => state.paramitha.patientDetails)

    const [phoneNumber, setPhoneNumber] = useState('')
    const [person, setPerson] = useState(null)
    const [loc, setLoc] = useState({ lat: '' || 17.448294, lng: '' || 78.391487 })
    const [openMap, setOpenMap] = useState(false)
    const [phoneError, setPhoneError] = useState('');
    const [nameError, setNameError] = useState('');
    const [locError, setLocError] = useState('');
    const [addressError, setAddressError] = useState('')
    const [checkedState, setCheckedState] = useState({
        checkbox1: true,
        emergency: false,
        nonEmergency: false
    });
    const [loading, setLoading] = useState(false)
    const usertoken = localstore.getToken()
    const ibmId = localstore.getIbmId()
    const urls = BranchUrl()
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [searchTerm, setSearchTerm] = useState('');
    const [address, setAddress] = useState('');
    const [locationInfo, setLocationInfo] = useState(null);
    const [locationList, setLocationList] = useState([]);

    const [selectedRadio, setSelectedRadio] = useState("Pickup from Patient’s Residence");
    const [selectedLocation, setSelectedLocation] = useState(locationCode[0]);
    const [priority, setPriority] = useState(priorities.urgent)

    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedRadio(value);
        if (value === "Pickup from Patient’s Residence") {
            setSelectedLocation(locationCode[0]);
        } else if (value === "Pickup from Accident Site") {
            setSelectedLocation(locationCode[1]);
        }
    };

    const handlePriorityChange = (event, value) => {
        const selectedPriority = event.target.value

        setPriority(selectedPriority);
    };

    const paramswithToken = {
        'X-FHIR-TENANT-ID': 'parimitha',
        'X-FHIR-DSID': `fhirparimitha`,
        'realm': 'parimitha',
        'Authorization': `Bearer ${usertoken}`
    }

    const paramswithoutToken = {
        'X-FHIR-TENANT-ID': 'parimitha',
        'X-FHIR-DSID': `fhirparimitha`,
        'realm': 'parimitha'
    }
    const getSearchLocInfo = async (searchValue) => {
        if (searchValue) {
            try {
                const response = await axios.get(`${URL.address}googlemapSearch/${searchValue}`, {
                    headers: {
                        'Usertoken': 'True'
                    }
                });
                return response.data
            } catch (error) {
                console.error("Error fetching location data:", error);
            }
        }
    };


    useEffect(() => {
        async function fetchLocation() {
            if (searchTerm?.length > 0) {
                let LocList = await getSearchLocInfo(searchTerm?.toLowerCase());
                setLocationList(LocList);
                // } else if (searchTerm == null || "") {
                //     setAddress();
                //     setLoc({ lat: '', lng: '' });
                //     // setSubDistricts([]);
                //     setLocationList([]);
                // }
            }
        }
        fetchLocation();
    }, [searchTerm]);


    const handleSearchInputOfLoc = useCallback(debounce((e, value) => {

        async function fetchLocation(val) {
            let LocList = await getSearchLocInfo(val?.toLowerCase())
            setLocationList(LocList?.data);
        }
        if (value) {
            fetchLocation(value);
        } else {
            setAddress('')
        }
    }, 500), [])
    // console.log(address, "address")
    const getLongitudeandLatitude = async (name) => {
        if (name) {
            try {
                const response = await axios.get(`${URL.address}googlemapsGeocode/${name}`, {
                    headers: {
                        'Usertoken': 'True'
                    }
                });
                return response.data
            } catch (error) {
                console.error("Error fetching location data:", error);
            }
        }
    };
    const getLocationByLongandLatitVals = async (lat, lng) => {
        if (lat && lng) {
            try {
                const response = await axios.get(`${URL.address}reverseGeocode/${lat + ',' + lng}`, {
                    headers: {
                        'Usertoken': 'True'
                    }
                });
                return response.data
            } catch (error) {
                console.error("Error fetching location data:", error);
            }
        }
    };

    useEffect(() => {
        async function fetchLatitAndLongtit() {
            let res;
            if (searchTerm?.name) {
                res = await getLongitudeandLatitude(searchTerm?.name);
                if (res) {
                    if (res.lat && res.lng) {
                        setLoc((prev) => {
                            return { ...prev, lat: res.lat, lng: res?.lng }
                        })
                        let LocObj = await getLocationByLongandLatitVals(res?.lat, res?.lng);
                        if (LocObj) {
                            setAddress(LocObj)
                            // setSubDistricts(LocObj.data?.subDistrictList ? LocObj.data?.subDistrictList : [])
                        }
                    }
                }

            }
        }
        fetchLatitAndLongtit()
    }, [searchTerm])



    const handleChangeLoc = (event, value) => {
        setSearchTerm(value)
        setAddressError('')
    };

    const handleCloseData = () => {
        if (usertoken && ibmId) {
            // setPhoneNumber('')
            setPerson('')
            setSearchTerm('')
            setAddress('')
            setCheckedState({ ...checkedState, emergency: false })
            setLoc({ lat: '', lng: '' })
            setAlertmessage('')
            setLocError('')
            setNameError('')
            handleClose()
        } else {
            setPhoneNumber('')
            setPerson('')
            setSearchTerm('')
            setAddress('')
            setCheckedState({ ...checkedState, emergency: false })
            setLoc({ lat: '', lng: '' })
            setAlertmessage('')
            setLocError('')
            setNameError('')
            handleClose()
        }
    }

    const handleCheckboxChange = (event) => {
        setCheckedState({
            ...checkedState,
            [event.target.name]: event.target.checked,
        });
        if (event.target.name === 'emergency') {
            setCheckedState({
                ...checkedState,
                emergency: event.target.checked,
                nonEmergency: !event.target.checked,
            });
        } else if (event.target.name === 'nonEmergency') {
            setCheckedState({
                ...checkedState,
                nonEmergency: event.target.checked,
                emergency: !event.target.checked,
            });
        }
    };
    const handleChangePhone = (e) => {
        setPhoneNumber(e.target.value?.replace(/\D/g, "")?.slice(0, 10))
        setPhoneError('')
    }
    const handlelocateMap = async () => {
        const Location = window.navigator && window.navigator.geolocation
        setAddressError('')
        if (Location) {
            Location.getCurrentPosition((position) => {
                setLoc({ lat: position.coords.latitude, lng: position.coords.longitude })
                setOpenMap(true)
                getAddress(position.coords.latitude, position.coords.longitude); // Call getAddress with coordinates
            }, (error) => {
            })
        }
        try {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });
            const { latitude, longitude } = position.coords;
            setLoc({ lat: parseFloat(latitude), lng: parseFloat(longitude) })
            getAddress(latitude, longitude); // Call getAddress with coordinates

        } catch (error) {
            // console.log(error)
        }
    }
    // console.log(address, "hello")
    // console.log(searchTerm, "searchTerm")
    const getAddress = async (lat, lng) => {
        try {
            const res = await axios.get(`${URL.address}reverseGeocode/${lat},${lng}?realm=parimitha`, {
                headers: {
                    'Usertoken': 'True'
                }
            })
            setAddress(res?.data);

        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        // handlelocateMap()
        setPhoneNumber(patientDetails?.telecom?.[0]?.value || '')
    }, [patientDetails]);

    const selectedBranchId = selectedbranch?.id ? selectedbranch?.id : `${process.env.REACT_APP_PARAMITHA_ID}`

    const handleSendRequest = async () => {
        let isValid = true;

        if (phoneNumber.length < 10) {
            setPhoneError('Please enter a valid 10-digit phone number.');
            isValid = false;
        } else {
            setPhoneError('');
        }

        // if (!searchTerm) {
        //     setLocError('Please search for an address location.')
        //     // setLocError('Please search for an address location.');
        //     isValid = false;
        // } else {
        //     setLocError('');

        // }
        if (!person) {
            setNameError('Please Enter Person Name')
            isValid = false;
        } else {
            setNameError('');
        }
        if (!address) {
            setAddressError('Please Select Current Location or Search Address')
            isValid = false;
        } else {
            setAddressError('');
        }
        // if (!searchTerm) {
        //     setLocError('Please search for an address location.');
        //     isValid = false;
        // } else {
        //     setLocError('');
        // }

        if (!isValid) {
            return;
        }

        const patientdata = {
            "resourceType": 'Patient',
            "managingOrganization": { "reference": `Organization/${selectedBranchId}` }
        }

        if (address && phoneNumber && person) {
            if (!usertoken && !ibmId) {
                axios.post(`${urls.fhir_tenant_orgId}Patient`, patientdata, {
                    headers: paramswithoutToken
                }).then(res => {
                    handleRelatedPatientId(res.data)
                })
            } else {
                axios.post(`${urls.fhir_tenant_orgId}Patient`, patientdata, {
                    headers: paramswithToken
                }).then(res => {
                    handleRelatedPatientId(res.data)
                }).catch(error => {
                    // setAlertmessage(error.message)
                    // setAlertstatus('error')
                })

            }
        }
    }

    const handleRelatedPatientId = async (patientId) => {
        const relateddata =
        {
            "resourceType": "RelatedPerson",
            "patient": {
                "reference": `Patient/${patientId}`
            },
            "name": [
                {
                    "given": [
                        person
                    ], "text": person
                }
            ],
            "telecom": [
                {
                    "system": "phone",
                    "value": phoneNumber
                }
            ]
        }
        if (!usertoken && !ibmId) {
            try {
                const res = await axios.post(`${urls.fhir_tenant_orgId}RelatedPerson`, relateddata, {
                    headers: paramswithoutToken
                })
                handleLocationId(res.data, patientId)
            } catch (error) {
                setAlertmessage(error.message)
                setAlertstatus('error')
            }
        } else {
            try {
                const res = await axios.post(`${urls.paramitha_v1}RelatedPerson`, relateddata, {
                    headers: paramswithToken
                })
                handleLocationId(res.data, patientId)
            } catch (error) {
                setAlertmessage(error.message)
                setAlertstatus('error')
            }
        }
    }
    const handleLocationId = async (relPersonId, patientId) => {
        const locationdata = {

            "resourceType": "Location",
            // "data": [
            //     {
            //         "name": "Swastik Hospitals, Ambalipura - Sarjapur Road, Dommasandra, Bengaluru, Karnataka, India"
            //     },
            //     {
            //         "name": "Swastik Hospital And Research Centre, Deepak Kumar Marg, Swastik Park, Chembur, Mumbai, Maharashtra, India"
            //     },
            //     {
            //         "name": "Sri Swastik Multispecialty Hospital, Nizampet Road, beside akshara iit junior collage, near bachupalli, chowrastha, Bachupally, Hyderabad, Telangana, India"
            //     },
            //     {
            //         "name": "Swastik Hospital, 5th Main Road, 2nd Block, Phase 1, Banashankari 1st Stage, Chamrajpet, Bengaluru, Karnataka, India"
            //     },
            //     {
            //         "name": "Swastik Eye Hospital, Vanivilas Road, Gandhi Bazaar, Basavanagudi, Bengaluru, Karnataka, India"
            //     }
            // ]
            "position": {
                "longitude": loc.lng,
                "latitude": loc.lat
                // "altitude": 0
            },
            "address": {
                "text": `${address?.addressLine} ${address?.subdistrict} ${address?.district} ${address?.state} ${address?.postalcode}`,
                "line": [address.addressLine],
                "city": address.subdistrict,
                "district": address.district,
                "state": address.state,
                "postalCode": address.postalcode
            },

        }
        if (!usertoken && !ibmId) {
            axios.post(`${urls.fhir_tenant_orgId}Location`, locationdata, {
                headers: paramswithoutToken
            }).then(res => {
                // setLocationId(res.data)
                handleServiceRequest(relPersonId, patientId, res.data)
            }).catch(error => {
                setAlertmessage(error.message)
                setAlertstatus('error')
            })
        } else {
            axios.post(`${urls.person_tenant_orgId}Location`, locationdata, {
                headers: paramswithToken
            }).then(res => {
                // setLocationId(res.data)
                handleServiceRequest(relPersonId, patientId, res.data)

            }).catch(error => {
                setAlertmessage(error.message)
                setAlertstatus('error')
            })
        }
    }

    const handleServiceRequest = async (relPersonId, patientId, locationId) => {
        const data =
        {
            "resourceType": "ServiceRequest",
            "status": "active",
            "intent": "proposal",
            "subject": {
                "reference": `Patient/${patientId}`
            },
            "requester": {
                "reference": `RelatedPerson/${relPersonId}`
            },
            "category": [
                {
                    "coding": [
                        {
                            "system": "http://hl7.org/fhir/ValueSet/servicerequest-category",
                            "code": "409971007",
                            "display": "Emergency medical services"
                        }
                    ]
                }
            ],
            "code": {
                "coding": [
                    {
                        "system": "http://hl7.org/fhir/ValueSet/emergencymedicalservices-code",
                        "code": "715542009",
                        "display": "Transportation by emergency ambulance"
                    }
                ]
            },
            // "orderDetail": [
            //     {
            //         "coding": [
            //             {
            //                 "system": "http://snomed.info/sct",
            //                 "code": "47545007"
            //             }
            //         ]
            //     }
            // ],
            "performerType": {
                "coding": [
                    {
                        "system": "http://snomed.info/sct",
                        "code": "397897005",
                        "display": "Paramedic (occupation)"
                    }
                ]
            },
            "performer": [
                {
                    "reference": `Organization/${selectedBranchId}`
                }
            ],
            "priority": priority,
            "locationReference": [
                {
                    "reference": `Location/${locationId}`
                }
            ],
            "locationCode": [selectedLocation]

        }

        // const data = {
        //     "resourceType": "ServiceRequest",
        //     "status": "active",
        //     // "intent": "original-order",
        //     "intent": "proposal",
        //     "subject": {
        //         "reference": `Patient/${patientId}`
        //     },

        //     "requester": {
        //         "reference": `RelatedPerson/${relPersonId}`
        //     },
        //     // "reasonCode": [
        //     //     {
        //     //         "coding": [checkedState.emergency ? coding[0] : coding[1]]
        //     //     }
        //     // ],

        //     "Category": {
        //         "system": "http://hl7.org/fhir/ValueSet/servicerequest-category", "code": "409971007", "display": "Emergency medicalservices"
        //     },
        //     "Code": {
        //         "System": "http://hl7.org/fhir/ValueSet/emergencymedicalservices-code", "code": "715542009", "display": 'Transportation by emergency ambulance'
        //     },
        //     "OrderDetail": "http://hl7.org/fhir/ValueSet/ambulancerrequest-orderdetail",
        //     "PerformerType": "Paramedic",
        //     "Performer": "paramedic/Ref.practitioner",
        //     "priority": priority,
        //     "locationReference": [
        //         {
        //             "reference": `Location/${locationId}`
        //         }
        //     ],
        //     "locationCode": [selectedLocation]
        // }

        if (!usertoken && !ibmId) {
            try {
                setLoading(true)
                const res = await axios.post(`${urls.fhir_tenant_orgId}ServiceRequest`, data, {
                    headers: paramswithoutToken
                })
                alert('Your Request is Successfully Created')

                handleCloseData()
            } catch (error) {
                setAlertmessage(error.message)
                setAlertstatus('error')
                // alert(error.message)
            } finally {
                setLoading(false)
            }

        } else {
            try {
                setLoading(true)
                const res = await axios.post(`${urls.paramitha_v1}ServiceRequest`, data, {
                    headers: paramswithToken
                })
                alert('Your Request is Successfully Created')
                handleCloseData()

            } catch (error) {
                setAlertmessage(error.message)
                setAlertstatus('error')
                // alert(error.message)
            } finally {
                setLoading(false)
            }

        }
    }

    return (
        <div>
            < CommonDialog maxWidth="md" open={open} sx={{ '.MuiDialog-paper': { bottom: { md: "0px" }, top: '0px' }, }} >
                <DialogTitle sx={{ p: '0px 30px' }}>
                    <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-1'>
                            <img src={EmergencyImg} alt="emergency" />
                            <div>
                                <div className='text-center text-xl md:text-2xl font-medium'>Emergency & Ambulance Services</div>
                                <div className='text-xs font-medium mt-0.5 text-[#E44190]'>@ {selectedbranch?.name || "Paramitha Hospitals"} </div>
                            </div>
                        </div>
                        <div className='mr-2 cursor-pointer' onClick={handleCloseData}><Close color='error' /></div>
                    </div>
                </DialogTitle>
                <Divider />

                <DialogContent sx={{
                    p: '5px 30px', overflowY: 'scroll',
                    // height: { xs: '100vh', sm: '100vh', md: '63vh' },
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    }
                }}>

                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedState.checkbox1}
                                        onChange={handleCheckboxChange}
                                        name="checkbox1"
                                        color="primary"
                                    />
                                }
                                label="I’m Reporting Incident"
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '13px',
                                        fontWeight: 500
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={selectedRadio}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel value="Pickup from Patient’s Residence" control={<Radio />} label="Pickup from Patient’s Residence"
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '13px',
                                                fontWeight: 500
                                            },
                                        }} />
                                    <FormControlLabel value="Pickup from Accident Site" control={<Radio />} label="Pickup from Accident Site"
                                        sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '13px',
                                                fontWeight: 500
                                            },
                                        }} />

                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>
                    <div className='mt-1'>
                        <Grid container columnSpacing={5}>
                            <Grid item xs={12} sm={12} md={6}>

                                <div>
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Phone Number (Reporting Person)</label>
                                    </Box>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type={'text'}
                                        value={phoneNumber}
                                        onChange={handleChangePhone}
                                        placeholder="Input your 10 digit Phone number"
                                        sx={{
                                            width: '100%', mt: "5px",
                                            ".MuiInputBase-root": {
                                                borderRadius: '7px',
                                                // backgroundColor: 'whitesmoke',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#59D8FA',
                                                },
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    +91
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {phoneError && <Typography sx={{ fontSize: '13px', color: '#D32F2F' }}>{phoneError}</Typography>}

                                </div>
                                <div className='mt-7'>
                                    <Box>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Name of Reporting Person
                                            {/* (Optional) */}
                                        </label>
                                    </Box>

                                    <CustomTextField
                                        placeholder="Name of Reporting Person"
                                        type='text'
                                        borderColor="#59D8FA"
                                        borderRadius="7px"
                                        value={person}
                                        onChange={(e) => {
                                            setPerson(e.target.value)
                                            setNameError('')
                                        }}
                                    />
                                    {nameError && <Typography sx={{ fontSize: '13px', color: '#D32F2F' }}>{nameError}</Typography>}

                                </div>
                                <div className='mt-7'>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={priority}
                                        onChange={handlePriorityChange}
                                    >
                                        <FormControlLabel sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '14px',
                                                fontWeight: 500
                                            },
                                        }} value={priorities.urgent} control={<Radio />} label="Critical/Life Threatening Emergency" />
                                        <FormControlLabel sx={{
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '14px',
                                                fontWeight: 500
                                            },
                                        }} value={priorities.stat} control={<Radio />} label="Patient Transfer/Transport (Not Emergency)" />
                                    </RadioGroup>
                                </div>

                                <div className='mt-5'>
                                    <div className='font-semibold'>24/7 Emergency Helpline :</div>
                                    <div className='mt-1'>
                                        <OutlinedButton text="+91 911 911 4934" height="33px" borderRadius="10px" borderColor="#1D5775" color="#1D5775" startIcon={<FiPhoneCall />} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <div>
                                    <Box sx={{ mt: { xs: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                        <label style={{ fontWeight: 500, fontSize: '14px' }}>Tell Location to Reach</label>
                                        <Typography component='button' sx={{ mt: '5px', fontWeight: 500, border: '1px solid gray', borderRadius: '7px', fontSize: '14px', p: "0px 10px" }} onClick={handlelocateMap}>Current Location</Typography>

                                    </Box>
                                    <div style={{ marginTop: '5px' }}>
                                        <Autocomplete
                                            id="state-select"
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#59D8FA',
                                                    },
                                                },
                                                ".MuiInputBase-root": {
                                                    borderRadius: '7px',
                                                },
                                            }}
                                            size='small'
                                            autoHighlight
                                            autoWidth
                                            options={locationList || []}
                                            value={searchTerm}
                                            getOptionLabel={(option) => option.name || ""}
                                            onInputChange={handleSearchInputOfLoc}
                                            onChange={handleChangeLoc}
                                            fullWidth
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ textTransform: 'capitalize' }} {...props}>
                                                    {option.name}
                                                </Box>
                                            )}
                                            // PopperComponent={({ children, ...props }) => (
                                            //     <Popper {...props} style={{
                                            //         zIndex: 999999, width: '400px'

                                            //     }}>
                                            //         {children}
                                            //     </Popper>
                                            // )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder='Search Address Location Here'
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <Location loc={loc} setLoc={setLoc} openMap={openMap} setOpenMap={setOpenMap} />
                                </div>

                                <div className='mt-0.5'>{`Address`}
                                </div>
                                <p className='mt-1 font-semibold text-sm'>
                                    {address.addressLine && `${address.addressLine}, `}
                                    {address.district && `${address.district}, `}
                                    {address.subdistrict && `${address.subdistrict}, `}
                                    {address.state && `${address.state}, `}
                                    {address.contry && `${address.contry}, `}
                                    {address.postalcode && `${address.postalcode}`}
                                </p>
                                {addressError && <Typography sx={{ fontSize: '13px', color: '#D32F2F' }}>{addressError}</Typography>}
                            </Grid>

                        </Grid>
                        <div className='mt-1'></div>
                    </div>
                </DialogContent>
                <Divider />
                <div className='text-center'>
                    <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />

                </div>
                <DialogActions sx={{ justifyContent: "space-between", px: 3 }}>
                    <div></div>
                    {/* <Button onClick={handleCloseData} color='error' sx={{ textTransform: 'capitalize' }} >Cancel</Button> */}
                    <ContainedButton text="Request Ambulance" bgcolor="#1B5775" height="33px" fontSize="14px" borderRadius="10px" endIcon={loading ? <CircularProgress size={20} color='inherit' /> : <VscSend />} onClick={handleSendRequest} />
                </DialogActions>
            </CommonDialog>
        </div >
    )
}

export default EmergencyPopup