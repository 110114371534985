import styled from "@emotion/styled";

export const Overlay = styled('div')({
    position: 'fixed',
    top: 81,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.68)', // Adjust the color and opacity as needed
    zIndex: 100, // Ensure it's above other elements
});