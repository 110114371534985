import React, { useState } from "react";
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import styles from "../../header/styles.module.css";
import AutoPlayVideo from "./AutoPlayVideo";
import videosrc from "../../../assets/images/mother and boy.mp4"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectedBranchInfo } from "../../../redux/slices/SelectedBranchSlice";
import { branchInfo } from "../../../redux/slices/branchSlice";


const Thirdslide = ({ urgentCarePopup, setUrgentCarePopup }) => {

  const dispatch = useDispatch();
  const branchList = useSelector((state) => state.login.branchLists);
  // console.log(branchList, "urgentCarePopup123",)
  const [selectBranchPopup, setSelectBranchPopup] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const branchName = useSelector((state) => state.branch.branch);
  const selectBranchInfo = useSelector((state) => state.SelectedBranch.selectedbranch);
  const [alertMessage, setAlertMessage] = useState('')

  const navigate = useNavigate();

  // console.log(selectBranchInfo, "branchName123321")



  const handkeclickBookNow = () => {
    selectBranchInfo?.id ? setUrgentCarePopup(true) :
      setSelectBranchPopup(true)
  }

  const handleNext = () => {
    if (selectedBranch) {
      setUrgentCarePopup(true)
      setSelectBranchPopup(false)
      const sanitizedLocation = {
        ...selectedBranch,
        name: selectedBranch?.name?.replace(/[\s,]+/g, '').toLowerCase(),
      };
      // setSelectedLocationName(selectedBranch?.name);
      localStorage.setItem("selectOrg", selectedBranch?.name); // Store the selected index in localStorages
      // dispatch(branchInfo(sanitizedLocation?.name));
      localStorage.setItem("brName", sanitizedLocation?.name);
      localStorage.setItem("selectedbranch", JSON.stringify(selectedBranch));
      dispatch(selectedBranchInfo(selectedBranch));
    } else {
      setAlertMessage('please select the branch')
    }

  }
  const handleClose = () => {
    setSelectBranchPopup(false)
    setAlertMessage('')
  }



  return (
    <>

      <Dialog
        open={selectBranchPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth // Ensures it takes up the full width allowed
        PaperProps={{
          sx: { height: "30%" }, // Custom width & height
        }}

      >
        <DialogTitle id="alert-dialog-title"> Please Select the Branch</DialogTitle>

        <DialogContent sx={{ paddingTop: "10px" }}>

          <Autocomplete
            options={branchList || []}
            getOptionLabel={(option) => option.name}
            value={selectedBranch}
            sx={{ paddingTop: "10px" }}
            onChange={(event, newValue) => {
              setSelectedBranch(newValue)
              setAlertMessage('')
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                placeholder="Select the Branch"
                variant="outlined"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    height: "42px", // Adjust height as needed
                    minHeight: "42px",
                    borderRadius: "7px",
                  },
                  "& .MuiInputBase-input": {
                    padding: "8px", // Adjust padding for better appearance
                  }
                }}
              />
            }
          />

        </DialogContent>

        <div className="text-center text-xs md:text-sm text-[red]">{alertMessage}</div>
        <DialogActions >
          <Button onClick={handleClose} sx={{ fontSize: "15px", textTransform: "capitalize" }} >Close</Button>
          <Button onClick={handleNext} autoFocus sx={{ fontSize: "15px", textTransform: "capitalize" }}>
            Next
          </Button>
        </DialogActions>
      </Dialog>

      <div
        style={{
          width: "100%",
          height: "80vh",
          // position: "relative",
        }}
        className={styles.carouselHead}
      >
        <AutoPlayVideo src={videosrc} />
        {/* <Image
        src={"/background.jpg"}
        alt="background"
        width={1200}
        height={600}
      /> */}
        <div className={styles.thirdslidecontainer}>
          <h1
            className={[styles.slidethirdheading].join(" ")}
            style={{ color: "black" }}
          >
            {/* Children are Special So, Our Specialists For Children */}
            Child Birth is Happiness,We Only Make It Natural & Safe
          </h1>

          <Box className={styles.childinformation}>
            <Typography className={styles.item}>TRIMESTER CHECKUP</Typography>
            <Typography className={styles.item}>LABOR PREPARATION</Typography>
            <Typography className={styles.item}>CHILD BIRTH</Typography>
            <Typography className={styles.item}>POSTNATAL CARE</Typography>
          </Box>
          <div className={styles.appintmentbtn}>
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                borderRadius: "10px",
                // alignSelf: "left",
                width: '150px',
                textTransform: "capitalize",
                backgroundColor: '#000000 !important',
                height: 35,
                mt: 2
              }}
            >
              <Typography sx={{ fontSize: '18px' }} onClick={handkeclickBookNow}>  Book Now</Typography>
            </Button>
          </div>
        </div>
      </div>

    </>
  );
};

export default Thirdslide;
