import React from "react";

const AutoPlayVideo = ({ src }) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: 0,
        paddingBottom: "56.25%", // Aspect ratio for 16:9 (height = width * 9 / 16)
        overflow: "hidden",
      }}
    >
      <video
        src={src}
        autoPlay
        muted
        loop
        playsInline
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover", // Makes sure the video covers the whole area
        }}
      />
    </div>
  );
};

export default AutoPlayVideo;
