import { createSlice } from "@reduxjs/toolkit";

const branchSlice = createSlice({
    name: 'branch',
    initialState: '',
    reducers: {
      branchInfo: (state, action) => {
        return {
          ...state,
          branch: action.payload
        }
      },
    },
  });
  export const { branchInfo } = branchSlice.actions;
  export default branchSlice.reducer;