import React, { useEffect, useState } from 'react';
import { Grid, Paper, CircularProgress, Box } from '@mui/material';
import axios from 'axios';
// import { PostData } from '../home/healhinfor/PostData';
import { DoctorsPosts } from '../home/healhinfor/doctorposts';

function PostPublications({ userData, practitionerrole, id, selectedbranch }) {
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);
    const practitionerValue = userData?.identifier?.[0]?.value;
    // console.log(userData, "userData1")
    useEffect(() => {
        const getpostData = async () => {
            setLoading(true);
            try {
                // const res = await axios.get(`${URL.feed}feed/postsbyuseridwithouttoken/${practitionerValue}?limit=25&offset=0`);
                // setPosts(res?.data?.results || []);
                if (practitionerValue) {
                    const res = await axios.get(`${process.env.REACT_APP_C_M_API_URL}manager/getSharePostFromDocsteth/${practitionerValue}`);
                    setPosts(res.data || []);
                    console.log(res)
                } else {
                    setPosts([]);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        if (practitionerValue) {
            getpostData();
        }
    }, [practitionerValue]);
    // useEffect(() => {
    //     const getpostData = async () => {
    //         setLoading(true);
    //         try {
    //             if (id) {
    //                 // Wait for the first API call to get the subres data
    //                 const subres = await axios.get(`https://dev.api.paramithahospitals.in/auth/v4/UserName/${id}`);
    //                 console.log(subres, "subres");

    //                 // Get the subid from the first API call (if it exists)
    //                 const subid = subres?.data?.[0]?.attributes?.userId?.[0];
    //                 console.log(subid, "subid");

    //                 // Now, use the subid to fetch posts
    //                 const res = await axios.get(`${process.env.REACT_APP_C_M_API_URL}manager/getSharePostFromDocsteth/${subid}`);
    //                 setPosts(res.data || []);
    //                 console.log(res);
    //             } else {
    //                 setPosts([]);
    //             }
    //         } catch (error) {
    //             console.error(error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     if (practitionerValue) {
    //         getpostData();
    //     }

    // }, [practitionerValue]);

    return (
        <div>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Grid container>
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        posts?.length > 0 ? posts?.map((post, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <DoctorsPosts feedData={post} userData={userData} />
                                {/* <PostData feedData={post} userData={userData} /> */}
                            </Grid>
                        )) : <div>No Posts Available</div>
                        // <div>No Posts Available</div>
                    )}
                </Grid>
            </Paper>
        </div>
    );
}

export default PostPublications;
