import React, { useEffect, useState, useRef } from 'react';
import { Button, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

function DepartmentOverview({ locationInfo }) {
    const [editAboutText, setEditAboutText] = useState('');
    const [about, setAbout] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false); // State to track truncation
    const textRef = useRef(null); // Ref to access the text div

    useEffect(() => {
        if (locationInfo?.resource?.description) {
            if (!about) {
                // Initially show only the truncated text (first 5 lines)
                setEditAboutText(locationInfo?.resource?.description);
            } else {
                setEditAboutText(locationInfo?.resource?.description);
            }
        } else {
            setEditAboutText('No data available');
        }
    }, [locationInfo, about]);

    useEffect(() => {
        // Check if the text is truncated (i.e., it's overflowing)
        if (textRef.current) {
            const isTextTruncated = textRef.current.scrollHeight > textRef.current.clientHeight;
            setIsTruncated(isTextTruncated);
        }
    }, [editAboutText]); // Check if the content is truncated whenever the content changes

    const handleToggleAbout = () => {
        setAbout(prevAbout => !prevAbout);
    };

    return (
        <div>
            <div className='font-semibold text-base md:text-lg'>About</div>
            <div>
                <Typography my={1}>
                    <div
                        className='text-sm md:text-base'
                        ref={textRef}
                        dangerouslySetInnerHTML={{ __html: editAboutText }}
                        style={{
                            display: !about ? '-webkit-box' : 'block',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: about ? 'none' : 5, // Show 5 lines when truncated
                            whiteSpace: 'pre-wrap',

                        }}
                    />
                </Typography>

                {/* Only show the button if the content is truncated */}
                {isTruncated && (
                    <div className='text-center mt-2' onClick={handleToggleAbout}>
                        {about ? (
                            <Button
                                variant="outlined"
                                sx={{
                                    borderColor: "#E54290",
                                    textTransform: "capitalize",
                                    color: "#E54290",
                                    borderRadius: "10px",
                                    width: "150px",
                                    '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
                                }}
                                endIcon={<ExpandLess />}
                            >
                                <Typography sx={{ fontSize: { xs: '14px', md: "16px", fontWeight: 500 } }}>Show Less</Typography>
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"
                                sx={{
                                    borderColor: "#E54290",
                                    textTransform: "capitalize",
                                    color: "#E54290",
                                    borderRadius: "10px",
                                    width: "150px",
                                    '&:hover': { backgroundColor: '#E54290', borderColor: '#E54290', color: 'white' },
                                }}
                                endIcon={<ExpandMore />}
                            >
                                <Typography sx={{ fontSize: { xs: '14px', md: "16px", fontWeight: 500 } }}>Show More</Typography>
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default DepartmentOverview;
