import { Box, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import Search from "./Search";
import PatientEducationArticle from "./PatientEducationArticle";
import LiveHealthEvents from "./LiveHealthEvents";
import FrequentQuestion from "./FrequentQuestion";
import "../home/healhinfor/Healthcom.css";
import AllPosts from "./AllPosts";
import ResearchPublications from "./ResearchPublications";
import { useSelector } from "react-redux";


const HealthInformationPage = () => {
  const [posts, setPosts] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const branchList = useSelector((state) => state.login.branchLists);
  // const pageIds = branchList.map(branch => branch.pageId).join(',');
  const pageIds = 471;
  const limit = 25;

  useEffect(() => {
    postAPIS();
  }, []);

  const postAPIS = async (key) => {
    //   setLoading(true);
    //   await axios
    //     .get(
    //       `https://dev.api.docsteth.com/feed/getpostbypageid/359?limit=25&offset=0`
    //     )
    //     .then((res) => {
    //       setPosts(res.data.results);
    //       console.log(res.data, "resdata");
    //     })
    //     .catch((error) => console.log(error, "6666666666"))
    //     .finally(() => {
    //       setLoading(false);
    //     });
  };

  // console.log(loading, "loading");
  return (
    <Box sx={{
      p: {
        xs: "5px 20px",
        sm: "5px 20px",
        md: "5px 90px",
        lg: "5px 90px"
      },
    }}>
      <Search />
      <PatientEducationArticle pageIds={pageIds} />
      <AllPosts pageIds={pageIds} />
      <LiveHealthEvents pageIds={pageIds} />
      <FrequentQuestion />
      <ResearchPublications />
    </Box>
  );
};

export default HealthInformationPage;
