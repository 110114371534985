import React from 'react';
import CommonDialog from '../atoms/commonModal';
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CalendarLinks from './calendarLink';
import { OutlinedButton } from '../atoms/commonbutton';

function BookingAlert({ open, setAlertOpen, userRole, userData, selectedSlot, selectedbranch, handleCancel }) {

    const handleClose = () => {
        setAlertOpen(false);
    };
    const adjustTime = (date) => {
        let adjustedDate = new Date(date);
        adjustedDate.setHours(adjustedDate.getHours() - 5);
        adjustedDate.setMinutes(adjustedDate.getMinutes() - 30);
        return adjustedDate;
    };

    const adjustedStart = adjustTime(selectedSlot?.start);
    const adjustedEnd = adjustTime(selectedSlot?.end);

    const event = {
        title: 'Medical Consultation Appointment',
        location: `Appointment Scheduled at ${selectedbranch?.name}`,
        description: `${userRole?.resource?.identifier?.[0]?.value} consultation Appointment with ${userData?.name?.[0]?.text}`,
        start: adjustedStart,
        end: adjustedEnd,
        guests: [`Dr. ${userData?.name?.[0]?.text}`], // Changed to an array
    };

    const handleDone = () => {
        handleCancel();
        setAlertOpen(false);
    };

    return (
        <div>
            <CommonDialog maxWidth={'sm'} open={open} onClose={handleClose}>
                <DialogContent>
                    <div className='text-center p-6'>
                        <div className='font-bold mt-1'>
                            Your Appointment is Successfully Booked
                        </div>
                        <div className='mt-1'>
                            <CalendarLinks event={event} />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <OutlinedButton text="Done" height="30px" width="100px" color="#1B5775" borderRadius="7px" borderColor="#1B5775" onClick={handleDone} />
                </DialogActions>
            </CommonDialog>
        </div>
    );
}

export default BookingAlert;
